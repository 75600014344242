import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import socketIOClient from 'socket.io-client';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import {
  Container,
  Row,
  Col,
  Alert,
  Modal,
  Button as ModalBtn,
} from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import moment from 'moment';
import { Link } from 'react-router-dom';
import closeIcon from 'assets/svgs/close-icon.svg';

// Interfaces
import { Message } from 'interfaces/Message.types';
import { ApplicationDetails } from 'interfaces/Job.types';

// Context
import GlobalContext from 'contexts/Global.context';

// Components
import Button from 'components/Button/Button';
import Input from 'components/Form/Input/Input';
import Loader from 'components/Loader/Loader';

// Constants
import { BASE_URL, ROUTES } from 'constants/Constants';

// Services
import {
  getMessagesService,
  sendMessageService,
  markConversationReadService,
} from 'services/MessageService';
import {
  fetchApplicationService,
  updateApplicantsStatusService,
} from 'services/JobService';

import History from 'utils/History';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

// SCSS
import styles from './ActiveJob.module.scss';

// SVGs
import Help from 'assets/svgs/help.svg';

const ActiveJob: React.FC = () => {
  const { id } = useParams();
  const {
    userDetails: { id: loggedInUserId }, // sender name
  } = useContext(GlobalContext);
  const [application, setApplication] = useState<ApplicationDetails | null>(
    null,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showExtendScope, setShowExtendScope] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [messages, setMessages] = useState<Array<Message> | null>(null);
  const [messageText, setMessageText] = useState<string>('');
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [sending, setSending] = useState<boolean>(false);
  const [operating, setOperating] = useState<boolean>(false);
  const [extending, setExtending] = useState<boolean>(false);
  const [partnerId, setPartnerId] = useState<string | null>(null);
  const [schedules, setSchedules] = useState<any>([
    {
      date: '',
      delivery: '',
    },
  ]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const addSchedule = () => {
    const newSchedules = [...schedules, { delivery: '', date: '' }];
    setSchedules(newSchedules);
  };

  const onEditorStateChange: Function = (editorStateParam) => {
    setEditorState(editorStateParam);
    setMessageText(
      draftToHtml(convertToRaw(editorStateParam.getCurrentContent())),
    );
  };

  const handleScheduleChange = (event: any, index: number) => {
    const { value, name } = event.target;
    const schedulesCopy = JSON.parse(JSON.stringify(schedules));
    schedulesCopy[index][name] = value;
    setSchedules(schedulesCopy);
  };

  const markRead = async () => {
    setSending(true);
    await markConversationReadService(partnerId);
    setUnreadCount(0);
    setSending(false);
  };

  const extendScope = async () => {
    const validSchedules = schedules.filter(({ date, delivery }) => {
      return !!date && !!delivery;
    });
    if (validSchedules.length === 0) {
      return;
    }
    setExtending(true);
    const newSchedules = [...(application.schedule || []), ...validSchedules];
    const apiBody = {
      schedule: newSchedules,
    };
    const { isUpdated, error } = await updateApplicantsStatusService(
      application.id,
      apiBody,
    );
    if (isUpdated) {
      setShowExtendScope(false);
      setApplication({ ...application, schedule: newSchedules });
      setSchedules([
        {
          delivery: '',
          date: '',
        },
      ]);
    } else if (error) {
      setError(error);
    }
    setExtending(false);
  };

  // fetch user chat
  const fetchMessages = useCallback(
    async (partner) => {
      setLoading(true);
      setError('');
      const { messages, error } = await getMessagesService(partner, id);
      if (messages) {
        setMessages(messages);
        setUnreadCount(
          messages.filter(
            (item) => item.read === false && item.sender === loggedInUserId,
          ).length,
        );
      } else if (error) {
        setError(error);
      }
      setLoading(false);
    },
    [loggedInUserId, id],
  );

  const fetchApplicationDetails = useCallback(async () => {
    setLoading(true);
    setError('');
    const query = {};
    if (window.location.pathname.includes('completed')) {
      query['completed'] = true;
    } else {
      query['active'] = true;
    }
    const { application, error } = await fetchApplicationService(id, query);
    if (application) {
      setApplication(application);
      const partner =
        loggedInUserId === application.user_id
          ? application.owner_id
          : application.user_id;
      setPartnerId(partner);
      fetchMessages(partner);
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  }, [id, fetchMessages, loggedInUserId]);

  // send messages
  const sendMessage = async () => {
    setSending(true);
    if (messageText) {
      const obj = {
        receiver: partnerId,
        body: messageText,
        application: id,
      };

      const { message } = await sendMessageService(obj);

      if (message) {
        fetchMessages(partnerId);
        setMessageText('');
        setEditorState(EditorState.createEmpty());
      }
    }
    setSending(false);
  };

  useEffect(() => {
    fetchApplicationDetails();
  }, [fetchApplicationDetails]);

  useEffect(() => {
    const socket = socketIOClient(BASE_URL);
    socket.emit('join', loggedInUserId);
    socket.on('message', (data) => {
      if (data.receiver === loggedInUserId && data.sender === partnerId) {
        const copy = messages ? [...messages, data] : [data];
        setMessages(copy);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [messages, application, loggedInUserId, partnerId]);

  const completeMilestone = async () => {
    setError('');
    setOperating(true);
    const newSchedule = JSON.parse(JSON.stringify(application.schedule));
    const nextIndex = newSchedule.filter(
      (item: any) => item.is_completed,
    ).length;
    if (newSchedule.length > nextIndex) {
      newSchedule[nextIndex].is_completed = true;
      const { isUpdated, error } = await updateApplicantsStatusService(id, {
        schedule: newSchedule,
      });

      if (isUpdated) {
        setApplication({ ...application, schedule: newSchedule });
      }

      if (error) {
        setError(error);
      }
    }
    setOperating(false);
  };

  const incompleteMilestone = async () => {
    setError('');
    setOperating(true);
    const newSchedule = JSON.parse(JSON.stringify(application.schedule));
    const nextIndex = newSchedule.filter(
      (item: any) => item.is_completed,
    ).length;
    if (newSchedule.length) {
      newSchedule[nextIndex - 1].is_completed = false;
      const { isUpdated, error } = await updateApplicantsStatusService(id, {
        schedule: newSchedule,
      });

      if (isUpdated) {
        setApplication({ ...application, schedule: newSchedule });
      }

      if (error) {
        setError(error);
      }
    }
    setOperating(false);
  };

  const completeProject = async () => {
    if (
      !application ||
      application.completed_at ||
      loggedInUserId !== application.owner_id ||
      operating
    ) {
      return;
    }
    setError('');
    setOperating(true);
    const { isUpdated, error } = await updateApplicantsStatusService(id, {
      is_completed: true,
    });

    if (isUpdated) {
      History.push(`${ROUTES.DASHBOARD}${ROUTES.COMPLETED_JOBS}`);
    }

    if (error) {
      setError(error);
    }
    setOperating(false);
  };

  const getTimeLeft = (deadline) => {
    if (deadline && /\d{4}-\d{2}-\d{2}/.test(deadline)) {
      return `${moment(deadline, 'YYYY-MM-DD').diff(moment(), 'days')} days`;
    }
    return deadline;
  };

  return (
    <div className={styles.activeJob}>
      <Container className="p-0">
        {application && (
          <Row>
            <Col xs={12}>
              <div className={styles.topBar}>
                <div className={styles.topBarLeft}>
                  {application.completed_at ? 'Completed' : 'Active'} Job
                </div>
                {!!unreadCount && (
                  <div className={styles.newMessage}>
                    {unreadCount} New Messages
                  </div>
                )}
              </div>
              <div className={styles.headWrapper}>
                <div className={styles.mainHeading}>
                  {application.job_title}
                </div>
                <div className={styles.subHeading}>
                  {application.job_description}
                </div>
              </div>
              <div className={styles.mainContent}>
                <div className={styles.jobLeftSec}>
                  <div className={styles.jobDetailTop}>
                    <Row>
                      <Col xs="12" sm="4">
                        <div className={styles.jobLabel}>Agreed Price</div>
                        <div className={styles.jobValue}>
                          $
                          {
                            application.offers.find(
                              (item) => item.is_accepted === true,
                            ).amount
                          }
                        </div>
                      </Col>
                      <Col xs="12" sm="4">
                        <div className={styles.jobLabel}>Additional Hourly</div>
                        <div className={styles.jobValue}>
                          ${application.hourly_fee}/hr
                        </div>
                      </Col>
                      {application.user_resume ? (
                        <Col xs="12" sm="4">
                          <div className={styles.jobLabel}>View Resume</div>
                          <div className={styles.jobValue}>
                            <a
                              href={application.user_resume}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Resume Download
                            </a>
                          </div>
                        </Col>
                      ) : (
                        <Col xs="12" sm="4">
                          <div className={styles.jobLabel}>Profile</div>
                          <div className={styles.jobValue}>
                            <Link
                              to={`${ROUTES.PROFILE}/${application.slug}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Profile
                            </Link>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                  <div className={styles.chatSection}>
                    <div className={styles.heading}>Chat</div>
                    {!showAll && messages?.length > 10 && (
                      <div className={styles.btnSec}>
                        <Button
                          label={`VIEW (${
                            messages?.length - 10
                          }) OLDER MESSAGES`}
                          type="w-100 lgBtn lt-gray sm-font"
                          onClick={() => setShowAll(true)}
                        />
                      </div>
                    )}

                    <div className={styles.chatBox}>
                      {!!messages?.length &&
                        messages
                          .slice(
                            !showAll && messages.length > 10
                              ? messages.length - 10
                              : 0,
                          )
                          .map((message, index) => (
                            <div key={index} className={styles.chatList}>
                              <div
                                className={`${styles.list} ${
                                  loggedInUserId === message.sender
                                    ? styles.listClient
                                    : ''
                                } ${
                                  !message.read &&
                                  message.sender !== loggedInUserId
                                    ? styles.newChat
                                    : ''
                                }`}
                              >
                                <div className={styles.img}>
                                  <a
                                    href={`${ROUTES.PROFILE}/${message.sender_slug}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={
                                        message.sender_photo ||
                                        'https://mic-react.s3.amazonaws.com/place_70x70.png'
                                      }
                                      alt={message.sender_name}
                                    />
                                  </a>
                                </div>
                                <div className={styles.messageInfo}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: message.body,
                                    }}
                                    className={styles.MessageHeading}
                                  />
                                  <div className={styles.user}>
                                    <a
                                      href={`${ROUTES.PROFILE}/${message.sender_slug}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {message.sender_name}
                                    </a>
                                  </div>
                                  <div className={styles.time}>
                                    {moment(message.created_at).fromNow()}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      <div className={styles.textAreaOuter}>
                        <Editor
                          editorState={editorState}
                          onEditorStateChange={onEditorStateChange}
                          wrapperClassName={styles.editorWrapper}
                          toolbarClassName={styles.toolbar}
                          editorClassName={styles.textEditor}
                          placeholder="Write a message"
                        />
                      </div>
                      <div className={styles.btnOuter}>
                        <Button
                          label="Send Message"
                          type="transparent outline-gray lgBtn"
                          disabled={!messageText || sending}
                          onClick={sendMessage}
                        />
                        <Button
                          label="Mark As Read"
                          type="transparent fadeBtn lgBtn"
                          disabled={sending || unreadCount === 0}
                          onClick={markRead}
                        />
                      </div>
                      <div className={styles.smallNote}>
                        Do not share personal information including email,
                        phone, etc.
                      </div>
                      <div className={styles.helpSection}>
                        <div className={styles.imgSection}>
                          <SVG baseURL="/" description="Help" src={Help} />
                        </div>
                        <div className={styles.helpDetail}>
                          <div className={styles.topDetail}>
                            Have a question?
                          </div>
                          <div className={styles.bottomDetail}>
                            <a
                              href={
                                COPY_OVERRIDES?.active_job?.support_link ||
                                ROUTES.SUPPORT
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Talk to support.
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.jobRightSec}>
                  {application?.completed_at ? (
                    <div className={styles.dueDays}>
                      <div className={styles.secValue}>Completed</div>
                      <div className={styles.jobDate}>
                        {moment(application.completed_at).format('YYYY-MM-DD')}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.dueDays}>
                      <div className={styles.secLabel}>Due in</div>
                      {application.deadline && (
                        <div className={styles.secValue}>
                          {getTimeLeft(application.deadline)}
                        </div>
                      )}
                      <div className={styles.jobDate}>
                        {application.deadline}
                      </div>
                    </div>
                  )}
                  <div className={styles.profileSec}>
                    <div className={styles.secLabel}>View Profile</div>
                    <div className={styles.secValue}>
                      <Link
                        to={`${ROUTES.PROFILE}/${application.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {application.first_name
                          ? application.first_name
                          : application.user_name}{' '}
                        {application.last_name || ''}
                      </Link>
                    </div>
                  </div>

                  <div className={styles.schBreakdown}>
                    <div className={styles.schListOuter}>
                      {application.schedule?.map((item, index) => (
                        <div
                          key={index}
                          className={`${styles.schListing} ${
                            item.is_completed ? styles.active : ''
                          }`}
                        >
                          <div className={styles.schTiming}>
                            {moment(item.date).format('MMMM Do, YYYY')}
                          </div>
                          <div className={styles.schDesc}>{item.delivery}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {!application.completed_at && (
                    <>
                      <div className={styles.shotDesc}>
                        Milestones are simply to help client navigate projects.
                        Money is only exchanged when the paying party marks the
                        project as completed.
                      </div>
                      <div className={styles.btnOuterMain}>
                        <Button
                          label="Mark Previous Milestone Incomplete"
                          type="w-100 lgBtn light-green md-font"
                          disabled={
                            operating ||
                            !application ||
                            !application.schedule?.length ||
                            application.schedule.filter(
                              (item) => item.is_completed,
                            ).length === 0 ||
                            loggedInUserId !== application?.owner_id
                          }
                          onClick={incompleteMilestone}
                        />
                        <Button
                          label="Mark Next Milestone Complete"
                          type="w-100 lgBtn light-green md-font"
                          disabled={
                            operating ||
                            !application ||
                            !application.schedule?.length ||
                            application.schedule.filter(
                              (item) => item.is_completed,
                            ).length === application.schedule.length ||
                            loggedInUserId !== application?.owner_id
                          }
                          onClick={completeMilestone}
                        />
                        <Button
                          label="Extend Scope + Add New Phase"
                          type="transparent outline-gray w-100 lgBtn md-font"
                          disabled={
                            operating ||
                            !application ||
                            !!application.completed_at ||
                            loggedInUserId !== application?.owner_id
                          }
                          onClick={() => setShowExtendScope(true)}
                        />
                      </div>
                      <div className={styles.shotDesc}>
                        Once a project is marked as complete, the service
                        provider will be paid. Only the Gig Creator can mark a
                        project as complete, and there is no reversing this
                        process.
                      </div>
                      <div className={styles.btnOuter}>
                        <Button
                          label={
                            operating
                              ? 'Releasing Payment...'
                              : 'Complete Gig/Release Payment'
                          }
                          type="black w-100 lgBtn"
                          disabled={
                            !application ||
                            !!application.completed_at ||
                            loggedInUserId !== application.owner_id ||
                            operating
                          }
                          onClick={() => setShowConfirmation(true)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        )}
        {error && <Alert variant="danger">{error}</Alert>}
        {!application && loading && <Loader />}
        <Modal
          show={showConfirmation}
          onHide={() => setShowConfirmation(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Complete Project</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              Are you sure you want to mark entire project complete and release
              the payment?
            </p>
            <div>Note: this cannot be reversed.</div>
            {error && <Alert variant="danger">{error}</Alert>}
          </Modal.Body>

          <Modal.Footer>
            <ModalBtn
              variant="secondary"
              onClick={() => setShowConfirmation(false)}
            >
              Cancel
            </ModalBtn>
            <ModalBtn
              variant="primary"
              disabled={operating}
              onClick={completeProject}
            >
              Proceed
            </ModalBtn>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showExtendScope}
          onHide={() => setShowExtendScope(false)}
          className={styles.extendScopeModal}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <div
            className={styles.closeBtn}
            onClick={() => setShowExtendScope(false)}
          >
            Close{' '}
            <span>
              <SVG
                baseURL="/"
                className={styles.closeIcon}
                description="close"
                loader={<span>Loading...</span>}
                src={closeIcon}
              />
            </span>
          </div>
          <Modal.Header>
            <Modal.Title>Add New Phase</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={styles.modalBody}>
              {schedules &&
                schedules.map((item, index) => (
                  <Row key={index}>
                    <Col md={4} sm={6}>
                      <div className={styles.authInput}>
                        <Input
                          type="date"
                          onChange={(e) => handleScheduleChange(e, index)}
                          value={item.date}
                          name="date"
                        />
                      </div>
                    </Col>
                    <Col md={8} sm={6}>
                      <div className={styles.authInput}>
                        <Input
                          onChange={(e) => handleScheduleChange(e, index)}
                          name="delivery"
                          type="text"
                          value={item.delivery}
                        />
                      </div>
                    </Col>
                  </Row>
                ))}
              <Row>
                <Col className={styles.addButton}>
                  <Button
                    onClick={addSchedule}
                    type="outline-gray"
                    size="lg"
                    label="Add More"
                  />
                </Col>
              </Row>
              {error && (
                <div>
                  <Alert variant="danger">
                    {error.includes('sandbox')
                      ? 'User is not available in recipients list.'
                      : error}
                  </Alert>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={extendScope}
              disabled={extending}
              label={loading ? 'Saving...' : 'Extend Scope'}
              type="transparent w-100 branding lgBtn md-font"
            />
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default ActiveJob;
