import React, { useState } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';

// Page Components
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import TalentCard from '../TalentCard/TalentCard';

// Interfaces
import { Talent, MyJob } from 'interfaces/User.types';

// Constants
import { ITEMS_PER_PAGE } from 'constants/Constants';

// SCSS
import styles from './Listing.module.scss';

interface ListingType {
  loading?: boolean;
  users: Array<Talent> | null;
  jobs: Array<MyJob> | null;
  totalUsers: number | null;
  fetchMoreUsers: (page: number) => void;
  error?: string;
}

const Listing = ({
  users,
  totalUsers,
  loading,
  jobs,
  fetchMoreUsers,
  error,
}: ListingType) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const fetchMore = () => {
    setCurrentPage(currentPage + 1);
    fetchMoreUsers(currentPage + 1);
  };

  return (
    <div className={styles.searchResults}>
      {users &&
        users.map((user) => (
          <TalentCard key={user.slug} jobs={jobs} user={user} />
        ))}

      {loading && <Loader />}

      {users && users.length === 0 && !loading && (
        <Alert variant="primary">No Users Found</Alert>
      )}
      {!users && !loading && error && <Alert variant="danger">{error}</Alert>}

      {!loading &&
      totalUsers &&
      totalUsers > ITEMS_PER_PAGE &&
      totalUsers !== users?.length ? (
        <Row>
          <Col
            className={`${styles.listingViewAll}`}
            xl={12}
            lg={12}
            md={12}
            sm={12}
          >
            <Button type="large" label="View More" onClick={fetchMore} />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default React.memo(Listing);
