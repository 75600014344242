import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import findIndex from 'lodash/findIndex';
import qs from 'qs';

// Page Components
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Content from './components/Content/Content';
import More from './components/More/More';

// Context
import GlobalContext from 'contexts/Global.context';

// Services
import {
  fetchJobDetailsService,
  fetchJobsService,
  // updateInvitationService,
} from 'services/JobService';

// Interfaces
import { Job } from 'interfaces/Job.types';

// SCSS
import styles from './Page.module.scss';
import Loader from 'components/Loader/Loader';
import { APP_CONSTANTS, JOB_TYPE } from 'constants/Constants';

const Page: React.FC = () => {
  const { id } = useParams();
  const { invited } = qs.parse(useLocation().search, {
    ignoreQueryPrefix: true,
  });

  const {
    userDetails: { email },
    variantDetails,
  } = useContext(GlobalContext);

  const [jobDetails, setJobDetails] = useState<Job | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [jobCategory, setJobCategory] = useState<string>('');
  const [relatedJobs, setRelatedJobs] = useState<Array<Job> | null>(null);
  const [relatedJobsLoading, setRelatedJobsLoading] = useState<boolean>(false);
  // const [relatedJobsError, setRelatedJobsError] = useState<string>('');

  const fetchRelatedJobs = useCallback(
    async (jobType?: string, category?: string) => {
      setRelatedJobsLoading(true);
      const { jobs } = await fetchJobsService({
        type: jobType,
        keyword: category,
      });

      if (jobs) {
        const sameJobIndex = findIndex(jobs, { id: id });
        if (sameJobIndex > -1) {
          jobs.splice(sameJobIndex, 1);
        }
        if (jobs.length) {
          setRelatedJobs(jobs);
        }
      }
      setRelatedJobsLoading(false);
    },
    [id],
  );

  const callAnalytics = useCallback(
    (jobDetails) => {
      window.analytics.track(
        JOB_TYPE.career === jobDetails.type
          ? 'Viewed a job listing'
          : 'Viewed an opportunity',
        {
          title: jobDetails.title,
          type: JOB_TYPE,
          category: jobDetails.category,
          location: jobDetails.location,
          pay: jobDetails.rate,
          jobDescription: jobDetails.opportunity_description,
          aboutCompany: jobDetails.hiring_description,
          hiringDescription: jobDetails.hiring_description,
          viewedBy: email,
        },
      );
    },
    [email],
  );

  const fetchJobDetails = useCallback(async () => {
    setLoading(true);
    const { jobDetails, error } = await fetchJobDetailsService(id);
    if (jobDetails) {
      setJobDetails(jobDetails);
      setJobCategory(jobDetails.category);
      fetchRelatedJobs(jobDetails.type, jobDetails.category);
      callAnalytics(jobDetails);
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  }, [id, fetchRelatedJobs, callAnalytics]);

  // const acceptJobInvite = useCallback(async () => {
  //   if (invite) {
  //     await updateInvitationService(invite, {
  //       status: 'accepted',
  //     });
  //   }
  // }, [invite]);

  const getPayText = () => {
    if (jobDetails.type === JOB_TYPE.career) {
      if (jobDetails?.min_pay && jobDetails?.max_pay) {
        return `$${(
          Number(jobDetails.min_pay) *
          ((100 - APP_CONSTANTS.platformFee) / 100)
        ).toFixed(2)}-$${(
          Number(jobDetails.max_pay) *
          ((100 - APP_CONSTANTS.platformFee) / 100)
        ).toFixed(2)}`;
      } else if (jobDetails?.min_pay) {
        return `$${(
          Number(jobDetails.min_pay) *
          ((100 - APP_CONSTANTS.platformFee) / 100)
        ).toFixed(2)}`;
      } else if (jobDetails?.min_pay === 0 && jobDetails?.max_pay === 0) {
        return 'Non-Paying';
      }
      return 'TBD';
    } else {
      if (jobDetails?.min_pay && jobDetails?.max_pay) {
        return `$${Number(jobDetails.min_pay).toFixed(2)}-$${Number(
          jobDetails.max_pay,
        ).toFixed(2)}`;
      } else if (jobDetails?.min_pay) {
        return `$${Number(jobDetails.min_pay).toFixed(2)}`;
      } else if (jobDetails?.min_pay === 0 && jobDetails?.max_pay === 0) {
        return 'No Cost';
      }
      return 'TBD';
    }
  };

  useEffect(() => {
    fetchJobDetails();
    // acceptJobInvite();
  }, [fetchJobDetails]);

  return (
    <Container className={styles.resultPage} fluid>
      {jobDetails && (
        <Fragment>
          <Helmet>
            <title>
              {jobDetails.title} - {jobDetails.category} -{' '}
              {variantDetails?.site_title}{' '}
            </title>
            <meta
              name="description"
              content={`${jobDetails.city}, ${
                jobDetails.state
              } - ${jobDetails.opportunity_description.substr(0, 150)}`}
            />
            {jobDetails.company_logo && (
              <meta
                property="og:image"
                content={`https://res.cloudinary.com/careermap/image/fetch/w_170,h_170,c_lfill,g_auto,f_auto/${jobDetails.company_logo}`}
              />
            )}
            <meta
              property="og:title"
              content={`${jobDetails.title} - ${jobDetails.category} - ${variantDetails?.site_title}`}
            />
          </Helmet>
          <Header
            jobId={jobDetails.id}
            title={jobDetails.title}
            location={`${jobDetails.city}, ${jobDetails.state}`}
            label={jobDetails.category}
            category={jobDetails.category}
            pay={getPayText()}
            minPay={jobDetails.min_pay}
            maxPay={jobDetails.max_pay}
            postedBy={jobDetails.user}
            applied={jobDetails.applied}
            externalURL={jobDetails.external_url}
            type={jobDetails.type}
            applyThrough={jobDetails.apply_through}
            company={jobDetails.company_name}
            isArchived={jobDetails.is_deleted}
            logo={jobDetails.company_logo}
            jobDetails={jobDetails}
          />
        </Fragment>
      )}

      <Container className={styles.jobDetailsContainer}>
        {jobDetails && !loading && !error && (
          <div>
            {invited && (
              <div className={styles.invited}>
                <Alert variant="primary">Invitation sent successfully!</Alert>
              </div>
            )}
            <Row className={styles.resultMain}>
              <Col xl lg md={12} sm={12} className={styles.resultContent}>
                <Content
                  jobDescription={jobDetails.opportunity_description}
                  aboutTheLabel={jobDetails.hiring_description}
                  postedBy={jobDetails.user}
                  credentialName={jobDetails.credential_name}
                  credentialLink={jobDetails.credential_link}
                  jobId={jobDetails.id}
                  title={jobDetails.title}
                />
              </Col>
              <Col xl lg md={12} sm={12} className={styles.resultSidebar}>
                <Sidebar
                  postedDate={jobDetails.created_at}
                  location={jobDetails.location}
                  referenceLinks={jobDetails.reference_links}
                  category={jobDetails.category}
                  fee={jobDetails.fee}
                  type={jobDetails.type}
                  dueDate={jobDetails.deadline}
                  idealApplicant={
                    jobDetails.ideal_applicant ||
                    jobDetails.other_ideal_applicant
                  }
                  speciality={jobDetails.speciality}
                  fellowship={jobDetails.fellowship}
                  fellowshipSub={jobDetails.fellowship_sub}
                  licensedState={jobDetails.licensed_state}
                  experience={jobDetails.experience}
                />
              </Col>
            </Row>
            <Row>
              {!relatedJobsLoading && relatedJobs && (
                <Col xl lg md={12} sm={12} className={`${styles.resultMore}`}>
                  <More
                    jobType={jobDetails.type}
                    jobCategory={jobCategory}
                    jobs={relatedJobs}
                  />
                </Col>
              )}

              {relatedJobsLoading && <Loader />}
            </Row>
          </div>
        )}

        {!jobDetails && loading && <Loader />}
      </Container>
    </Container>
  );
};

export default React.memo(Page);
