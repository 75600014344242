import React from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
// Components
import Button from 'components/Button/Button';

import { IJobCard } from 'interfaces/Job.types';

import { ROUTES } from 'constants/Constants';

// SCSS
import styles from './JobCard.module.scss';

interface Props {
  job: IJobCard;
  status: string;
}

const JobCard: React.FC<Props> = ({ status, job }: Props) => {
  const getTimeLeft = (deadline) => {
    if (deadline && /\d{4}-\d{2}-\d{2}/.test(deadline)) {
      return `Due in ${moment(deadline).diff(moment(), 'days')} days`;
    }
    return deadline;
  };
  return (
    <Row>
      <Col>
        <div className={styles.jobList}>
          <div className={styles.list}>
            <div
              className={`${styles.newMessage} ${styles.unread} ${
                !job.unread_messages ? styles.nounread : ''
              }`}
            >
              {job.unread_messages || 0} New Messages
            </div>
            <div className={styles.jobHeading}>
              <span>{job.title}</span>
              <span className={styles.jobAmount}>${job.cost}</span>
            </div>
            <div
              className={`${styles.jobStatus} ${
                status !== 'completed' ? styles.pending : ''
              }`}
            >
              {status === 'completed' ? 'Completed' : getTimeLeft(job.deadline)}
            </div>
            <p>{job.opportunity_description}</p>
            <div className={styles.jobBottom}>
              <Button
                link={
                  status === 'completed'
                    ? `${ROUTES.COMPLETED_JOBS}/${job.application_id}`
                    : `${ROUTES.ACTIVE_JOBS}/${job.application_id}`
                }
                target="_blank"
                label="View Job"
                type="transparent outline-gray smBtn"
              />
              <div className={styles.viewProfile}>
                <div className={styles.imgSection}>
                  <img
                    src={job.applicant_photo || 'https://placehold.co/28x28'}
                    alt={job.applicant_user_name}
                  />
                </div>
                <div className={styles.profileName}>
                  {job.applicant_user_name}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default JobCard;
