import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { Row, Col, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';

// Components
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Form/Input/Input';
import TextArea from '../../../../components/Form/TextArea/TextArea';
import FileUpload from 'components/FileUpload/FileUpload';
import Select from 'components/Form/Select/Select';
import Loader from 'components/Loader/Loader';

import { Multiselect } from 'multiselect-react-dropdown';

// Context
import GlobalContext from 'contexts/Global.context';

// Constants
import { ROUTES } from 'constants/Constants';
import STATES from 'constants/States';
import INDUSTRIES from 'config/Industries';
import { PHONE_TYPES } from 'constants/Constants';
import {
  EDUCATIONS,
  GOALS,
  SPECIALITIES,
  QUALIFICATIONS,
  FELLOWSHIPS,
  FELLOWSHIP_SUBSPECIALITIES,
} from 'constants/Educations';

// Styles
import styles from './EditProfile.module.scss';

// Types
import { Category } from 'interfaces/Category.types';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

import {
  fetchUserDetailsService,
  updateUserAccountInfoService,
} from 'services/UserService';
import {
  fetchCategoriesService,
  getUploadURLService,
} from 'services/CommonService';

import { FILE_TYPE } from 'constants/Constants';
import { camelToSnake, validateURL } from 'utils/UtilityFunctions';

interface PersonaInfo {
  firstName: string;
  lastName: string;
  bio: string;
  /* about: string; */
  careerCategories: Array<any>;
  opportunityCategories: Array<any>;
  industry?: string;
  goals?: Array<any>;
  otherGoals?: string;
  phoneType?: string;
  phone?: string;
  alternateEmail?: string;
  professionalClassification?: string;
  professionalClassificationOther?: string;
  preferredTitle?: string;
  speciality?: string;
  specialityHospital?: string;
  specialityCity?: string;
  specialityState?: string;
  fellowship?: string;
  fellowshipSub?: string;
  fellowshipHospital?: string;
  fellowshipSubCity?: string;
  fellowshipSubState?: string;
  licensedStates?: Array<any>;
}

interface Location {
  city: string;
  state: string;
  zip: number;
}

interface School {
  name?: string;
  notes?: string;
  level?: string;
}

interface Company {
  title?: string;
  name?: string;
  date_range?: string;
}

interface Websites {
  website: string;
  facebook: string;
  twitter: string;
  youtube: string;
  instagram: string;
  /* additionalLink1: string;
  additionalLink2: string; */
  /* additionalLinks: Array<string>; */
}

const USER_INFO_ERRORS: any = {
  firstName: 'Please enter your first name',
  preferredTitle: 'Please enter your first name',
  lastName: 'Please enter your last name',
  bio: 'Please enter personal bio',
  /* about: 'Please enter something about yourself', */
  city: 'Please enter the city',
  state: 'Please enter the state',
  zip: 'Please enter the zip code',
  website: 'Please enter a valid website URL',
  industry: 'Please enter the industry',
  goals: 'Please select your goals',
  professionalClassification: 'Please select your professional classification',
};

const USER_DETAILS_VALIDATIONS: any = {
  firstName: {
    min: 1,
    max: 20,
    error: 'Please enter characters between 1 and 20',
  },
  preferredTitle: {
    min: 1,
    max: 30,
    error: 'Please enter characters between 1 and 30',
  },
  lastName: {
    min: 1,
    max: 20,
    error: 'Please enter characters between 1 and 20',
  },
  userName: {
    min: 1,
    max: 30,
    error: 'Please enter characters between 1 and 30',
  },
  bio: {
    min: 1,
    max: 10000,
    error: 'Bio cannot be longer than 10,000 characters',
  },
  city: {
    min: 1,
    max: 20,
    error: 'Please enter characters between 1 and 20',
  },
  state: {
    min: 1,
    max: 20,
    error: 'Please enter characters between 1 and 20',
  },
  zip: {
    min: 5,
    max: 6,
    error: 'Please enter characters between 5 and 6',
  },
};

const EditProfile = () => {
  const {
    userDetails: {
      id,
      first_name,
      last_name,
      bio: current_bio,
      photo: current_photo,
      /* about: current_about, */
      resume: current_resume,
      city: current_city,
      state: current_state,
      zip: current_zip,
      website: current_website,
      facebook: current_facebook,
      twitter: current_twitter,
      youtube: current_youtube,
      instagram: current_instagram,
      industry: current_industry,
      slug,
      goals: current_goals,
      other_goals,
      phone_type,
      phone: current_phone,
      alternate_email,
      professional_classification,
      professional_classification_other,
      preferred_title,
      speciality: current_speciality,
      speciality_hospital,
      speciality_city,
      speciality_state,
      fellowship: current_fellowship,
      fellowship_sub,
      fellowship_hospital,
      fellowship_sub_city,
      fellowship_sub_state,
      licensed_states,
      companies: current_companies,
      schools: current_schools,
    },
    setAuth,
    variantDetails,
  } = useContext(GlobalContext);

  // personal info state
  const [personalInfo, setPersonalInfo] = useState<PersonaInfo>({
    firstName: first_name,
    lastName: last_name,
    bio: current_bio,
    careerCategories: [],
    opportunityCategories: [],
    industry: current_industry,
    goals: current_goals,
    otherGoals: other_goals,
    phoneType: phone_type,
    phone: current_phone,
    alternateEmail: alternate_email,
    professionalClassification: professional_classification,
    professionalClassificationOther: professional_classification_other,
    preferredTitle: preferred_title,
    speciality: current_speciality,
    specialityHospital: speciality_hospital,
    specialityCity: speciality_city,
    specialityState: speciality_state,
    fellowship: current_fellowship,
    fellowshipSub: fellowship_sub,
    fellowshipHospital: fellowship_hospital,
    fellowshipSubCity: fellowship_sub_city,
    fellowshipSubState: fellowship_sub_state,
    licensedStates: licensed_states,
  });

  // location info state
  const [location, setLocation] = useState<Location>({
    city: current_city,
    state: current_state,
    zip: current_zip,
  });

  // websites info state
  const [websites, setWebsites] = useState<Websites>({
    website: current_website,
    facebook: current_facebook,
    twitter: current_twitter,
    youtube: current_youtube,
    instagram: current_instagram,
  });

  // additional Links
  const [additionalLinks, setadditionalLinks] = useState<any>({
    additionalLink1: { title: '', link: '' },
    additionalLink2: { title: '', link: '' },
    additionalLink3: { title: '', link: '' },
    additionalLink4: { title: '', link: '' },
    additionalLink5: { title: '', link: '' },
  });

  const [companies, setCompanies] = useState<Company[] | null>([
    {
      title: (current_companies && current_companies[0]?.title) || '',
      name: (current_companies && current_companies[0]?.name) || '',
      date_range: (current_companies && current_companies[0]?.date_range) || '',
    },
    {
      title: (current_companies && current_companies[1]?.title) || '',
      name: (current_companies && current_companies[1]?.name) || '',
      date_range: (current_companies && current_companies[1]?.date_range) || '',
    },
    {
      title: (current_companies && current_companies[2]?.title) || '',
      name: (current_companies && current_companies[2]?.name) || '',
      date_range: (current_companies && current_companies[2]?.date_range) || '',
    },
  ]);

  const [schools, setSchools] = useState<School[] | null>([
    {
      name: (current_schools && current_schools[0]?.name) || '',
      notes: (current_schools && current_schools[0]?.notes) || '',
      level: (current_schools && current_schools[0]?.level) || '',
    },
    {
      name: (current_schools && current_schools[1]?.name) || '',
      notes: (current_schools && current_schools[1]?.notes) || '',
      level: (current_schools && current_schools[1]?.level) || '',
    },
    {
      name: (current_schools && current_schools[2]?.name) || '',
      notes: (current_schools && current_schools[2]?.notes) || '',
      level: (current_schools && current_schools[2]?.level) || '',
    },
  ]);

  const [validationErrors, setValidationErrors] = useState<any>({
    firstName: '',
    lastName: '',
    bio: '',
    categories: '',
    city: '',
    state: '',
    zip: '',
    website: '',
    facebook: '',
    twitter: '',
    youtube: '',
    instagram: '',
    industry: '',
    preferredTitle: '',
    additionalLink1Title: '',
    additionalLink2Title: '',
    additionalLink3Title: '',
    additionalLink4Title: '',
    additionalLink5Title: '',
    additionalLink1: '',
    additionalLink2: '',
    additionalLink3: '',
    additionalLink4: '',
    additionalLink5: '',
    goals: '',
    professionalClassification: '',
  });

  const [photo, setPhoto] = useState<null | File>(null);

  const [categories, setCategories] = useState<Array<Category | any>>(null);

  // const [resumeFile, setResumeFile] = useState<null | File>(null);
  const resumeFile = null;

  const [newToken, setNewToken] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>('');

  const [fetchLoading, setFetchLoading] = useState<boolean>(false);

  const [currentResume, setCurrentResume] = useState<string>(
    current_resume
      ? current_resume.substr(current_resume.lastIndexOf('/') + 1)
      : '',
  );

  const handlePersonalInfoChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;

    if (!value) {
      if (name !== 'industry' || variantDetails?.site_short_title === 'CCE') {
        setValidationErrors({
          ...validationErrors,
          [name]: USER_INFO_ERRORS[name],
        });
      }
    } else {
      if (USER_DETAILS_VALIDATIONS[name]) {
        if (
          value.length > USER_DETAILS_VALIDATIONS[name].max ||
          value.length < USER_DETAILS_VALIDATIONS[name].min
        ) {
          setValidationErrors({
            ...validationErrors,
            [name]: USER_DETAILS_VALIDATIONS[name].error,
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            [name]: '',
          });
        }
      } else {
        setValidationErrors({
          ...validationErrors,
          [name]: '',
        });
      }
    }

    setPersonalInfo({
      ...personalInfo,
      [name]: value,
    });
  };

  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (!value) {
      setValidationErrors({
        ...validationErrors,
        [name]: USER_INFO_ERRORS[name],
      });
    } else {
      if (USER_DETAILS_VALIDATIONS[name]) {
        if (
          value.length > USER_DETAILS_VALIDATIONS[name].max ||
          value.length < USER_DETAILS_VALIDATIONS[name].min
        ) {
          setValidationErrors({
            ...validationErrors,
            [name]: USER_DETAILS_VALIDATIONS[name].error,
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            [name]: '',
          });
        }
      } else {
        setValidationErrors({
          ...validationErrors,
          [name]: '',
        });
      }
    }

    setLocation({
      ...location,
      [name]: value,
    });
  };

  const handleWebsiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (value) {
      if (validateURL(value)) {
        setValidationErrors({
          ...validationErrors,
          [name]: '',
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [name]: USER_INFO_ERRORS['website'],
        });
      }
    } else {
      setValidationErrors({
        ...validationErrors,
        [name]: '',
      });
    }

    setWebsites({
      ...websites,
      [name]: value,
    });
  };

  const handleAdditionalLinksChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;

    if (name.includes('Title')) {
      const linkKey = name.split('Title')[0];
      if (value) {
        if (!additionalLinks[linkKey].link) {
          setValidationErrors({
            ...validationErrors,
            [linkKey]: 'Please enter URL',
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            [linkKey]: validateURL(additionalLinks[linkKey].link)
              ? ''
              : USER_INFO_ERRORS['website'],
            [name]: '',
          });
        }
      } else {
        if (!additionalLinks[linkKey].link) {
          setValidationErrors({
            ...validationErrors,
            [linkKey]: '',
            [name]: '',
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            [linkKey]: validateURL(additionalLinks[linkKey].link)
              ? ''
              : USER_INFO_ERRORS['website'],
            [name]: 'Please enter title',
          });
        }
      }

      setadditionalLinks({
        ...additionalLinks,
        [linkKey]: {
          ...additionalLinks[linkKey],
          title: value,
        },
      });
    } else {
      const titleKey = `${name}Title`;
      if (value) {
        if (!additionalLinks[name].title) {
          setValidationErrors({
            ...validationErrors,
            [titleKey]: 'Please enter title',
            [name]: validateURL(value) ? '' : USER_INFO_ERRORS['website'],
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            [titleKey]: '',
            [name]: validateURL(value) ? '' : USER_INFO_ERRORS['website'],
          });
        }
      } else {
        if (!additionalLinks[name].title) {
          setValidationErrors({
            ...validationErrors,
            [titleKey]: '',
            [name]: '',
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            [titleKey]: '',
            [name]: 'Please enter URL',
          });
        }
      }

      setadditionalLinks({
        ...additionalLinks,
        [name]: {
          ...additionalLinks[name],
          link: value,
        },
      });
    }
  };

  const handlePhotoChange = (image: File | null) => {
    setPhoto(image);
  };

  // const handleResumeChange = (resume: File | null) => {
  //   setCurrentResume('');
  //   setResumeFile(resume);
  // };

  const uploadPhoto = async () => {
    setLoading(true);
    let uploadImageResponse: any = null;
    let errorOccurred: any = null;
    if (photo) {
      const fileInfo = {
        name: photo.name,
        type: photo.type,
      };
      const { signedRequest, url, error } = await getUploadURLService(fileInfo);

      if (signedRequest && url) {
        uploadImageResponse = await fetch(signedRequest, {
          method: 'PUT',
          body: photo,
        });
        return { uploadImageResponse, url };
      } else if (error) {
        setApiError(`An error occurred while uploading photo: ${error}`);
        setLoading(false);
        errorOccurred = error;
        return { error };
      }
    }
    return { uploadImageResponse, error: errorOccurred };
  };

  const uploadResume = async () => {
    setLoading(true);
    if (resumeFile) {
      const fileInfo = {
        name: resumeFile.name,
        type: resumeFile.type,
      };
      const { signedRequest, url, error } = await getUploadURLService(fileInfo);

      if (signedRequest && url) {
        const uploadResumeResponse = await fetch(signedRequest, {
          method: 'PUT',
          body: resumeFile,
        });
        return { uploadResumeResponse, url };
      } else if (error) {
        setApiError(`An error occurred while uploading photo: ${error}`);
        setLoading(false);
        return { error };
      }
    }
  };

  const updateProfile = async (photoURL?: string, resumeURL?: string) => {
    setLoading(true);
    const consolidatedRequestBody: any = {
      ...personalInfo,
      ...location,
      ...websites,
    };
    const reducer = (acc: any, current: string) => {
      const newObj: any = {};
      if (
        current !== 'additionalLink1' &&
        current !== 'additionalLink2' &&
        current !== 'additionalLink3' &&
        current !== 'additionalLink4' &&
        current !== 'additionalLink5'
      ) {
        newObj[camelToSnake(current)] = consolidatedRequestBody[current];
      }

      return { ...acc, ...newObj };
    };
    let requestBody = Object.keys({
      ...personalInfo,
      ...location,
      ...websites,
      ...additionalLinks,
    }).reduce(reducer, {});
    if (
      !additionalLinks.additionalLink1 &&
      !additionalLinks.additionalLink2 &&
      !additionalLinks.additionalLink3 &&
      !additionalLinks.additionalLink4 &&
      !additionalLinks.additionalLink5
    ) {
      requestBody.additional_links = [];
    } else {
      const links = [
        additionalLinks.additionalLink1,
        additionalLinks.additionalLink2,
        additionalLinks.additionalLink3,
        additionalLinks.additionalLink4,
        additionalLinks.additionalLink5,
      ];

      requestBody.additional_links = links.filter((l) => l.title && l.link);
      requestBody.additional_links = requestBody.additional_links.map(
        (item) => ({
          title: item.title,
          link: item.link.toLowerCase(),
        }),
      );
    }

    requestBody.schools = schools.filter((l) => l.level && l.name);

    requestBody.companies = companies.filter(
      (l) => l.title && l.name && l.date_range,
    );

    if (requestBody.twitter) {
      requestBody.twitter = requestBody.twitter.toLowerCase();
    }

    if (requestBody.website) {
      requestBody.website = requestBody.website.toLowerCase();
    }

    if (requestBody.youtube) {
      requestBody.youtube = requestBody.youtube.toLowerCase();
    }

    if (requestBody.facebook) {
      requestBody.facebook = requestBody.facebook.toLowerCase();
    }

    if (requestBody.instagram) {
      requestBody.instagram = requestBody.instagram.toLowerCase();
    }

    if (current_resume && !currentResume && !resumeFile) {
      requestBody = { ...requestBody, resume: '' };
    }

    if (resumeURL) {
      requestBody = { ...requestBody, resume: resumeURL };
    }

    const { token, error } = await updateUserAccountInfoService(id, {
      ...requestBody,
      career_categories: personalInfo.careerCategories
        ?.map((c) => c.name)
        .join(','),
      opportunity_categories: personalInfo.opportunityCategories
        ?.map((c) => c.name)
        .join(','),
      licensed_states: personalInfo.licensedStates
        ?.map((c) => c.label)
        .join('_'),
      goals: personalInfo.goals?.map((c) => c.label).join('_'),
      photo: photoURL,
    });

    if (token) {
      setNewToken(token);
      setAuth(token);
    } else if (error) {
      setApiError(error);
    }

    setLoading(false);
  };

  const handleSubmit = async () => {
    setApiError('');
    setNewToken('');
    if (photo && resumeFile) {
      // first try uploading photo and then upload resume
      const {
        uploadImageResponse,
        url: photoUrl,
        error,
      }: any = await uploadPhoto();
      if (uploadImageResponse) {
        const {
          uploadResumeResponse,
          url: resumeUrl,
          error,
        }: any = await uploadResume();
        if (uploadResumeResponse) {
          updateProfile(photoUrl, resumeUrl);
        } else if (error) {
          return;
        }
      } else if (error) {
        return;
      }
    } else if (photo && !resumeFile) {
      const { uploadImageResponse, url: photoUrl }: any = await uploadPhoto();
      if (uploadImageResponse) {
        updateProfile(photoUrl, '');
      }
    } else if (!photo && resumeFile) {
      const { uploadResumeResponse, url: resumeUrl }: any =
        await uploadResume();
      if (uploadResumeResponse) {
        updateProfile('', resumeUrl);
      }
    } else {
      updateProfile('', '');
    }
  };

  const handleCategorySelect = (categoryList: Array<Category>, key) => {
    /* const selectedCategories = categoryList.map((list) => ({ name: list })); */

    setPersonalInfo({
      ...personalInfo,
      [key]: categoryList,
    });
  };

  const handleMultiSelect = (list: any, key: string) => {
    setPersonalInfo({
      ...personalInfo,
      [key]: list,
    });
  };

  const handleSchoolChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const schoolsCopy = JSON.parse(JSON.stringify(schools));
    schoolsCopy[index][event.target.name] = event.target.value;
    setSchools(schoolsCopy);
  };

  const handleCompanyChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const companiesCopy = JSON.parse(JSON.stringify(companies));
    companiesCopy[index][event.target.name] = event.target.value;
    setCompanies(companiesCopy);
  };

  const isSubmitDisabled = (): boolean => {
    const {
      firstName,
      lastName,
      preferredTitle,
      bio,
      industry,
      professionalClassification,
      goals,
    } = personalInfo;
    const { city, state, zip } = location;

    if (
      firstName &&
      lastName &&
      preferredTitle &&
      bio &&
      city &&
      state &&
      zip &&
      professionalClassification &&
      goals &&
      (industry || variantDetails?.site_short_title !== 'CCE') &&
      Object.keys(validationErrors).every((e) => !validationErrors[e])
    ) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const { user } = await fetchUserDetailsService(id);

      if (user) {
        setPersonalInfo((personalInfo) => ({
          ...personalInfo,
          bio: user.bio,
          careerCategories: user.career_categories?.length
            ? user.career_categories
                .split(/(,(?=\S)|:)/)
                .filter((skill) => skill !== ',')
                .map((k) => ({ name: k }))
            : [],
          opportunityCategories: user.opportunity_categories?.length
            ? user.opportunity_categories
                .split(/(,(?=\S)|:)/)
                .filter((skill) => skill !== ',')
                .map((k) => ({ name: k }))
            : [],
          goals: user.goals
            ? user.goals.split('_').map((k) => ({ label: k }))
            : [],
          licensedStates: user.licensed_states
            ? user.licensed_states.split('_').map((k) => ({ label: k }))
            : [],
        }));

        if (user.additional_links) {
          setadditionalLinks((additionalLinks: any) => ({
            ...additionalLinks,
            additionalLink1: user.additional_links[0] || {
              title: '',
              link: '',
            },
            additionalLink2: user.additional_links[1] || {
              title: '',
              link: '',
            },
            additionalLink3: user.additional_links[2] || {
              title: '',
              link: '',
            },
            additionalLink4: user.additional_links[3] || {
              title: '',
              link: '',
            },
            additionalLink5: user.additional_links[4] || {
              title: '',
              link: '',
            },
          }));
        }
        if (user.schools?.length) {
          setSchools([
            {
              name: user.schools[0]?.name || '',
              notes: user.schools[0]?.notes || '',
              level: user.schools[0]?.level || '',
            },
            {
              name: user.schools[1]?.name || '',
              notes: user.schools[1]?.notes || '',
              level: user.schools[1]?.level || '',
            },
            {
              name: user.schools[2]?.name || '',
              notes: user.schools[2]?.notes || '',
              level: user.schools[2]?.level || '',
            },
          ]);
        }
        if (user.companies?.length) {
          setCompanies([
            {
              name: user.companies[0]?.name || '',
              title: user.companies[0]?.title || '',
              date_range: user.companies[0]?.date_range || '',
            },
            {
              name: user.companies[1]?.name || '',
              title: user.companies[1]?.title || '',
              date_range: user.companies[1]?.date_range || '',
            },
            {
              name: user.companies[2]?.name || '',
              title: user.companies[2]?.title || '',
              date_range: user.companies[2]?.date_range || '',
            },
          ]);
        }
        if (user.resume) {
          setCurrentResume(
            user.resume.substr(user.resume.lastIndexOf('/') + 1),
          );
        }
      }
    };

    const fetchCategories = async () => {
      setFetchLoading(true);
      const categories: Array<Category> = await fetchCategoriesService();

      if (categories && categories.length) {
        setCategories(categories);
        await fetchUserDetails();
      }

      setFetchLoading(false);
    };

    fetchCategories();
  }, [id]);

  const renderTooltipCost = (props: any) => (
    <Tooltip
      className={styles.tooltipContainer}
      id="button-tooltipCost"
      {...props}
    >
      Categories are how we sort {COPY_OVERRIDES?.profile?.careers || 'Careers'}{' '}
      and {COPY_OVERRIDES?.profile?.opportunities || 'Opportunities'}, and also
      how users display their skills on their profiles. Select the Categories
      you want to showcase for hiring/getting hired.
    </Tooltip>
  );
  return (
    <Fragment>
      <div className={styles.dashboardSection}>
        {fetchLoading ? <Loader /> : null}
        {categories && (
          <Row>
            <Col xl={9} lg={9} md={12} sm={12}>
              <Row>
                <Col>
                  <div className={styles.sectionHeading}>Edit Profile</div>
                  <div className={styles.profileInfo}>
                    Goals & Interests
                    <span>
                      How do you intend to use the site? This will help us
                      provide you with the best experience. This information can
                      be edited/updated at any time.
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      Goals (Check all that apply)
                      <span className={styles.requiredField}> *</span>
                    </div>
                    <Multiselect
                      options={GOALS || []}
                      isObject={true}
                      displayValue="label"
                      closeOnSelect={false}
                      showCheckbox
                      selectedValues={personalInfo.goals}
                      onSelect={(list) => handleMultiSelect(list, 'goals')}
                      onRemove={(list) => handleMultiSelect(list, 'goals')}
                    />
                    {validationErrors.goals && (
                      <div className={styles.error}>
                        {validationErrors.goals}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      Other Goals (only if not listed above)
                    </div>
                    <Input
                      name="otherGoals"
                      type="text"
                      value={personalInfo.otherGoals}
                      onChange={handlePersonalInfoChange}
                      className={
                        validationErrors.otherGoals ? styles.errorInput : ''
                      }
                    />
                    {validationErrors.otherGoals && (
                      <div className={styles.error}>
                        {validationErrors.otherGoals}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={12} lg={12} md={6} sm={12}>
                  <div
                    className={`${styles.authInput} ${styles.authInputMultiselect}`}
                  >
                    <div className={styles.authLabel}>
                      {COPY_OVERRIDES?.profile?.career_interests ||
                        'Career categories I am interested in'}
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipCost}
                      >
                        <div className={styles.infoHover}>i</div>
                      </OverlayTrigger>
                      <span className={styles.shortMess}>
                        <a
                          href={
                            COPY_OVERRIDES?.profile?.submit_category_link ||
                            'https://musiccareermap.com/category-submission'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                COPY_OVERRIDES?.profile?.submit_category_text ||
                                'If you don&apos;t see your category listed, click here to submit it.',
                            }}
                          ></span>
                        </a>
                      </span>
                    </div>
                    <Multiselect
                      options={categories || []}
                      isObject={true}
                      displayValue="name"
                      closeOnSelect={false}
                      showCheckbox
                      selectedValues={personalInfo.careerCategories}
                      onSelect={(categories) =>
                        handleCategorySelect(categories, 'careerCategories')
                      }
                      onRemove={(categories) =>
                        handleCategorySelect(categories, 'careerCategories')
                      }
                    />
                  </div>
                </Col>
                <Col xl={12} lg={12} md={6} sm={12}>
                  <div
                    className={`${styles.authInput} ${styles.authInputMultiselect}`}
                  >
                    <div className={styles.authLabel}>
                      {COPY_OVERRIDES?.profile?.opportunity_interests ||
                        'Opportunities categories I am interested in'}
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipCost}
                      >
                        <div className={styles.infoHover}>i</div>
                      </OverlayTrigger>
                      <span className={styles.shortMess}>
                        <a
                          href={
                            COPY_OVERRIDES?.profile?.submit_category_link ||
                            'https://musiccareermap.com/category-submission'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                COPY_OVERRIDES?.profile?.submit_category_text ||
                                'If you don&apos;t see your category listed, click here to submit it.',
                            }}
                          ></span>
                        </a>
                      </span>
                    </div>
                    <Multiselect
                      options={categories || []}
                      isObject={true}
                      displayValue="name"
                      closeOnSelect={false}
                      showCheckbox
                      selectedValues={personalInfo.opportunityCategories}
                      onSelect={(categories) =>
                        handleCategorySelect(
                          categories,
                          'opportunityCategories',
                        )
                      }
                      onRemove={(categories) =>
                        handleCategorySelect(
                          categories,
                          'opportunityCategories',
                        )
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.profileInfo}>
                    Personal Information
                    <span>Basic Information</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>First Name</div>
                    <Input
                      name="firstName"
                      type="text"
                      value={personalInfo.firstName}
                      onChange={handlePersonalInfoChange}
                      className={
                        validationErrors.firstName ? styles.errorInput : ''
                      }
                    />
                    {validationErrors.firstName && (
                      <div className={styles.error}>
                        {validationErrors.firstName}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Last Name</div>
                    <Input
                      name="lastName"
                      type="text"
                      className={
                        validationErrors.lastName ? styles.errorInput : ''
                      }
                      value={personalInfo.lastName}
                      onChange={handlePersonalInfoChange}
                    />
                    {validationErrors.lastName && (
                      <div className={styles.error}>
                        {validationErrors.lastName}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      Preferred Professional Name/Title
                      <span className={styles.requiredField}> *</span>
                      <div>
                        (What would you like to appear on your profile?)
                      </div>
                    </div>
                    <Input
                      name="preferredTitle"
                      type="text"
                      value={personalInfo.preferredTitle}
                      onChange={handlePersonalInfoChange}
                      placeholder='Example: "Dr. Jane Smith" or "John Doe"'
                      className={
                        validationErrors.preferredTitle ? styles.errorInput : ''
                      }
                    />
                    {validationErrors.preferredTitle && (
                      <div className={styles.error}>
                        {validationErrors.preferredTitle}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      Professional Classification
                      <span className={styles.requiredField}> *</span>
                      <div>
                        (If you fall outside these categories (Attorney, etc.),
                        please select &quot;Non-Medical Member&quot; and specify
                        in the field below.)
                      </div>
                    </div>
                    <Select
                      id="professionalClassification"
                      selectItems={QUALIFICATIONS}
                      onChange={handlePersonalInfoChange}
                      name="professionalClassification"
                      value={personalInfo.professionalClassification}
                    />
                    {validationErrors.professionalClassification && (
                      <div className={styles.error}>
                        {validationErrors.professionalClassification}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      Professional Classification Other (Only if not listed
                      above)
                    </div>
                    <Input
                      name="professionalClassificationOther"
                      type="text"
                      value={personalInfo.professionalClassificationOther}
                      onChange={handlePersonalInfoChange}
                      className={
                        validationErrors.professionalClassificationOther
                          ? styles.errorInput
                          : ''
                      }
                    />
                    {validationErrors.professionalClassificationOther && (
                      <div className={styles.error}>
                        {validationErrors.professionalClassificationOther}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      City<span className={styles.requiredField}> *</span>
                    </div>
                    <Input
                      name="city"
                      type="text"
                      className={validationErrors.city ? styles.errorInput : ''}
                      value={location.city}
                      onChange={handleLocationChange}
                    />
                    {validationErrors.city && (
                      <div className={styles.error}>
                        {validationErrors.city}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      State<span className={styles.requiredField}> *</span>
                    </div>
                    <Select
                      id="state"
                      selectItems={STATES}
                      onChange={handleLocationChange}
                      name="state"
                      value={location.state}
                    />
                    {validationErrors.state && (
                      <div className={styles.error}>
                        {validationErrors.state}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      Zip<span className={styles.requiredField}> *</span>
                    </div>
                    <Input
                      name="zip"
                      type="text"
                      className={validationErrors.zip ? styles.errorInput : ''}
                      value={location.zip}
                      onChange={handleLocationChange}
                    />
                    {validationErrors.zip && (
                      <div className={styles.error}>{validationErrors.zip}</div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      Bio<span className={styles.requiredField}> *</span>
                    </div>
                    <div>
                      <b>
                        Tell our community about yourself (10k character limit).
                      </b>
                    </div>
                    <TextArea
                      name="bio"
                      value={personalInfo.bio}
                      className={validationErrors.bio ? styles.errorInput : ''}
                      onChange={handlePersonalInfoChange}
                    />
                    {validationErrors.bio && (
                      <div className={styles.error}>{validationErrors.bio}</div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Phone Type</div>
                    <Select
                      id="phoneType"
                      selectItems={PHONE_TYPES}
                      onChange={handlePersonalInfoChange}
                      name="phoneType"
                      value={personalInfo.phoneType}
                    />
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Phone Number</div>
                    <Input
                      name="phone"
                      type="text"
                      value={personalInfo.phone}
                      onChange={handlePersonalInfoChange}
                      placeholder="(___) ___ - ____"
                    />
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Alternate Email</div>
                    <Input
                      name="alternateEmail"
                      type="email"
                      value={personalInfo.alternateEmail}
                      onChange={handlePersonalInfoChange}
                    />
                  </div>
                </Col>
              </Row>
              {!!INDUSTRIES?.length && (
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.authInput}>
                      <div className={styles.authLabel}>Industry</div>
                      <Select
                        id="industry"
                        selectItems={INDUSTRIES}
                        onChange={handlePersonalInfoChange}
                        name="industry"
                        value={personalInfo.industry}
                      />
                      {validationErrors.industry &&
                        variantDetails?.site_short_title === 'CCE' && (
                          <div className={styles.error}>
                            {validationErrors.industry}
                          </div>
                        )}
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Upload Your Photo</div>
                    <FileUpload
                      fileType={FILE_TYPE.IMAGE}
                      fileSpecs={['5mb max', '500px wide', '500px tall']}
                      onFileChange={handlePhotoChange}
                      alreadyUploadedFileURL={current_photo}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.profileInfo}>
                    Professional Information
                    <span>Tell us about your career.</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      Residency/Specialty
                      <div>
                        (Please choose one if applicable, and{' '}
                        <a
                          href="http://beyondphysician.org/residency-category-submission"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          click here
                        </a>{' '}
                        if your preference is not listed.)
                      </div>
                    </div>
                    <Select
                      id="speciality"
                      selectItems={SPECIALITIES}
                      onChange={handlePersonalInfoChange}
                      name="speciality"
                      value={personalInfo.speciality}
                    />
                    {validationErrors.speciality && (
                      <div className={styles.error}>
                        {validationErrors.speciality}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      Residency/Specialty Hospital/Clinic Name
                    </div>
                    <Input
                      name="specialityHospital"
                      type="text"
                      value={personalInfo.specialityHospital}
                      onChange={handlePersonalInfoChange}
                      className={
                        validationErrors.specialityHospital
                          ? styles.errorInput
                          : ''
                      }
                    />
                    {validationErrors.specialityHospital && (
                      <div className={styles.error}>
                        {validationErrors.specialityHospital}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.authLabel}>
                    Residency/Specialty Location
                  </div>
                </Col>
                <Col xl={8} lg={8} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>City</div>
                    <Input
                      name="specialityCity"
                      type="text"
                      value={personalInfo.specialityCity}
                      onChange={handlePersonalInfoChange}
                      className={
                        validationErrors.specialityCity ? styles.errorInput : ''
                      }
                    />
                    {validationErrors.specialityCity && (
                      <div className={styles.error}>
                        {validationErrors.specialityCity}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={4} lg={4} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>State</div>
                    <Select
                      id="specialityState"
                      selectItems={STATES}
                      onChange={handlePersonalInfoChange}
                      name="specialityState"
                      value={personalInfo.specialityState}
                    />
                    {validationErrors.specialityState && (
                      <div className={styles.error}>
                        {validationErrors.specialityState}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      Fellowship Category{' '}
                      <div>
                        (Please choose one, if applicable.{' '}
                        <a
                          href={
                            COPY_OVERRIDES?.profile?.submit_fellowship_link ||
                            'https://musiccareermap.com/category-submission'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                COPY_OVERRIDES?.profile
                                  ?.submit_fellowship_text || 'Click here',
                            }}
                          ></span>
                        </a>{' '}
                        if your option is not listed.)
                      </div>
                    </div>
                    <Select
                      id="fellowship"
                      selectItems={FELLOWSHIPS}
                      onChange={handlePersonalInfoChange}
                      name="fellowship"
                      value={personalInfo.fellowship}
                    />
                    {validationErrors.fellowship && (
                      <div className={styles.error}>
                        {validationErrors.fellowship}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      Fellowship Subspecialty{' '}
                      <div>
                        (After selecting your Category above, please choose one
                        subcategory.{' '}
                        <a
                          href={
                            COPY_OVERRIDES?.profile
                              ?.submit_fellowship_sub_link ||
                            'https://musiccareermap.com/category-submission'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                COPY_OVERRIDES?.profile
                                  ?.submit_fellowship_text || 'Click here',
                            }}
                          ></span>
                        </a>{' '}
                        if your option is not listed.)
                      </div>
                    </div>
                    <Select
                      id="fellowshipSub"
                      selectItems={
                        FELLOWSHIP_SUBSPECIALITIES[personalInfo.fellowship] ||
                        []
                      }
                      onChange={handlePersonalInfoChange}
                      name="fellowshipSub"
                      value={personalInfo.fellowshipSub}
                    />
                    {validationErrors.fellowshipSub && (
                      <div className={styles.error}>
                        {validationErrors.fellowshipSub}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      Fellowship Subspecialty Hospital/Clinic Name
                    </div>
                    <Input
                      name="fellowshipHospital"
                      type="text"
                      value={personalInfo.fellowshipHospital}
                      onChange={handlePersonalInfoChange}
                      className={
                        validationErrors.fellowshipHospital
                          ? styles.errorInput
                          : ''
                      }
                    />
                    {validationErrors.fellowshipHospital && (
                      <div className={styles.error}>
                        {validationErrors.fellowshipHospital}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.authLabel}>
                    Fellowship Subspecialty Location
                  </div>
                </Col>
                <Col xl={8} lg={8} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>City</div>
                    <Input
                      name="fellowshipSubCity"
                      type="text"
                      value={personalInfo.fellowshipSubCity}
                      onChange={handlePersonalInfoChange}
                      className={
                        validationErrors.fellowshipSubCity
                          ? styles.errorInput
                          : ''
                      }
                    />
                    {validationErrors.fellowshipSubCity && (
                      <div className={styles.error}>
                        {validationErrors.fellowshipSubCity}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={4} lg={4} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>State</div>
                    <Select
                      id="fellowshipSubState"
                      selectItems={STATES}
                      onChange={handlePersonalInfoChange}
                      name="fellowshipSubState"
                      value={personalInfo.fellowshipSubState}
                    />
                    {validationErrors.fellowshipSubState && (
                      <div className={styles.error}>
                        {validationErrors.fellowshipSubState}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={12} lg={12} md={6} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Licensed States</div>
                    <Multiselect
                      options={STATES || []}
                      isObject={true}
                      displayValue="label"
                      closeOnSelect={false}
                      showCheckbox
                      selectedValues={personalInfo.licensedStates}
                      onSelect={(list) =>
                        handleMultiSelect(list, 'licensedStates')
                      }
                      onRemove={(list) =>
                        handleMultiSelect(list, 'licensedStates')
                      }
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className={styles.profileInfo}>
                    Employment History
                    <span>
                      To help connect you with the best jobs and services
                      available. Include any information outside of these fields
                      in your About section. Start with your most recent/current
                      employer, which will appear at the top of your list.
                    </span>
                  </div>
                </Col>
              </Row>

              {companies.map((item, i) => {
                return (
                  <Row key={i}>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <div className={styles.subHeading}>Company #{i + 1}</div>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <div className={styles.authInput}>
                        <div className={styles.authLabel}>Name</div>
                        <Input
                          name="name"
                          type="text"
                          value={companies[i].name}
                          onChange={(e) => handleCompanyChange(e, i)}
                        />
                      </div>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <div className={styles.authInput}>
                        <div className={styles.authLabel}>Title</div>
                        <Input
                          name="title"
                          type="text"
                          value={companies[i].title}
                          onChange={(e) => handleCompanyChange(e, i)}
                        />
                      </div>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={12}>
                      <div className={styles.authInput}>
                        <div className={styles.authLabel}>Date Range</div>
                        <Input
                          name="date_range"
                          type="text"
                          value={companies[i].date_range}
                          onChange={(e) => handleCompanyChange(e, i)}
                        />
                      </div>
                    </Col>
                  </Row>
                );
              })}

              <Row>
                <Col>
                  <div className={styles.profileInfo}>
                    Education
                    <span>
                      To help connect you with the best jobs and services
                      available. Include any information outside of these fields
                      in your About section. Start with your most recent/current
                      education, which will appear at the top of your list.
                    </span>
                  </div>
                </Col>
              </Row>
              {schools?.map((item, i) => {
                return (
                  <Row key={i}>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <div className={styles.subHeading}>School #{i + 1}</div>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <div className={styles.authInput}>
                        <div className={styles.authLabel}>
                          Level{' '}
                          <span style={{ fontWeight: 'normal' }}>
                            (Degree, etc)
                          </span>
                        </div>
                        <Select
                          id="level"
                          name="level"
                          selectItems={EDUCATIONS}
                          onChange={(e) => handleSchoolChange(e, i)}
                          value={schools[i].level}
                        />
                      </div>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <div className={styles.authInput}>
                        <div className={styles.authLabel}>Institution Name</div>
                        <Input
                          name="name"
                          type="text"
                          value={schools[i].name}
                          onChange={(e) => handleSchoolChange(e, i)}
                        />
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <div className={styles.authInput}>
                        <div className={styles.authLabel}>
                          Notes{' '}
                          <span style={{ fontWeight: 'normal' }}>
                            (Field of Study, Honors, etc)
                          </span>
                        </div>
                        <Input
                          name="notes"
                          type="text"
                          value={schools[i].notes}
                          onChange={(e) => handleSchoolChange(e, i)}
                        />
                      </div>
                    </Col>
                  </Row>
                );
              })}

              <Row>
                <Col>
                  <div className={styles.profileInfo}>
                    Add Additional Links
                    <span>
                      Personal or Business. Use this section to include any
                      awards, publications, research, trials.
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Website</div>
                    <Input
                      name="website"
                      type="text"
                      placeholder="http://"
                      className={
                        validationErrors.website ? styles.errorInput : ''
                      }
                      value={websites.website}
                      onChange={handleWebsiteChange}
                    />
                    {validationErrors.website && (
                      <div className={styles.error}>
                        {validationErrors.website}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Facebook</div>
                    <Input
                      name="facebook"
                      type="text"
                      placeholder="http://"
                      className={
                        validationErrors.facebook ? styles.errorInput : ''
                      }
                      value={websites.facebook}
                      onChange={handleWebsiteChange}
                    />
                    {validationErrors.facebook && (
                      <div className={styles.error}>
                        {validationErrors.facebook}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Twitter</div>
                    <Input
                      name="twitter"
                      type="text"
                      placeholder="http://"
                      className={
                        validationErrors.twitter ? styles.errorInput : ''
                      }
                      value={websites.twitter}
                      onChange={handleWebsiteChange}
                    />
                    {validationErrors.twitter && (
                      <div className={styles.error}>
                        {validationErrors.twitter}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Youtube</div>
                    <Input
                      name="youtube"
                      type="text"
                      placeholder="http://"
                      className={
                        validationErrors.youtube ? styles.errorInput : ''
                      }
                      value={websites.youtube}
                      onChange={handleWebsiteChange}
                    />
                    {validationErrors.youtube && (
                      <div className={styles.error}>
                        {validationErrors.youtube}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Instagram</div>
                    <Input
                      name="instagram"
                      type="text"
                      placeholder="http://"
                      className={
                        validationErrors.instagram ? styles.errorInput : ''
                      }
                      value={websites.instagram}
                      onChange={handleWebsiteChange}
                    />
                    {validationErrors.instagram && (
                      <div className={styles.error}>
                        {validationErrors.instagram}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Additional Links</div>
                    <Row className="mb-4">
                      <Col xl={4} lg={4} md={12} sm={12}>
                        <Input
                          name="additionalLink1Title"
                          type="text"
                          placeholder="Title"
                          className={
                            validationErrors.additionalLink1Title
                              ? styles.errorInput
                              : ''
                          }
                          value={additionalLinks.additionalLink1.title}
                          onChange={handleAdditionalLinksChange}
                        />
                        {validationErrors.additionalLink1Title && (
                          <div className={styles.error}>
                            {validationErrors.additionalLink1Title}
                          </div>
                        )}
                      </Col>
                      <Col xl={8} lg={8} md={12} sm={12}>
                        <Input
                          name="additionalLink1"
                          type="text"
                          placeholder="http://"
                          className={
                            validationErrors.additionalLink1
                              ? styles.errorInput
                              : ''
                          }
                          value={additionalLinks.additionalLink1.link}
                          onChange={handleAdditionalLinksChange}
                        />
                        {validationErrors.additionalLink1 && (
                          <div className={styles.error}>
                            {validationErrors.additionalLink1}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col xl={4} lg={4} md={12} sm={12}>
                        <Input
                          name="additionalLink2Title"
                          type="text"
                          placeholder="Title"
                          className={
                            validationErrors.additionalLink2Title
                              ? styles.errorInput
                              : ''
                          }
                          value={additionalLinks.additionalLink2.title}
                          onChange={handleAdditionalLinksChange}
                        />
                        {validationErrors.additionalLink2Title && (
                          <div className={styles.error}>
                            {validationErrors.additionalLink2Title}
                          </div>
                        )}
                      </Col>
                      <Col xl={8} lg={8} md={12} sm={12}>
                        <Input
                          name="additionalLink2"
                          type="text"
                          placeholder="http://"
                          className={
                            validationErrors.additionalLink2
                              ? styles.errorInput
                              : ''
                          }
                          value={additionalLinks.additionalLink2.link}
                          onChange={handleAdditionalLinksChange}
                        />
                        {validationErrors.additionalLink2 && (
                          <div className={styles.error}>
                            {validationErrors.additionalLink2}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col xl={4} lg={4} md={12} sm={12}>
                        <Input
                          name="additionalLink3Title"
                          type="text"
                          placeholder="Title"
                          className={
                            validationErrors.additionalLink3Title
                              ? styles.errorInput
                              : ''
                          }
                          value={additionalLinks.additionalLink3.title}
                          onChange={handleAdditionalLinksChange}
                        />
                        {validationErrors.additionalLink3Title && (
                          <div className={styles.error}>
                            {validationErrors.additionalLink3Title}
                          </div>
                        )}
                      </Col>
                      <Col xl={8} lg={8} md={12} sm={12}>
                        <Input
                          name="additionalLink3"
                          type="text"
                          placeholder="http://"
                          className={
                            validationErrors.additionalLink3
                              ? styles.errorInput
                              : ''
                          }
                          value={additionalLinks.additionalLink3.link}
                          onChange={handleAdditionalLinksChange}
                        />
                        {validationErrors.additionalLink3 && (
                          <div className={styles.error}>
                            {validationErrors.additionalLink3}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col xl={4} lg={4} md={12} sm={12}>
                        <Input
                          name="additionalLink4Title"
                          type="text"
                          placeholder="Title"
                          className={
                            validationErrors.additionalLink4Title
                              ? styles.errorInput
                              : ''
                          }
                          value={additionalLinks.additionalLink4.title}
                          onChange={handleAdditionalLinksChange}
                        />
                        {validationErrors.additionalLink4Title && (
                          <div className={styles.error}>
                            {validationErrors.additionalLink4Title}
                          </div>
                        )}
                      </Col>
                      <Col xl={8} lg={8} md={12} sm={12}>
                        <Input
                          name="additionalLink4"
                          type="text"
                          placeholder="http://"
                          className={
                            validationErrors.additionalLink4
                              ? styles.errorInput
                              : ''
                          }
                          value={additionalLinks.additionalLink4.link}
                          onChange={handleAdditionalLinksChange}
                        />
                        {validationErrors.additionalLink4 && (
                          <div className={styles.error}>
                            {validationErrors.additionalLink4}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={4} lg={4} md={12} sm={12}>
                        <Input
                          name="additionalLink5Title"
                          type="text"
                          placeholder="Title"
                          className={
                            validationErrors.additionalLink5Title
                              ? styles.errorInput
                              : ''
                          }
                          value={additionalLinks.additionalLink5.title}
                          onChange={handleAdditionalLinksChange}
                        />
                        {validationErrors.additionalLink5Title && (
                          <div className={styles.error}>
                            {validationErrors.additionalLink5Title}
                          </div>
                        )}
                      </Col>
                      <Col xl={8} lg={8} md={12} sm={12}>
                        <Input
                          name="additionalLink5"
                          type="text"
                          placeholder="http://"
                          className={
                            validationErrors.additionalLink5
                              ? styles.errorInput
                              : ''
                          }
                          value={additionalLinks.additionalLink5.link}
                          onChange={handleAdditionalLinksChange}
                        />
                        {validationErrors.additionalLink5 && (
                          <div className={styles.error}>
                            {validationErrors.additionalLink5}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <div className={styles.profileInfo}>
                    Credentials
                    <span>
                      Be careful about sharing information like personal emails
                      of phone numbers. Onsite messaging is available to all
                      users. Adding credentials is a quick way to enhance and
                      distinguish your skills and experience.
                    </span>
                  </div>
                </Col>
              </Row> */}
              {/* <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Upload Your Resume</div>
                    <FileUpload
                      fileType={FILE_TYPE.DOCUMENT}
                      fileSpecs={['pdf and doc files only', '5mbs max']}
                      onFileChange={handleResumeChange}
                      currentUploadedFileName={currentResume}
                    />
                  </div>
                </Col>
              </Row> */}
              <Row>
                <Col>
                  <div className={styles.authButton}>
                    <Button
                      label={loading ? 'loading...' : 'Save All Information'}
                      type="large w-100"
                      disabled={isSubmitDisabled()}
                      onClick={handleSubmit}
                    />
                  </div>
                  {apiError && <Alert variant="danger">{apiError}</Alert>}
                  {newToken ? (
                    <Redirect to={`${ROUTES.PROFILE}/${slug}`} />
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    </Fragment>
  );
};

export default EditProfile;
