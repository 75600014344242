import React, { useState, Fragment } from 'react';
import moment from 'moment';
// import downloadjs from 'downloadjs';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import Badge from 'assets/svgs/certificateBadge.svg';

import { downloadCredential } from 'services/UserService';

// Styles
import styles from './Credentials.module.scss';

interface Props {
  credentials: Array<any>;
  onDelete?: (id: string) => void;
  isLoading?: boolean;
}

const Credentials: React.FC<Props> = ({
  credentials,
  onDelete,
  isLoading,
}: Props) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isCopying, setIsCopying] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);

  const downloadCred = async (id) => {
    if (isDownloading) {
      return;
    }
    setIsDownloading(true);
    const { error, data } = await downloadCredential(id);
    if (!error) {
      window.open(data.url, '__blank');
    }
    setIsDownloading(false);
  };

  const copyLink = async (id) => {
    if (isCopying) {
      return;
    }
    setIsCopying(true);
    const { error, data } = await downloadCredential(id);
    if (!error) {
      window.navigator?.clipboard?.writeText(data.url);
      setCopied(true);
    }
    setIsCopying(false);
  };

  return (
    <>
      {!!credentials?.length && (
        <div className={styles.resumeListing}>
          {credentials?.map((item, index) => (
            <Fragment key={index}>
              <div key={index} className={styles.listing}>
                <div className={styles.badge}>
                  <SVG
                    description="Credential Badge"
                    src={Badge}
                    title="Badge"
                  />
                </div>
                <div className={styles.resumeListRight}>
                  <div className={styles.resumeHeading}>
                    {item.credential_title}
                  </div>
                  <div className={styles.resumeInstitute}>
                    {item.verifying_party_company}
                  </div>
                  <div className={styles.resumeDate}>
                    Issued {moment(item.awarded_at).format('MMMM YYYY')}
                  </div>
                  <div className={styles.resumeData}>
                    <Link to="#" onClick={() => downloadCred(item.id)}>
                      {!isDownloading ? 'See Certificate' : 'Downloading...'}
                    </Link>
                    <Link
                      className={styles.copyCert}
                      to="#"
                      onClick={() => copyLink(item.id)}
                    >
                      {copied
                        ? 'Copied'
                        : !isCopying
                          ? 'Copy Link'
                          : 'Copying...'}
                    </Link>
                    {!!onDelete && (
                      <Link
                        to="#"
                        className={styles.deleteCert}
                        onClick={() => onDelete(item.id)}
                      >
                        {!isLoading ? 'Delete Certificate' : 'Deleting...'}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      )}
    </>
  );
};

export default Credentials;
