import React, { useRef, useState, Fragment, useEffect } from 'react';

// Components
import { Container, Col, Row } from 'react-bootstrap';
import Button from 'components/Button/Button';

// Constants
import { FILE_TYPE } from 'constants/Constants';

// Styles
import styles from './FileUpload.module.scss';

interface Props {
  fileType?: string;
  fileSpecs?: Array<string>;
  onFileChange: (file: any) => void;
  buttonStyles?: string;
  reset?: boolean;
  image?: string;
  alreadyUploadedFileURL?: string;
  currentUploadedFileName?: string;
  multiple?: boolean;
  label?: string;
}

const FileUpload: React.FC<Props> = ({
  fileType,
  fileSpecs,
  onFileChange,
  buttonStyles,
  reset,
  image,
  alreadyUploadedFileURL,
  currentUploadedFileName,
  multiple,
  label,
}: Props) => {
  const inputFileRef = useRef<any>(null);

  const [file, setFile] = useState<any>(null);

  const [preview, setPreview] = useState<string | null>(image || null);

  useEffect(() => {
    if (reset && file) {
      setFile(null);
      setPreview(null);
    }
  }, [reset, file]);

  const handleOnClick = () => {
    if (inputFileRef) {
      inputFileRef.current.click();
    }
  };

  const removeFile = () => {
    setFile(null);
    onFileChange(null);
  };

  const handleFileChange = (e: any) => {
    const { files } = e.target;

    if (files.length) {
      if (fileType === FILE_TYPE.IMAGE && !multiple) {
        // eslint-disable-next-line no-undef
        const reader = new FileReader();
        reader.onload = (evt: any) => {
          setPreview(evt.target.result);
        };
        reader.readAsDataURL(files[0]);
      }

      setFile(multiple ? files : files[0]);
      onFileChange(multiple ? [...files] : files[0]);
    }
  };

  const imageURL = () => {
    if (preview) {
      return preview;
    }

    if (!preview && alreadyUploadedFileURL) {
      return alreadyUploadedFileURL;
    }
  };

  return (
    <Container className={`px-0 ${styles.fileUpload}`}>
      <Row>
        <Col xs={12} sm={6}>
          <Button
            type={`${buttonStyles} button`}
            label={label || 'Upload'}
            size="lg"
            onClick={handleOnClick}
          />
          <input
            id={styles.imageUpload}
            type="file"
            accept={
              fileType === FILE_TYPE.IMAGE
                ? 'image/*'
                : '.pdf,.doc,.docx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            }
            ref={inputFileRef}
            onChange={handleFileChange}
            multiple={multiple || false}
          />
          {fileSpecs && (
            <ul className={styles.fileSpecs}>
              {fileSpecs.map((fs) => (
                <li key={fs} className={styles.spec}>
                  {fs}
                </li>
              ))}
            </ul>
          )}
        </Col>

        {fileType && !multiple ? (
          <Col xs={12} sm={6}>
            {fileType === FILE_TYPE.IMAGE ? (
              <div className={styles.imageType}>
                {imageURL() && <img src={imageURL()} alt={preview} />}
                {/* {preview && <img src={preview} alt={preview} />} */}
              </div>
            ) : (
              <div></div>
            )}

            {fileType === FILE_TYPE.DOCUMENT && file && (
              <Fragment>
                <div className={styles.fileSelectedName}>{file.name}</div>
                <div className={styles.removeFile} onClick={removeFile}>
                  Remove
                </div>
              </Fragment>
            )}

            {fileType === FILE_TYPE.DOCUMENT &&
              currentUploadedFileName &&
              !file && (
                <Fragment>
                  <div className={styles.fileSelectedName}>
                    {currentUploadedFileName}
                  </div>
                  <div className={styles.removeFile} onClick={removeFile}>
                    Remove
                  </div>
                </Fragment>
              )}
          </Col>
        ) : (
          ''
        )}
      </Row>
    </Container>
  );
};

export default React.memo(FileUpload);
