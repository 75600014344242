import React, { Fragment, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share';

import moment from 'moment';

// import SVG from 'react-inlinesvg';

import { Container, Row, Col } from 'react-bootstrap';

import Credentials from 'components/Credentials/Credentials';
import Button from 'components/Button/Button';
import InviteModal from 'pages/TalentSearch/components/InviteModal/InviteModal';

// SVGs
// import PDF from 'assets/svgs/pdf.svg';
// import Certificate from 'assets/svgs/mixing.svg';

// Context
import GlobalContext from 'contexts/Global.context';

// Interfaces
import { ApplicantProfile, MyJob } from 'interfaces/User.types';
import { Job } from 'interfaces/Job.types';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faInstagram,
  faFacebook,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  // faArrowDown,
  faArrowCircleUp,
} from '@fortawesome/free-solid-svg-icons';

import History from 'utils/History';

// Constants
import { ROUTES } from 'constants/Constants';

// Styles
import styles from './Content.module.scss';

interface Props {
  profile: ApplicantProfile;
  jobs: MyJob[];
  services: Job[];
}

const Content: React.FC<Props> = ({ profile, jobs, services }: Props) => {
  const {
    isLoggedIn,
    userDetails: { id: loggedInUserId },
  } = useContext(GlobalContext);

  const [showInvite, setShowInvite] = useState<boolean>(false);

  const showInvitePopup = () => {
    if (!isLoggedIn) {
      History.push('/login?destination=' + window.location.pathname);
    }
    setShowInvite(true);
  };

  const renderSkills = (key: string) => {
    // eslint-disable-next-line react/prop-types
    return (
      <div className={styles.skills}>
        {profile[key]
          ? // eslint-disable-next-line react/prop-types
            profile[key]
              .split(/(,(?=\S)|:)/)
              .filter((skill) => skill !== ',')
              .map((skill) => (
                <div key={skill} className={styles.skill}>
                  {skill.trim()}
                </div>
              ))
          : null}
      </div>
    );
  };

  const getClickableLink = (link) => {
    return link.startsWith('http://') || link.startsWith('https://')
      ? link
      : `//${link}`;
  };

  const renderAdditionalLinks = () => {
    // eslint-disable-next-line react/prop-types
    const { additional_links: additionalLinks } = profile;
    if (additionalLinks && additionalLinks.length) {
      return (
        <div className={styles.box}>
          <div className={styles.label}>Additional Links</div>
          <div className={styles.boxContent}>
            <ul>
              {additionalLinks.map((additionalLink) => {
                if (additionalLink.title && additionalLink.link) {
                  return (
                    <li key={`${additionalLink.title}-${additionalLink.link}`}>
                      <a
                        href={getClickableLink(additionalLink.link)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {additionalLink.title}
                      </a>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <Fragment>
      <Container className={styles.profileContentContainer}>
        <Row className="justify-content-md-center">
          <Col className={styles.p0}>
            <div className={styles.profileContentWrapper}>
              <div className={styles.leftContent}>
                <div className={styles.skillsBox}>
                  <div className={styles.label}>Seeking</div>
                  <div className={styles.boxContent}>
                    {renderSkills('career_categories')}
                  </div>
                </div>
                <div className={styles.skillsBox}>
                  <div className={styles.label}>Offering</div>
                  <div className={styles.boxContent}>
                    {renderSkills('opportunity_categories')}
                  </div>
                </div>
                <div className={styles.box}>
                  <div className={styles.label}>Joined</div>
                  <div className={styles.boxContent}>
                    <div className={styles.duration}>
                      {moment(profile.created_at).fromNow()}
                    </div>
                  </div>
                </div>
                {profile.id !== loggedInUserId && (
                  <div className={styles.box}>
                    <div className={styles.label}>Contact</div>
                    <div className={styles.boxContent}>
                      <Link
                        to={{
                          pathname: `${ROUTES.DASHBOARD}${ROUTES.MY_MESSAGES}/${profile.id}`,
                          state: { userName: profile.user_name },
                        }}
                      >
                        Message Me
                      </Link>
                    </div>
                  </div>
                )}
                <div className={styles.box}>
                  <div className={styles.label}>Profile Link</div>
                  <div className={styles.boxContent}>
                    <Link
                      to={`${ROUTES.PROFILE}/${profile.slug}`}
                      target="_blank"
                    >
                      {window.location.href}
                    </Link>
                  </div>
                </div>
                <div className={styles.box}>
                  <div className={styles.label}>Websites</div>
                  <div className={styles.boxContent}>
                    <ul>
                      {profile.twitter && (
                        <li>
                          <a
                            href={getClickableLink(profile.twitter)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faTwitter}
                                className={styles.socialIcon}
                              />
                            </span>
                            Twitter
                          </a>
                        </li>
                      )}
                      {profile.facebook && (
                        <li>
                          <a
                            href={getClickableLink(profile.facebook)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faFacebook}
                                className={styles.socialIcon}
                              />
                            </span>
                            Facebook
                          </a>
                        </li>
                      )}
                      {profile.instagram && (
                        <li>
                          <a
                            href={getClickableLink(profile.instagram)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faInstagram}
                                className={styles.socialIcon}
                              />
                            </span>
                            Instagram
                          </a>
                        </li>
                      )}
                      {profile.youtube && (
                        <li>
                          <a
                            href={getClickableLink(profile.youtube)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faYoutube}
                                className={styles.socialIcon}
                              />
                            </span>
                            Youtube
                          </a>
                        </li>
                      )}
                      {profile.website && (
                        <li>
                          <a
                            href={getClickableLink(profile.website)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faArrowCircleUp}
                                className={styles.socialIcon}
                              />
                            </span>
                            Website
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                {renderAdditionalLinks()}
                <div className={styles.box}>
                  <div className={styles.label}>Share</div>
                  <div className={styles.boxContent}>
                    <FacebookShareButton
                      className={styles.shareButton}
                      url={window.location.href}
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={window.location.href}
                      title={profile.first_name}
                      className={styles.shareButton}
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <LinkedinShareButton
                      url={window.location.href}
                      title={profile.first_name}
                      className={styles.shareButton}
                    >
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                  </div>
                </div>
              </div>
              <div className={styles.rightContent}>
                {/* <div className={styles.subHeading}>About</div>
                <div className={styles.description}>{profile.about}</div> */}
                <InviteModal
                  showInvite={showInvite}
                  setShowInvite={setShowInvite}
                  user={profile}
                  jobs={jobs}
                />
                {!!services?.length && (
                  <div className={styles.subHeading}>Services</div>
                )}
                {!!services?.length && (
                  <div className={styles.userServices}>
                    {services?.map((job) => (
                      <div key={job.id} className={styles.serviceCard}>
                        <div className={styles.serviceTitle}>{job.title}</div>
                        <div className={styles.payRange}>
                          {job.min_pay && job.max_pay
                            ? `$${job.min_pay}-$${job.max_pay}`
                            : job.min_pay
                              ? `$${job.min_pay}`
                              : 'TBD'}
                        </div>
                        <Button
                          link={`${ROUTES.OPPORTUNITY}/${job.id}`}
                          target="_blank"
                          label="View"
                        />
                        <Button
                          label="Invite To Job"
                          type="transparent outline-gray add-btn"
                          onClick={() => showInvitePopup()}
                        />
                      </div>
                    ))}
                  </div>
                )}
                <div className={styles.subHeading}>About</div>
                <div className={styles.description}>
                  <pre>{profile.bio}</pre>
                </div>
                <div className={styles.subHeading}>
                  Experience & Information
                </div>
                <div className={styles.innerHeading}>
                  Speciality/Residency Areas
                </div>
                <div className={styles.value}>{profile.speciality}</div>
                <div className={styles.innerHeading}>Residency</div>
                <div className={styles.value}>
                  {profile.speciality_hospital}
                </div>
                <div className={styles.innerHeading}>Fellowship Areas</div>
                <div className={styles.value}>{profile.fellowship}</div>
                <div className={styles.innerHeading}>
                  Fellowship Subspecialty
                </div>
                <div className={styles.value}>{profile.fellowship_sub}</div>
                <div className={styles.innerHeading}>
                  Fellowship Subspecialty Hospital
                </div>
                <div className={styles.value}>
                  {profile.fellowship_hospital}
                </div>
                <div className={styles.innerHeading}>
                  Fellowship Subspecialty Location
                </div>
                <div className={styles.value}>
                  {profile.fellowship_sub_city}, {profile.fellowship_sub_state}
                </div>
                <div className={styles.innerHeading}>States Licensed</div>
                <div className={styles.value}>
                  {profile.licensed_states
                    ?.split('_')
                    .map((state) => <div key={state}>{state}</div>)}
                </div>
                <div className={styles.subHeading}>Employment History</div>
                {profile.companies?.map((company, index) => (
                  <div key={index}>
                    <div className={styles.innerHeading}>{company.title}</div>
                    <div className={styles.value}>
                      {company.name}{' '}
                      {company.date_range ? `(${company.date_range})` : ''}
                    </div>
                  </div>
                ))}
                <div className={styles.subHeading}>Education</div>
                {profile.schools?.map((school, index) => (
                  <div key={index}>
                    <div className={styles.innerHeading}>{school.level}</div>
                    <div className={styles.value}>
                      {school.name}
                      <div className={styles.notes}>{school.notes}</div>
                    </div>
                  </div>
                ))}
                {/* {profile.resume ? (
                  <>
                    <div className={styles.subHeading}>My Resume</div>
                    <div className={styles.downloadResume}>
                      <a
                        href={profile.resume}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        <div className={styles.pdfIcon}>
                          <SVG baseURL="/" description="PDF" src={PDF} />
                        </div>
                        <div className={styles.fileType}>
                          <div className={styles.fileName}>My Resume</div>
                          <div className={styles.fileExt}>
                            {profile.resume.substring(
                              profile.resume.lastIndexOf('/') + 1
                            )}
                          </div>
                        </div>
                        <div className={styles.download}>
                          <FontAwesomeIcon icon={faArrowDown} />
                        </div>
                      </a>
                    </div>
                  </>
                ) : null} */}
                {!!profile.certificates?.length && (
                  <>
                    <div className={styles.subHeading}>
                      Licenses &amp; Certifications
                    </div>
                    <div>
                      <Row>
                        <Col>
                          <Credentials credentials={profile.certificates} />
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Content;
