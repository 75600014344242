import React, { Fragment, useState } from 'react';
import { Row, Col, Modal, Alert } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { Helmet } from 'react-helmet';

import FileUpload from 'components/FileUpload/FileUpload';

// SVGs
import BadgeGrey from '../../../../assets/svgs/badgeGrey.svg';
import closeIcon from 'assets/svgs/close-icon.svg';

// Interfaces
import { ApplicantProfile } from 'interfaces/User.types';

import {
  addCertificateService,
  deleteCertificateService,
} from 'services/UserService';
import { getUploadURLService } from 'services/CommonService';

// Utilities
import { camelToSnake, getFileName } from 'utils/UtilityFunctions';

// Components
import Button from 'components/Button/Button';
import Input from 'components/Form/Input/Input';
import Credentials from 'components/Credentials/Credentials';

import {
  FREE_WORK_CREDENTIALS,
  APP_CONSTANTS,
  FILE_TYPE,
} from 'constants/Constants';

import COPY_OVERRIDES from 'config/CopyOverrides';

// Styles
import styles from './Resume.module.scss';

interface Props {
  profile: ApplicantProfile;
  refetch: () => void;
}

const DEFAULT_CERT_VALUES = {
  verifyingPartyCompany: '',
  credentialTitle: '',
  awardedAt: '',
  s3Url: '',
};

const {
  errorConstants: { SOMETHING_WENT_WRONG: serverError },
} = APP_CONSTANTS;

const Resume: React.FC<Props> = ({ profile, refetch }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>('');
  const [show, setShow] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const [certificate, setCertificate] = useState<any>(DEFAULT_CERT_VALUES);

  const uploadCert = async () => {
    let uploadImageResponse: any = null;
    const fileInfo = {
      name: getFileName(file?.name),
      type: file?.type,
    };
    const { signedRequest, url, error } = await getUploadURLService(fileInfo);

    if (signedRequest && url) {
      uploadImageResponse = await fetch(signedRequest, {
        method: 'PUT',
        body: file,
      });

      if (uploadImageResponse) {
        return url;
      }
    } else if (error) {
      setApiError(`An error occurred while uploading file: ${error}`);
      setIsLoading(false);
      return null;
    }
  };

  const handleModalClose = () => {
    setShow(false);
    setCertificate(DEFAULT_CERT_VALUES);
    setApiError('');
  };

  const createCert = async () => {
    setIsLoading(true);
    setApiError('');
    const data = { ...certificate, s3_url: '' };
    const body = {};
    const uploadRes = await uploadCert();
    if (uploadRes) {
      data.s3_url = uploadRes;
    } else {
      return;
    }
    Object.keys(data).map((key: string) => {
      body[camelToSnake(key)] = data[key];
      return key;
    });
    const { certificate: cert, error } = await addCertificateService(body);
    if (!cert) {
      setApiError(error || serverError);
    } else {
      refetch();
      handleModalClose();
    }
    setIsLoading(false);
  };

  const deleteCertificate = async (id: string) => {
    setIsLoading(true);
    setApiError('');
    const { success, error } = await deleteCertificateService(id);
    if (!success) {
      setApiError(error || serverError);
    } else {
      refetch();
    }
    setIsLoading(false);
  };

  const isDisabled =
    isLoading ||
    !certificate.credentialTitle ||
    !certificate.verifyingPartyCompany ||
    !file ||
    !certificate.awardedAt;

  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setCertificate({ ...certificate, [name]: value });
  };

  return (
    <Fragment>
      <div className={styles.dashboardSection}>
        <Helmet>
          {COPY_OVERRIDES?.titles?.credentials && (
            <title>{COPY_OVERRIDES?.titles?.credentials}</title>
          )}
          {COPY_OVERRIDES?.meta?.credentials && (
            <meta
              name="description"
              content={COPY_OVERRIDES.meta.credentials}
            />
          )}
        </Helmet>
        <Row>
          <Col>
            <Row>
              <Col>
                <div className={styles.freeCred}>
                  Credentials help you verify skills/work experience through a
                  third-party partner. All Credentials are free to earn,
                  displayed on your profile, and can help you unlock career
                  opportunities, educational content, and more. Credentialing is
                  quick and easy; click the &quot;Free Experience Verification
                  Credential&quot; button to get started.
                </div>
                <a
                  href={FREE_WORK_CREDENTIALS}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn-grey-ghost"
                >
                  Request Free Experience Verification Credential
                </a>
                <div className={styles.line}></div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Credentials
                  onDelete={deleteCertificate}
                  credentials={profile?.certificates}
                  isLoading={isLoading}
                />

                {!profile?.certificates?.length && (
                  <div className={styles.noCertificate}>
                    <div className={styles.noCertBadge}>
                      <SVG
                        description="No Certificate"
                        src={BadgeGrey}
                        title="Badge"
                      />
                    </div>
                    <div className={styles.noResumeHeading}>
                      You have no current certifications or licenses added just
                      yet.
                    </div>
                    <div className={styles.noResumeDate}>
                      If you’d like for one to be added please use the button
                      below.
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.authButton}>
                  <Button
                    label="Add Credential To Profile"
                    type="large w-100 branding"
                    onClick={() => setShow(true)}
                  />
                </div>
              </Col>
            </Row>
            <Modal
              show={show}
              onHide={isLoading ? () => {} : handleModalClose}
              className={styles.modalWrappper}
              centered
            >
              <div className={styles.closeBtn} onClick={handleModalClose}>
                Close{' '}
                <span>
                  <SVG
                    baseURL="/"
                    className={styles.closeIcon}
                    description="close"
                    loader={<span>Loading...</span>}
                    src={closeIcon}
                  />
                </span>
              </div>
              {/* <Modal.Header
                className={`${styles.modalContainer} ${styles.modalHeader}`}
                closeButton
              >
                <div className={styles.modalTitle}>
                  Add Credential To Profile
                </div>
              </Modal.Header> */}

              <Modal.Header>
                <Modal.Title>
                  Add Credential To Profile
                  <div className={styles.descriptor}>
                    Note: Credentials awarded through Beyond Physician will
                    automatically be added to your profile.
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className={styles.authFormWrap}>
                  <Row
                    className={`${styles.modalContainer} ${styles.modalInputWrap}`}
                  >
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <div className={styles.authInput}>
                        <div className={styles.authLabel}>Title</div>
                        <Input
                          type="text"
                          onChange={handleChange}
                          value={certificate.credentialTitle}
                          name="credentialTitle"
                        />
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <div className={styles.authInput}>
                        <div className={styles.authLabel}>
                          Verifying Party Company
                        </div>
                        <Input
                          type="text"
                          onChange={handleChange}
                          value={certificate.verifyingPartyCompany}
                          name="verifyingPartyCompany"
                        />
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <div className={styles.authInput}>
                        <div className={styles.authLabel}>Awarded At</div>
                        <Input
                          type="date"
                          onChange={handleChange}
                          value={certificate.awardedAt}
                          name="awardedAt"
                        />
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <div
                        className={`${styles.authInput} ${styles.modalUpload}`}
                      >
                        <div className={styles.authLabel}>PDF</div>
                        <FileUpload
                          fileType={FILE_TYPE.DOCUMENT}
                          fileSpecs={[]}
                          onFileChange={setFile}
                        />
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <div className={styles.authButton}>
                        <Button
                          type="large w-100"
                          size="lg"
                          label={isLoading ? 'loading...' : 'Add Credential'}
                          disabled={isDisabled}
                          onClick={createCert}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                {apiError && (
                  <Alert variant="danger">
                    <div className={styles.error}>{apiError}</div>
                  </Alert>
                )}
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Resume;
