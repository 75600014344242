import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import Button from 'components/Button/Button';

// SCSS
import styles from './PaymentProcessed.module.scss';

// SVGs
import SuccessIcon from 'assets/svgs/success.svg';
import { ROUTES } from 'constants/Constants';

const PaymentProcessed = () => {
  return (
    <div className={styles.successPage}>
      <Container>
        <Row>
          <Col md={12}>
            <div className={styles.payRedirect}>
              <div className={styles.imgWrapper}>
                <SVG baseURL="/" description="Success" src={SuccessIcon} />
              </div>
              <div className={styles.heading}>
                Your payment has been successfully processed.
              </div>
              <div className={styles.desc}>
                Thank you for your payment. Your Gig is now active, and you can
                manage all aspects of the Gig from your Active Gig Hub on your
                Dashboard.
              </div>
              <div className={styles.payButton}>
                <Button
                  link={`${ROUTES.DASHBOARD}${ROUTES.ACTIVE_JOBS}?congrats=true`}
                  label="View Active Gigs"
                  type="lgBtn"
                />
              </div>
              <div className={styles.payInfo}></div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PaymentProcessed;
