import React, { Fragment, useState, useEffect, useCallback } from 'react';

import { Row, Col, Alert } from 'react-bootstrap';

// Components
import Result from '../../../JobBoard/Result/Result';
import Loader from 'components/Loader/Loader';

// Interfaces
import { IJobInvitation } from 'interfaces/Job.types';

// Services
import {
  fetchInvitationsService,
  updateInvitationService,
} from 'services/JobService';

// Styles
import styles from './PendingInvites.module.scss';

const PendingInvites: React.FC = () => {
  // active jobs state
  const [loading, setLoading] = useState<boolean>(false);
  const [invites, setInvites] = useState<Array<IJobInvitation> | null>(null);
  const [error, setError] = useState<string>('');

  const fetchInvites = useCallback(async () => {
    setInvites(null);
    setLoading(true);
    const { invites, error } = await fetchInvitationsService('received');

    if (invites) {
      setInvites(invites);
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  }, []);

  const declineJobInvite = async (inviteId: string) => {
    const { success, error } = await updateInvitationService(inviteId, {
      status: 'declined',
    });

    if (success) {
      fetchInvites();
    } else if (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchInvites();
  }, [fetchInvites]);

  return (
    <Fragment>
      <div className={styles.dashboardSection}>
        <Row>
          <Col>
            <Row>
              <Col>
                <div className={styles.sectionHeading}>Pending Invites</div>
              </Col>
            </Row>
            <Row className={styles.invites}>
              <Col>
                {loading ? <Loader /> : null}
                {invites &&
                  invites.length > 0 &&
                  invites.map((invite) => (
                    <Result
                      key={invite.id}
                      id={invite.job_id}
                      image={invite.company_logo}
                      title={invite.job_title}
                      category={invite.job_category}
                      location={`${invite.job_city}, ${invite.job_state}`}
                      date={invite.job_created_at}
                      city={invite.job_city}
                      state={invite.job_state}
                      jobType={invite.job_type}
                      invitedOn={invite.created_at}
                      inviteId={invite.id}
                      declineJobInvite={declineJobInvite}
                    />
                  ))}
                {!loading && invites && invites.length === 0 ? (
                  <Alert variant="primary">
                    {'No pending invitations found'}
                  </Alert>
                ) : null}
                {!loading && !invites && error ? (
                  <Alert variant="danger">{error}</Alert>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default PendingInvites;
