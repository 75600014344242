import React, { Fragment, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Container, Row, Col } from 'react-bootstrap';
import Button from 'components/Button/Button';

// Context
import GlobalContext from 'contexts/Global.context';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faMapMarkerAlt,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';

// Constants
import { ROUTES, JOB_TYPE } from 'constants/Constants';

// SCSS
import styles from './Result.module.scss';
import Image from 'components/Image/Image';

// Props
interface IResult {
  id?: string;
  image: string | 'https://mic-react.s3.amazonaws.com/place_70x70.png';
  title: string;
  category: string;
  location: string;
  jobType?: string;
  idealApplicant?: string;
  date: string;
  isFeatured?: boolean;
  city: string;
  state: string;
  isArchived?: boolean;
  isPosted?: boolean;
  invitedOn?: string;
  inviteId?: string;
  link?: string;
  /* deleteLoading?: boolean; */
  deleteAJobPosting?: (jobId: string) => void;
  /* relistLoading?: boolean; */
  relistAJobPosting?: (jobId: string) => void;
  declineJobInvite?: (inviteId: string) => void;
}

const Result = (props: IResult) => {
  const [archiveLoading, setArchiveLoading] = useState<boolean>(false);
  const [declineLoading, setDeclineLoading] = useState<boolean>(false);
  const { variantDetails } = useContext(GlobalContext);

  const DEFAULT_THUMBNAIL = `${variantDetails?.assets_url}/header_logo_thumb.svg`;

  const archiveAJobListing = async (jobId: string) => {
    setArchiveLoading(true);
    await props.deleteAJobPosting(jobId);
    setArchiveLoading(false);
  };

  const declineJobInvite = async (inviteId: string) => {
    setDeclineLoading(true);
    await props.declineJobInvite(inviteId);
    setDeclineLoading(false);
  };

  const typeClass = {
    'Non-Medical Member': styles.oneOff,
    'Undergraduate Student': styles.fullTime,
    'Medical Resident': styles.fullTime,
    'Medical Student': styles.fullTime,
    'Medical Fellow': styles.partTime,
    'Physician (Practicing)': styles.partTime,
    'Physician (Non-Practicing)': styles.oneOff,
  };

  const jobExpiryDaysLeft = () => {
    const expDays =
      props.jobType === JOB_TYPE.career
        ? parseInt(variantDetails?.career_expiry_days)
        : parseInt(variantDetails?.opportunity_expiry_days);
    const jobExpiryDate = moment(props.date).add(expDays, 'days');
    return jobExpiryDate.diff(moment(), 'days');
  };

  return (
    <Fragment>
      <Container
        className={`${styles.jobResult} ${
          props.isFeatured ? styles.featured : ''
        }`}
      >
        <Link
          to={
            props.link ||
            (props.inviteId
              ? props.jobType === JOB_TYPE.career
                ? `${ROUTES.CAREER}/${props.id}?invite=${props.inviteId}`
                : `${ROUTES.OPPORTUNITY}/${props.id}?invite=${props.inviteId}`
              : props.jobType === JOB_TYPE.career
                ? `${ROUTES.CAREER}/${props.id}`
                : `${ROUTES.OPPORTUNITY}/${props.id}`)
          }
        >
          <Row className={styles.resultDataRow}>
            <Col
              className={`${styles.resultImageCol}`}
              xl="auto"
              lg="auto"
              md="auto"
              xs={12}
            >
              <div className={styles.resultImage}>
                <Image
                  className="img-fluid"
                  src={
                    props.image
                      ? `https://res.cloudinary.com/careermap/image/fetch/w_170,h_170,c_lfill,g_auto,f_auto/${props.image}`
                      : DEFAULT_THUMBNAIL
                  }
                  title=""
                />
              </div>
            </Col>
            <Col
              className={`${styles.resultInfo}`}
              xl={7}
              lg={7}
              md={6}
              sm={8}
              xs={12}
            >
              {props.isFeatured ? (
                <div className={styles.featuredJob}>Featured</div>
              ) : null}
              <div className={styles.resultTitle}>{props.title}</div>
              <div className={styles.resultAttributes}>
                {props.invitedOn && (
                  <span className={styles.resultInvite}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    &nbsp; Invited{' '}
                    {moment(props.invitedOn).format('MM/DD/YYYY')}
                  </span>
                )}
                <span className={styles.resultCategory}>
                  <FontAwesomeIcon icon={faBuilding} />
                  &nbsp;
                  {props.category.split(/(,(?=\S)|:)/)[0]}
                </span>
                <span className={styles.resultLocation}>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  &nbsp;
                  {`${props.city}, ${props.state}`}
                </span>
              </div>
            </Col>
            <Col
              className={`${styles.resultAvailability}`}
              xl={3}
              lg={3}
              md={4}
              sm={4}
              xs={12}
            >
              {props.idealApplicant && (
                <div
                  className={`${
                    typeClass[props.idealApplicant] || styles.unknown
                  } ${styles.resultType}`}
                >
                  {props.idealApplicant}
                </div>
              )}
              {props.isArchived ? (
                <div className={styles.resultTypeDate}>
                  {`Ended on: ${
                    props.date
                      ? moment(props.date).format('MM/DD/YY')
                      : moment().format('MM/DD/YY')
                  }`}
                </div>
              ) : (
                <div className={styles.resultTypeDate}>
                  {moment(props.date).fromNow()}
                </div>
              )}
            </Col>
          </Row>
        </Link>
      </Container>

      {props.isPosted ? (
        <Container className={styles.resultOptions}>
          <Row>
            <Col className={'p-0'} md={6} sm={12}>
              <Link
                to={
                  props.jobType === JOB_TYPE.career
                    ? `${ROUTES.CAREER}/${props.id}`
                    : `${ROUTES.OPPORTUNITY}/${props.id}`
                }
                className="pl-2"
              >
                <Button label="View Listing" type="transparent outline-gray" />
              </Link>
            </Col>
            <Col className={styles.optionsRight} md={6} sm={12}>
              <div className={styles.availabilityText}>
                Available for {jobExpiryDaysLeft()} more days
              </div>
              <Button
                label={archiveLoading ? 'Archiving...' : 'Archive'}
                type="light-pink"
                onClick={
                  archiveLoading ? () => {} : () => archiveAJobListing(props.id)
                }
              />
            </Col>
          </Row>
        </Container>
      ) : null}

      {props.invitedOn ? (
        <Container className={styles.resultOptions}>
          <Row>
            <Col className={'p-0'} md={6} sm={12}>
              <Link
                to={
                  props.jobType === JOB_TYPE.career
                    ? `${ROUTES.CAREER}/${props.id}?invite=${props.inviteId}`
                    : `${ROUTES.OPPORTUNITY}/${props.id}?invite=${props.inviteId}`
                }
                className="pl-2"
              >
                <Button label="View" type="transparent outline-gray" />
              </Link>
            </Col>
            <Col
              className={styles.optionsRight}
              style={{ justifyContent: 'flex-end' }}
              md={6}
              sm={12}
            >
              <Button
                label={declineLoading ? 'Declining...' : 'Decline'}
                type="light-pink"
                onClick={
                  declineLoading
                    ? () => {}
                    : () => declineJobInvite(props.inviteId)
                }
              />
            </Col>
          </Row>
        </Container>
      ) : null}

      {props.isArchived ? (
        <Container className={styles.resultOptions}>
          <Row>
            <Col className={'p-0'} md={6} sm={12}>
              <Link
                to={{
                  pathname: ROUTES.FIND_SOMEONE,
                  search: `?id=${props.id}&type=${props.jobType}`,
                }}
                className="pl-2"
              >
                <Button label="Relist" type="transparent outline-gray" />
              </Link>
              <Link
                to={
                  props.jobType === JOB_TYPE.career
                    ? `${ROUTES.CAREER}/${props.id}`
                    : `${ROUTES.OPPORTUNITY}/${props.id}`
                }
                className="pl-2"
              >
                <Button label="View Listing" type="transparent outline-gray" />
              </Link>
            </Col>
          </Row>
        </Container>
      ) : null}
    </Fragment>
  );
};

export default React.memo(Result);
