import React, { Fragment, useState, useContext } from 'react';
import Button from 'components/Button/Button';
import InviteModal from '../../InviteModal/InviteModal';

// Constants
import { ROUTES } from 'constants/Constants';

import History from 'utils/History';

import GlobalContext from 'contexts/Global.context';

// Interfaces
import { Talent, MyJob } from 'interfaces/User.types';

// SCSS
import styles from './TalentCard.module.scss';
import Image from 'components/Image/Image';

const Result = (props: { user: Talent; jobs: MyJob[] }) => {
  const { isLoggedIn, variantDetails } = useContext(GlobalContext);
  const [showInvite, setShowInvite] = useState<boolean>(false);

  const DEFAULT_THUMBNAIL = `${variantDetails?.assets_url}/header_logo_thumb.svg`;

  const { user, jobs } = props;

  const showInvitePopup = () => {
    if (!isLoggedIn) {
      History.push('/login?destination=' + window.location.pathname);
    }
    setShowInvite(true);
  };

  return (
    <Fragment>
      <div className={styles.talentList}>
        <div className={styles.talentImg}>
          <Image
            className="img-fluid"
            src={user.photo || DEFAULT_THUMBNAIL}
            title={user.user_name}
          />
        </div>
        <div className={styles.talentInfo}>
          <div className={styles.talentTitle}>
            <span>
              {user.preferred_title || user.first_name || user.user_name}{' '}
              {!user.preferred_title && (user.last_name || '')}
            </span>
            <Button
              label=" "
              type="transparent outline-gray add-btn icon-only"
              onClick={() => showInvitePopup()}
            />
          </div>
          <div className={styles.talentAttributes}>
            <span className={styles.talentLocation}>
              {`${user.city}, ${user.state}`}
            </span>
          </div>
          <div className={styles.talentSkills}>
            {user.skills
              ?.split(/(,(?=\S)|:)/)
              .filter((skill) => skill !== ',')
              .join(' | ')}
          </div>
        </div>
        <div className={styles.talentAvailability}>
          <div className={styles.payRange}>
            {user.min_pay ? `$${user.min_pay}` : ''}
            {user.max_pay ? `-$${user.max_pay}` : ''}
          </div>

          <Button
            link={`${ROUTES.PROFILE}/${user.slug}`}
            target="_blank"
            label="View"
          />
        </div>
      </div>
      <InviteModal
        showInvite={showInvite}
        setShowInvite={setShowInvite}
        user={user}
        jobs={jobs}
      />
    </Fragment>
  );
};

export default React.memo(Result);
