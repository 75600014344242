import React, { Fragment, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

//Components
import Sidebar from '../Sidebar/Sidebar';

// Context
import GlobalContext from 'contexts/Global.context';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

// SCSS
import styles from './Welcome.module.scss';

const Welcome = () => {
  const { variantDetails } = useContext(GlobalContext);
  return (
    <Fragment>
      <Row>
        <Col md={variantDetails?.site_short_title === 'MIC' ? 6 : 12}>
          <div
            className={styles.mainHeading}
            dangerouslySetInnerHTML={{
              __html:
                COPY_OVERRIDES?.about?.header ||
                'Welcome to <span>your music</span> professional network.',
            }}
          ></div>
          <p
            dangerouslySetInnerHTML={{
              __html:
                COPY_OVERRIDES?.about?.subheader ||
                COPY_OVERRIDES?.about?.subheader === ''
                  ? COPY_OVERRIDES?.about?.subheader
                  : `Let’s face it: most career and opportunity marketplaces were not
                built with the music industry in mind. Too much of the focus is
                placed outside of our industry, resulting in not enough
                music-specific posts or the few posts offered being buried
                underneath everything else. We deserve a better way to find music
                industry careers and opportunities.
                <strong>
                  This is exactly why we built Music Industry Careers.
                </strong>`,
            }}
          ></p>
        </Col>
        {variantDetails?.site_short_title === 'MIC' && (
          <Col md={6}>
            <Sidebar />
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <div
            className={
              variantDetails?.site_short_title === 'BeyondPhysician'
                ? ''
                : styles.content
            }
          >
            <p
              dangerouslySetInnerHTML={{
                __html:
                  COPY_OVERRIDES?.about?.body_1 ||
                  COPY_OVERRIDES?.about?.body_1 === ''
                    ? COPY_OVERRIDES?.about?.body_1
                    : 'Music Industry Careers is a part of the Music Career Map family, a series of platforms built to help musicians, music professionals, educators and students gain access to the best education and career development resources.',
              }}
            ></p>

            <p
              dangerouslySetInnerHTML={{
                __html:
                  COPY_OVERRIDES?.about?.body_2 ||
                  COPY_OVERRIDES?.about?.body_2 === ''
                    ? COPY_OVERRIDES?.about?.body_2
                    : 'Music Career Map was developed by Brian Penick professional musician, entrepreneur, investors and writer with more than two decades of experience working in music, ranging from developing artists that have reached platinum status to launching music companies like The Counter Rhythm Group, Soundstr (acquired) and many more.',
              }}
            ></p>

            <p
              dangerouslySetInnerHTML={{
                __html: COPY_OVERRIDES?.about?.body_3 || '',
              }}
            ></p>

            <p
              dangerouslySetInnerHTML={{
                __html: COPY_OVERRIDES?.about?.body_4 || '',
              }}
            ></p>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Welcome;
