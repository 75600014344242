import React, { Fragment, useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Row, Col, Alert } from 'react-bootstrap';

// Components
import Loader from 'components/Loader/Loader';
import Button from 'components/Button/Button';

// Interfaces
import { IJobInvitation } from 'interfaces/Job.types';

// Services
import { fetchInvitationsService } from 'services/JobService';

// Constants
import { ROUTES, JOB_TYPE } from 'constants/Constants';

// Styles
import styles from './SentInvites.module.scss';

const SentInvites: React.FC = () => {
  // active jobs state
  const [loading, setLoading] = useState<boolean>(false);
  const [invites, setInvites] = useState<Array<IJobInvitation> | null>(null);
  const [error, setError] = useState<string>('');

  const fetchInvites = useCallback(async () => {
    setInvites(null);
    setLoading(true);
    const { invites, error } = await fetchInvitationsService('sent');

    if (invites) {
      setInvites(invites);
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchInvites();
  }, [fetchInvites]);

  return (
    <Fragment>
      <div className={styles.dashboardSection}>
        <Row>
          <Col>
            <Row>
              <Col>
                <div className={styles.sectionHeading}>
                  People I&apos;ve Invited
                </div>
              </Col>
            </Row>
            <Row className={styles.invites}>
              <Col>
                {loading ? <Loader /> : null}
                {invites &&
                  invites.length > 0 &&
                  invites.map((invite) => (
                    <div key={invite.id} className={styles.talentList}>
                      <div className={styles.talentImg}>
                        <img
                          className="img-fluid"
                          src={invite.photo || 'https://placehold.co/160x160'}
                          alt={invite.user_name}
                        />
                      </div>
                      <div className={styles.talentInfo}>
                        <div className={styles.talentTitle}>
                          <span>
                            {invite.first_name || invite.user_name}{' '}
                            {invite.last_name || ''}
                          </span>
                        </div>
                        <div className={styles.talentAttributes}>
                          <span className={styles.talentLocation}>
                            {`${invite.city}, ${invite.state}`}
                          </span>
                        </div>
                        <div className={styles.talentSkills}>
                          Invited{' '}
                          {moment(invite.created_at).format('MM/DD/YYYY')} to{' '}
                          <Link
                            to={
                              invite.job_type === JOB_TYPE.career
                                ? `${ROUTES.CAREER}/${invite.job_id}`
                                : `${ROUTES.OPPORTUNITY}/${invite.job_id}`
                            }
                          >
                            &quot;{invite.job_title}&quot;
                          </Link>
                        </div>
                      </div>
                      <div className={styles.talentAvailability}>
                        <div className={styles.payRange}>
                          {invite.min_pay ? `$${invite.min_pay}` : ''}
                          {invite.max_pay ? `-$${invite.max_pay}` : ''}
                        </div>

                        <Button
                          link={`${ROUTES.PROFILE}/${invite.slug}`}
                          target="_blank"
                          label="View Profile"
                        />
                      </div>
                    </div>
                  ))}
                {!loading && invites && invites.length === 0 ? (
                  <Alert variant="primary">{'No one invited yet'}</Alert>
                ) : null}
                {!loading && !invites && error ? (
                  <Alert variant="danger">{error}</Alert>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default SentInvites;
