import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';

// Components
import Button from 'components/Button/Button';

// Constants
import { ROUTES } from 'constants/Constants';

// SCSS
import styles from './Applicants.module.scss';

// Props
interface IApplicants {
  jobId?: string;
  note?: string;
  pay?: number | string;
  type?: string;
  offerId?: string;
  applicationId: string;
  is_interested: boolean;
  is_hired: boolean;
  is_disabled?: boolean;
  userId?: string;
  image: string | 'https://mic-react.s3.amazonaws.com/place_70x70.png';
  title: string;
  amount?: number | null;
  owner?: string;
  unread?: number | null;
  markNotInterested: (applicationId: string, isInterested: boolean) => void;
  markAsHired: (
    jobId: string,
    applicationId: string,
    isHired: boolean,
    directHireObject?: any,
  ) => void;
}

const Applicants = (props: IApplicants) => {
  return (
    <Fragment>
      <Container
        className={`${styles.applicantsList} ${
          !props.is_interested ? styles.hired : ''
        }
        `}
      >
        {/* <Container
        className={`${styles.applicantsList} ${
          !props.is_interested ? styles.hired : ''
        }
        ${!props.is_disabled ? styles.appDisabled : ''}
        `}
      > */}
        <Row>
          {/* @dev add class "appHired" on "applicantsListWrapper div" class when clicked on Mark As Hired */}
          {/* @dev toogle hide show "Top Candidate" on click of Mark As Top Candidate button */}
          <Col className={styles.applicantsListWrapper}>
            <div className={styles.imgWrapper}>
              <img alt={props.title} src={props.image} />
            </div>
            <div className={styles.appDetails}>
              <div className={styles.appName}>{props.title}</div>
              {props.note ? (
                <div className={styles.appAbout}>{props.note}</div>
              ) : (
                ''
              )}
            </div>
            <div className={styles.offer}>
              <div className={styles.offerAmount}>${props.amount}</div>
              <div className={styles.offerLabel}>
                <Link
                  to={`${ROUTES.DASHBOARD}${ROUTES.MY_MESSAGES}/${props.userId}?applicationId=${props.applicationId}&type=${props.type}`}
                >
                  View Full Offer
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className={styles.resultOptions}>
        <Row className="justify-content-md-between">
          <Col className="p-0" md="auto" sm="12">
            {props.is_hired ? (
              <Button
                onClick={() =>
                  props.markAsHired(
                    props.jobId,
                    props.applicationId,
                    !props.is_hired,
                  )
                }
                label="Hired"
                type="green-check"
              />
            ) : props.pay ? (
              <Button
                label="Accept Offer/Start Gig"
                type="transparent outline-gray"
                link={`${ROUTES.DASHBOARD}${ROUTES.MY_MESSAGES}/${props.userId}?applicationId=${props.applicationId}&type=${props.type}&showPay=true`}
              />
            ) : (
              <Button
                label="Accept Offer/Start Gig"
                type="transparent outline-gray"
                onClick={() =>
                  props.markAsHired(props.jobId, props.applicationId, true, {
                    offerId: props.offerId,
                    applicantId: props.userId,
                  })
                }
              />
            )}
            <Button
              label={
                <span>
                  Message/Counter Offer{' '}
                  {props.unread ? (
                    <span className={styles.unread}>{props.unread}</span>
                  ) : (
                    ''
                  )}
                </span>
              }
              type="transparent outline-gray"
              link={`${ROUTES.DASHBOARD}${ROUTES.MY_MESSAGES}/${props.userId}?applicationId=${props.applicationId}&type=${props.type}`}
            />
          </Col>
          <Col md="auto" sm="12" className="p-0">
            <Button
              label={props.is_interested ? 'Not Interested' : 'Interested'}
              type="transparent outline-gray"
              /* disabled={!props.is_interested} */
              onClick={() =>
                props.markNotInterested(
                  props.applicationId,
                  !props.is_interested,
                )
              }
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Applicants;
