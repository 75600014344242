import React from 'react';
import Search from './Search';

// Constants
import { JOB_TYPE } from 'constants/Constants';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

const Careers: React.FC = () => {
  return (
    <Search
      title={COPY_OVERRIDES?.careers?.header || 'Careers'}
      type={JOB_TYPE.career}
      subtitle={
        COPY_OVERRIDES?.careers?.subheader ||
        'Non-clinical one-offs to contract work, even volunteering for experience.'
      }
    />
  );
};

export default React.memo(Careers);
