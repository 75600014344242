import React from 'react';
import Search from './Search';

// Constants
import { JOB_TYPE } from 'constants/Constants';
// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

const Opportunities: React.FC = () => {
  return (
    <Search
      title={COPY_OVERRIDES?.opportunities?.header || 'Opportunities'}
      type={JOB_TYPE.opportunity}
      subtitle={
        COPY_OVERRIDES?.opportunities?.subheader ||
        'Find physicians, students, entrepreneurs, and more. '
      }
    />
  );
};

export default React.memo(Opportunities);
