import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

// Context
import GlobalContext from 'contexts/Global.context';

// SCSS
import styles from './Sidebar.module.scss';

const Sidebar = () => {
  const { variantDetails } = useContext(GlobalContext);
  return (
    <Fragment>
      <Row>
        <Col>
          {variantDetails?.site_short_title !== 'CCE' ? (
            <div className={styles.sidebarWrapper}>
              <div className={styles.boxWrap}>
                <div className={styles.heading}>Careers</div>
                <div className={styles.subHeading}>
                  Find an array of career options, including:
                </div>
                <div className={styles.list}>
                  <div>
                    <Link to="/#">Internships</Link>
                  </div>
                  <div>
                    <Link to="/#">Freelance/Contract Work</Link>
                  </div>
                  <div>
                    <Link to="/#">Part/Full Time Employment</Link>
                  </div>
                </div>
              </div>
              <div className={styles.boxWrap}>
                <div className={styles.heading}>Opportunities</div>
                <div className={styles.subHeading}>
                  Our opportunities are endless, including:
                </div>
                <div className={styles.list}>
                  <div>
                    <Link to="/#">Mentors/Consultants</Link>
                  </div>
                  <div>
                    <Link to="/#">Music Lessons</Link>
                  </div>
                  <div>
                    <Link to="/#">Professional Servies</Link>
                  </div>
                  <div>
                    <Link to="/#">Peer-to-Peer Collaborations</Link>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.sidebarWrapper}>
              <div className={styles.boxWrap}>
                <div className={styles.list}>
                  <div>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="http://cceglobal.org"
                    >
                      CCE Global
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Fragment>
  );
};

export default Sidebar;
