import React, { useContext } from 'react';
import { Router, Switch, Route } from 'react-router-dom';

// History
import History from './utils/History';

// Routes path
import { ROUTES } from 'constants/Constants';

import { PrivateRoute } from './routes/Private.routes';

import GlobalContext from './contexts/Global.context';

// Components
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Terms from './pages/Terms/Terms';
import PaymentProcessed from './pages/PaymentProcessed/PaymentProcessed';
import Privacy from './pages/Privacy/Privacy';
import Support from './pages/Support/Support';
import AccountVerify from './pages/AccountVerify/AccountVerify';
import Profile from './pages/Profile/Profile';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Dashboard from 'pages/Dashboard/Dashboard';
import ActiveJob from './pages/JobBoard/ActiveJob/ActiveJob';

import Careers from './pages/Search/Careers';
import Opportunities from './pages/Search/Opportunities';
import Talent from './pages/TalentSearch/TalentSearch';
import Post from './pages/JobBoard/Post/Post';
import Career from './pages/JobBoard/Page/Page';
import Apply from './pages/JobBoard/Apply/Apply';

const AppRoutes = () => {
  const { isLoggedIn } = useContext(GlobalContext);
  return (
    <>
      <Router history={History}>
        <Switch>
          <Route exact path={ROUTES.HOME} component={Home} />
          <Route exact path={ROUTES.APPLICANT_PROFILE} component={Profile} />
          <Route exact path={ROUTES.LOGIN} component={Login} />
          <Route exact path={ROUTES.REGISTER} component={Register} />
          <Route
            exact
            path={ROUTES.FORGOT_PASSWORD}
            component={ForgotPassword}
          />
          <Route exact path={ROUTES.RESET_PASSWORD} component={ResetPassword} />

          <PrivateRoute
            path={ROUTES.DASHBOARD}
            component={Dashboard}
            isLoggedIn={isLoggedIn}
          />

          {/* Post Job Type */}
          <PrivateRoute
            path={ROUTES.FIND_SOMEONE}
            component={Post}
            isLoggedIn={isLoggedIn}
          />

          {/* Edit Post */}
          <PrivateRoute
            path={ROUTES.EDIT_POST}
            component={Post}
            isLoggedIn={isLoggedIn}
          />

          {/* Job Listing/Search */}
          <Route exact path={ROUTES.CAREERS} component={Careers} />
          <Route exact path={ROUTES.OPPORTUNITIES} component={Opportunities} />

          <Route exact path={ROUTES.TALENT} component={Talent} />

          {/* Inner Career Page */}
          <Route exact path={ROUTES.CAREER_DETAIL} component={Career} />

          {/* Inner Opportunity Page */}
          <Route exact path={ROUTES.OPPORTUNITY_DETAIL} component={Career} />

          {/* About Page */}
          <Route exact path={ROUTES.ABOUT} component={About} />

          {/* Active Job Page */}
          <Route exact path={ROUTES.ACTIVE_JOB} component={ActiveJob} />

          {/* Completed Job Page */}
          <Route exact path={ROUTES.COMPLETED_JOB} component={ActiveJob} />

          {/* Active Job Page */}
          <Route
            exact
            path={ROUTES.PAYMENT_PROCESSED}
            component={PaymentProcessed}
          />

          {/* Submit Offer Page */}
          <PrivateRoute
            path={ROUTES.SUBMIT_OFFER}
            component={Apply}
            isLoggedIn={isLoggedIn}
          />

          {/* Terms Page */}
          <Route exact path={ROUTES.TERMS} component={Terms} />

          {/* Privacy Policy Page */}
          <Route exact path={ROUTES.PRIVACY} component={Privacy} />

          {/* Support Page */}
          <Route exact path={ROUTES.SUPPORT} component={Support} />

          {/* About Page */}
          <Route exact path={ROUTES.ACCOUNT_VERIFY} component={AccountVerify} />
        </Switch>
      </Router>
      <Footer />
    </>
  );
};

export default AppRoutes;
