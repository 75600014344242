import React, { Fragment, useContext, useState, useEffect } from 'react';

import { NavLink, useLocation } from 'react-router-dom';

// Context
import GlobalContext from 'contexts/Global.context';

// Constants
import { ROUTES, JOB_TYPE } from 'constants/Constants';

import COPY_OVERRIDES from 'config/CopyOverrides';

// Services
import { getAllConversationsService } from 'services/MessageService';
import {
  fetchApplicantsService,
  fetchSpecificJobsService,
  fetchInvitationsService,
} from 'services/JobService';

// Styles
import styles from './DashboardMenu.module.scss';

interface Props {
  url?: string;
}

const DashboardMenu: React.FC<Props> = ({ url }: Props) => {
  const { logout } = useContext(GlobalContext);

  const { pathname } = useLocation();

  const [unreadCount, setUnreadCount] = useState<number>(0);

  const [pendingCareerApplicantsCount, setPCApplicantsCount] =
    useState<number>(0);
  // const [pendingOppApplicantsCount, setPOppApplicantsCount] = useState<number>(
  //   0
  // );
  const [activeJobsCount, setActiveJobsCount] = useState<number>(0);
  const [completedJobsCount, setCompletedJobsCount] = useState<number>(0);

  const [sentInvitesCount, setSentInvitesCount] = useState<number>(0);
  const [pendingInvitesCount, setPendingInvitesCount] = useState<number>(0);

  const fetchConversations = async () => {
    const { conversations } = await getAllConversationsService({
      unread: true,
    });

    if (conversations) {
      setUnreadCount(conversations.length);
    }
  };

  const fetchPendingApplicantsCount = async (type: string) => {
    const { applicants } = await fetchApplicantsService({
      type,
    });

    if (applicants) {
      const count = applicants.reduce(
        (acc: any, currentApplicant: { applicants: any }) =>
          acc + currentApplicant.applicants,
        0,
      );
      if (type === JOB_TYPE.career) {
        setPCApplicantsCount(count);
      } else if (type === JOB_TYPE.opportunity) {
        // setPOppApplicantsCount(count);
      }
    }
  };

  const fetchJobsCount = async (isCompleted: boolean) => {
    const { count } = await fetchSpecificJobsService({
      type: JOB_TYPE.career,
      completed: isCompleted,
    });
    if (count) {
      if (isCompleted) {
        setCompletedJobsCount(count);
      } else {
        setActiveJobsCount(count);
      }
    }
  };

  const fetchInvitesCount = async (type: string) => {
    const { invites } = await fetchInvitationsService(type);
    if (invites) {
      if (type === 'sent') {
        setSentInvitesCount(invites.length);
      } else {
        setPendingInvitesCount(invites.length);
      }
    }
  };

  useEffect(() => {
    // fetch unread messages count
    fetchConversations();
    // fetch pending career applicants count
    fetchPendingApplicantsCount(JOB_TYPE.career);
    // fetch pending opportunity applicants count
    fetchPendingApplicantsCount(JOB_TYPE.opportunity);
    fetchJobsCount(true);
    fetchJobsCount(false);
    fetchInvitesCount('sent');
    fetchInvitesCount('received');
  }, []);

  useEffect(() => {
    if (pathname.indexOf('messages') > -1) {
      fetchConversations();
    }
  }, [pathname]);

  return (
    <Fragment>
      <div className={styles.dashMenu}>
        <ul>
          <li>
            <span>{COPY_OVERRIDES?.dashboard?.careers || 'Careers'}</span>
            <ul>
              <li>
                <NavLink
                  activeClassName={styles.active}
                  to={`${url}${ROUTES.CAREERS_APPLIED}`}
                >
                  I&apos;ve Applied
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName={styles.active}
                  to={`${url}${ROUTES.CAREERS_POSTED}`}
                >
                  I&apos;ve Posted
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  activeClassName={styles.active}
                  to={`${url}${ROUTES.ARCHIVED_CAREERS}`}
                >
                  Archived Careers
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  activeClassName={styles.active}
                  to={`${url}${ROUTES.CAREERS_PENDING_APPLICANTS}`}
                >
                  Pending Applicants
                  <span className={styles.count}>
                    {pendingCareerApplicantsCount}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName={styles.active}
                  to={`${url}${ROUTES.ACTIVE_JOBS}`}
                >
                  Active
                  <span className={styles.count}>{activeJobsCount}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName={styles.active}
                  to={`${url}${ROUTES.COMPLETED_JOBS}`}
                >
                  Completed
                  <span className={styles.count}>{completedJobsCount}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName={styles.active}
                  to={`${url}${ROUTES.SENT_INVITES}`}
                >
                  People I&apos;ve Invited
                  <span className={styles.count}>{sentInvitesCount}</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <span>
              {COPY_OVERRIDES?.dashboard?.opportunities || 'Opportunites'}
            </span>
            <ul>
              {/* <li>
                <NavLink
                  activeClassName={styles.active}
                  to={`${url}${ROUTES.OPPORTUNITIES_APPLIED}`}
                >
                  I&apos;ve Applied
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  activeClassName={styles.active}
                  to={`${url}${ROUTES.OPPORTUNITIES_POSTED}`}
                >
                  I&apos;ve Posted
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  activeClassName={styles.active}
                  to={`${url}${ROUTES.ARCHIVED_OPPORTUNITIES}`}
                >
                  Archived Opportunites
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink
                  activeClassName={styles.active}
                  to={`${url}${ROUTES.OPPORTUNITIES_PENDING_APPLICANTS}`}
                >
                  Pending Applicants
                  <span className={styles.count}>
                    {pendingOppApplicantsCount}
                  </span>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  activeClassName={styles.active}
                  to={`${url}${ROUTES.PENDING_INVITES}`}
                >
                  Pending Invites
                  <span className={styles.count}>{pendingInvitesCount}</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <span>My Information</span>
            <ul>
              <li>
                <NavLink
                  activeClassName={styles.active}
                  to={`${url}${ROUTES.MY_MESSAGES}`}
                >
                  My Messages{' '}
                  <span className={styles.count}>{unreadCount}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName={styles.active}
                  to={`${url}${ROUTES.EDIT_PROFILE}`}
                >
                  Edit Profile
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName={styles.active}
                  to={`${url}${ROUTES.MY_RESUME}`}
                >
                  My Credentials
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName={styles.active}
                  to={`${url}${ROUTES.EDIT_ACCOUNT_INFO}`}
                >
                  Edit Account Info
                </NavLink>
              </li>
              <li>
                <NavLink to={ROUTES.HOME} onClick={() => logout()}>
                  Sign Out
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default DashboardMenu;
