export const EDUCATIONS = [
  { label: 'High School Diploma' },
  { label: 'GED or Equivalent' },
  { label: 'Certificate' },
  { label: 'BA/Bachelor of Arts' },
  { label: 'BS/Bachelor of Science' },
  { label: 'MA/Master of Arts' },
  { label: 'MS/Master of Science' },
  { label: 'M Ed/Master of Education' },
  { label: 'MHA/Master of Heath Administration' },
  { label: 'PhD/Doctor of Philosophy' },
  { label: 'MD/Medical Doctor' },
  { label: 'DO/Doctor of Osteopathic Medicine' },
  { label: 'JD/Juris Doctor' },
  { label: 'MBA/Masters of Business Administration' },
];

export const QUALIFICATIONS = [
  { label: 'Non-Medical Member' },
  { label: 'Undergraduate Student' },
  { label: 'Medical Student' },
  { label: 'Medical Resident' },
  { label: 'Medical Fellow' },
  { label: 'Physician (Practicing)' },
  { label: 'Physician (Non-Practicing)' },
];

export const FELLOWSHIPS = [
  { label: 'Anesthesiology Fellowships' },
  { label: 'Colon And Rectal Surgery' },
  { label: 'Dermatology Fellowships' },
  { label: 'Emergency Medicine Fellowships' },
  { label: 'Family Medicine Fellowships' },
  { label: 'Internal Medicine Fellowships' },
  { label: 'Medical Genetics And Genomics Fellowships' },
  { label: 'Minimally Invasive | Bariatric Surgery' },
  { label: 'Neurological Surgery Fellowships' },
  { label: 'Neurology Fellowships' },
  { label: 'Obstetrics And Gynecology Fellowships' },
  { label: 'Ophthalmology Fellowships' },
  { label: 'Orthopaedic Surgery Fellowships' },
  { label: 'Osteopathic Neuromusculoskeletal Medicine (Preventive Medicine)' },
  { label: 'Otolaryngology - Head And Neck Surgery Fellowships' },
  { label: 'Pathology Fellowships' },
  { label: 'Pediatric Fellowships' },
  { label: 'Physical Medicine And Rehabilitation Fellowships' },
  { label: 'Plastic Surgery Fellowships' },
  { label: 'Preventive Medicine Fellowships' },
  { label: 'Psychiatry Fellowships' },
  { label: 'Radiology Fellowships' },
  { label: 'Surgery Fellowships' },
  { label: 'Thoracic Surgery Fellowships' },
  { label: 'Urology Fellowships' },
];

export const FELLOWSHIP_SUBSPECIALITIES = {
  'Anesthesiology Fellowships': [
    { label: 'Adult Cardiothoracic (Anesthesiology)' },
    { label: 'Clinical Informatics (Anesthesiology)' },
    { label: 'Critical Care Medicine (Anesthesiology)' },
    { label: 'Regional Anesthesiology & Acute Pain Medicine (Anesthesiology)' },
    { label: 'Pediatric Cardiac Anesthesiology (Anesthesiology)' },
    { label: 'Obstetric Anesthesiology (Anesthesiology)' },
    { label: 'Pain Medicine (Multidisciplinary)' },
    { label: 'Pediatric Anesthesiology (Anesthesiology)' },
  ],
  'Colon And Rectal Surgery': [],
  'Dermatology Fellowships': [
    { label: 'Dermatopathology (Multidisciplinary)' },
    { label: 'Micrographic Surgery And Dermatologic Oncology (Dermatology)' },
    { label: 'Pediatric Dermatology (Dermatology)' },
  ],
  'Emergency Medicine Fellowships': [
    { label: 'Clinical Informatics (Emergency Medicine)' },
    { label: 'Emergency Medical Services (Emergency Medicine)' },
    { label: 'Medical Toxicology (Emergency Medicine)' },
    { label: 'Pediatric Emergency Medicine (Emergency Medicine)' },
    { label: 'Sports Medicine (Emergency Medicine)' },
    { label: 'Undersea And Hyperbaric Medicine (Emergency Medicine)' },
  ],
  'Family Medicine Fellowships': [
    { label: 'Clinical Informatics (Family Medicine)' },
    { label: 'Geriatric Medicine (Family Medicine)' },
    { label: 'Hospice And Palliative Medicine (Multidisciplinary)' },
    { label: 'Sports Medicine (Family Medicine)' },
  ],
  'Internal Medicine Fellowships': [
    { label: 'Adult Congenital Heart Disease (Internal Medicine)' },
    {
      label:
        'Advanced Heart Failure & Transplant Cardiology (Internal Medicine)',
    },
    { label: 'Cardiovascular Disease (Internal Medicine)' },
    { label: 'Clinical Cardiac Electrophysiology (Internal Medicine)' },
    { label: 'Clinical Informatics (Internal Medicine) (Internal Medicine)' },
    { label: 'Critical Care Medicine (Internal Medicine)' },
    { label: 'Endocrinology, Diabetes, And Metabolism (Internal Medicine)' },
    { label: 'Gastroenterology (Internal Medicine)' },
    { label: 'Geriatric Medicine (Internal Medicine)' },
    { label: 'Hematology (Internal Medicine)' },
    { label: 'Hematology & Medical Oncology (Internal Medicine)' },
    { label: 'Infectious Disease (Internal Medicine)' },
    { label: 'Interventional Cardiology (Internal Medicine)' },
    { label: 'Nephrology (Internal Medicine)' },
    { label: 'Medical Oncology (Internal Medicine)' },
    { label: 'Pulmonary Disease (Internal Medicine)' },
    {
      label: 'Pulmonary Disease And Critical Care Medicine (Internal Medicine)',
    },
    { label: 'Rheumatology (Internal Medicine)' },
    { label: 'Sleep Medicine (Multidisciplinary)' },
    { label: 'Transplant Hepatology (Internal Medicine)' },
  ],
  'Medical Genetics And Genomics Fellowships': [
    { label: 'Medical Genetics And Genomics (Medical Genetics)' },
    { label: 'Medical Biochemical Genetics (Medical Genetics)' },
    { label: 'Molecular Genetic Pathology (Multidisciplinary)' },
  ],
  'Neurological Surgery Fellowships': [
    { label: 'Endovascular Surgical Neuroradiology (Neurological Surgery)' },
  ],
  'Neurology Fellowships': [
    { label: 'Brain Injury Medicine (Neurology)' },
    { label: 'Clinical Neurophysiology (Neurology)' },
    { label: 'Epilepsy (Neurology)' },
    { label: 'Neurocritical Care (Multidisciplinary)' },
    { label: 'Endovascular Surgical Neuroradiology (Neurology)' },
    { label: 'Neurodevelopmental Disabilities (Neurology)' },
    { label: 'Neuromuscular Medicine (Neurology)' },
    { label: 'Vascular Neurology (Neurology)' },
    { label: 'Child Neurology (Neurology)' },
    { label: 'Nuclear Medicine (Neurology)' },
  ],
  'Obstetrics And Gynecology Fellowships': [
    {
      label:
        'Female Pelvic Medicine & Reconstructive Surgery (Obstetrics and Gynecology)',
    },
    { label: 'Gynecologic Oncology (Obstetrics and Gynecology)' },
    { label: 'Maternal-Fetal Medicine (Obstetrics and Gynecology)' },
    {
      label:
        'Reproductive Endocrinology And Infertility (Obstetrics and Gynecology)',
    },
    { label: 'Complex Family Planning (Obstetrics and Gynecology)' },
  ],
  'Ophthalmology Fellowships': [
    { label: 'Ophthalmic Plastic & Reconstructive Surgery (Ophthalmology)' },
  ],
  'Orthopaedic Surgery Fellowships': [
    { label: 'Adult Reconstructive Orthopaedics (Orthopaedic Surgery)' },
    { label: 'Foot & Ankle Orthopaedics (Orthopaedic Surgery)' },
    { label: 'Hand Surgery (Orthopaedic Surgery)' },
    { label: 'Musculoskeletal Oncology (Orthopaedic Surgery)' },
    { label: 'Orthopaedic Sports Medicine (Orthopaedic Surgery)' },
    { label: 'Orthopaedic Surgery Of The Spine (Orthopaedic Surgery)' },
    { label: 'Orthopaedic Trauma (Orthopaedic Surgery)' },
    { label: 'Pediatric Orthopaedics (Orthopaedic Surgery)' },
  ],
  'Osteopathic Neuromusculoskeletal Medicine (Preventive Medicine)': [],
  'Otolaryngology - Head And Neck Surgery Fellowships': [
    { label: 'Neurotology (Otolaryngology)' },
    { label: 'Pediatric Otolaryngology (Otolaryngology)' },
  ],
  'Pathology Fellowships': [
    { label: 'Pathology-Anatomic & Clinical (Pathology)' },
    { label: 'Blood Banking/Transfusion Medicine (Pathology)' },
    { label: 'Clinical Informatics (Pathology)' },
    { label: 'Chemical Pathology (Pathology)' },
    { label: 'Cytopathology (Pathology)' },
    { label: 'Forensic Pathology (Pathology)' },
    { label: 'Hematopathology (Pathology)' },
    { label: 'Medical Microbiology (Pathology)' },
    { label: 'Neuropathology (Pathology)' },
    { label: 'Pediatric Pathology (Pathology)' },
    { label: 'Selective Pathology (Pathology)' },
  ],
  'Pediatric Fellowships': [
    { label: 'Adolescent Medicine (Pediatrics)' },
    { label: 'Child Abuse Pediatrics (Pediatrics)' },
    { label: 'Clinical Informatics (Pediatrics)' },
    { label: 'Developmental-Behavioral Pediatrics (Pediatrics)' },
    { label: 'Neonatal-Perinatal Medicine (Pediatrics)' },
    { label: 'Pediatric Cardiology (Pediatrics)' },
    { label: 'Pediatric Critical Care Medicine (Pediatrics)' },
    { label: 'Pediatric Emergency Medicine (Pediatrics)' },
    { label: 'Pediatric Endocrinology (Pediatrics)' },
    { label: 'Pediatric Gastroenterology (Pediatrics)' },
    { label: 'Pediatric Hematology/Oncology (Pediatrics)' },
    { label: 'Pediatric Infectious Diseases (Pediatrics)' },
    { label: 'Pediatric Nephrology (Pediatrics)' },
    { label: 'Pediatric Pulmonology (Pediatrics)' },
    { label: 'Pediatric Rheumatology (Pediatrics)' },
    { label: 'Sports Medicine (Pediatrics)' },
    { label: 'Pediatric Transplant Hepatology (Pediatrics)' },
    { label: 'Pediatric Hospital Medicine (Pediatrics)' },
  ],
  'Physical Medicine And Rehabilitation Fellowships': [
    { label: 'Brain Injury Medicine (Physical Medicine And Rehabilitation)' },
    {
      label:
        'Spinal Cord Injury Medicine (Physical Medicine And Rehabilitation)',
    },
    {
      label:
        'Pediatric Rehabilitation Medicine (Physical Medicine And Rehabilitation)',
    },
    { label: 'Sports Medicine (Physical Medicine And Rehabilitation)' },
  ],
  'Plastic Surgery Fellowships': [
    { label: 'Craniofacial Surgery (Plastic Surgery)' },
    { label: 'Hand Surgery (Plastic Surgery)' },
  ],
  'Preventive Medicine Fellowships': [
    { label: 'Preventive Medicine (Preventive Medicine)' },
    { label: 'Medical Toxicology (Preventive Medicine)' },
    { label: 'Undersea & Hyperbaric Medicine (Preventive Medicine)' },
  ],
  'Psychiatry Fellowships': [
    { label: 'Addiction Medicine (Multidisciplinary)' },
    { label: 'Addiction Psychiatry (Psychiatry)' },
    { label: 'Child And Adolescent Psychiatry (Psychiatry)' },
    { label: 'Forensic Psychiatry (Psychiatry)' },
    { label: 'Geriatric Psychiatry (Psychiatry)' },
    { label: 'Consultation-Liaison Psychiatry (Psychiatry)' },
  ],
  'Radiology Fellowships': [
    { label: 'Abdominal Radiology (Radiology)' },
    { label: 'Endovascular Surgical Neuroradiology (Radiology)' },
    { label: 'Musculoskeletal Radiology (Radiology)' },
    { label: 'Neuroradiology (Radiology)' },
    { label: 'Nuclear Radiology (Radiology)' },
    { label: 'Pediatric Radiology (Radiology)' },
    { label: 'Vascular And Interventional Radiology (Radiology)' },
    { label: 'Interventional Radiology - Independent (Radiology)' },
    { label: 'Interventional Radiology - Integrated (Radiology)' },
  ],
  'Surgery Fellowships': [
    { label: 'Complex General Surgical Oncology (Surgery)' },
    { label: 'Hand Surgery (Surgery)' },
    { label: 'Pediatric Surgery (Surgery)' },
    { label: 'Surgical Critical Care (Surgery)' },
    { label: 'Vascular Surgery (Surgery)' },
    { label: 'Vascular Surgery - Integrated (Surgery)' },
  ],
  'Thoracic Surgery Fellowships': [
    { label: 'Thoracic Surgery (Thoracic Surgery)' },
    { label: 'Congenital Cardiac Surgery (Thoracic Surgery)' },
    { label: 'Thoracic Surgery - Integrated (Thoracic Surgery)' },
  ],
  'Urology Fellowships': [
    { label: 'Female Pelvic Medicine & Reconstructive Surgery (Urology)' },
    { label: 'Pediatric Urology (Urology)' },
  ],
  'Minimally Invasive | Bariatric Surgery': [
    {
      label:
        'Advanced laparoscopic, and endoscopic surgery with bariatric surgery training',
    },
  ],
};

export const SPECIALITIES = [
  { label: 'Allergy and Immunology' },
  { label: 'Anesthesiology' },
  { label: 'Colon and Rectal Surgery' },
  { label: 'Dermatology' },
  { label: 'Emergency Medicine' },
  { label: 'Family Medicine' },
  { label: 'Internal Medicine' },
  { label: 'Medical Genetics and Genomics' },
  { label: 'Neurological Surgery' },
  { label: 'Neurology' },
  { label: 'Nuclear Medicine' },
  { label: 'Obstetrics and Gynecology' },
  { label: 'Ophthalmology' },
  { label: 'Orthopaedic Surgery' },
  { label: 'Osteopathic Neuromusculoskeletal Medicine' },
  { label: 'Otolaryngology' },
  { label: 'Pathology' },
  { label: 'Pediatrics' },
  { label: 'Physical Medicine and Rehabilitation' },
  { label: 'Plastic Surgery' },
  { label: 'Podiatry' },
  { label: 'Preventive Medicine' },
  { label: 'Psychiatry' },
  { label: 'Radiation Oncology' },
  { label: 'Radiology' },
  { label: 'Surgery' },
  { label: 'Thoracic Surgery' },
  { label: 'Urology' },
];

export const GOALS = [
  { label: 'Find or hire someone from this community (free or paid).' },
  { label: 'Find side gigs to make more money (e.g., expert witness).' },
  {
    label:
      'Offer free or paid professional services (e.g., mentoring, consulting).',
  },
  {
    label: 'Increase my skills/knowledge (e.g., online courses, credentials).',
  },
  { label: 'Increase my network and build my professional brand.' },
  { label: 'I want to do something else (please specify in next field).' },
  // eslint-disable-next-line quotes
  { label: "I don't know yet, but I'm excited to see what's offered." },
];

export const CREDENTIAL_EXPERIENCE = [
  { label: 'Experience/Training: Required (Verified Credential)' },
  { label: 'Experience/Training: Preferred (Not Required)' },
  { label: 'Experience/Training: No Requirement/Preference' },
];
