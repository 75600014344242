import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

// Components
import Header from './Components/Header/Header';
import Content from './Components/Content/Content';
import Loader from 'components/Loader/Loader';

import GlobalContext from 'contexts/Global.context';

// Interfaces
import { ApplicantProfile, MyJob } from 'interfaces/User.types';
import { Job } from 'interfaces/Job.types';

// Services
import { fetchApplicantProfileService } from 'services/UserService';
import { fetchJobsService, fetchMyJobsService } from 'services/JobService';

// Styles
import styles from './Profile.module.scss';
import { JOB_TYPE, CLOUDINARY_BASE_URL } from 'constants/Constants';

const Profile = () => {
  const { slug } = useParams();
  const { isLoggedIn, variantDetails } = useContext(GlobalContext);
  const { search: paramValue } = useLocation();

  const [loading, setLoading] = useState<boolean>(false);
  const [jobs, setJobs] = useState<MyJob[]>([]);
  const [services, setServices] = useState<Job[]>([]);

  const [profile, setProfile] = useState<ApplicantProfile | null>(null);
  const [error, setError] = useState<string>('');

  const fetchUserServices = async (userId: string) => {
    setLoading(true);
    const { jobs, error } = await fetchJobsService(
      {
        user: userId,
        type: JOB_TYPE.opportunity,
      },
      { limit: 50, skip: 0, orderBy: 'created_at' },
    );

    if (jobs) {
      setServices(jobs);
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  };

  const fetchUserProfile = useCallback(async () => {
    setLoading(true);
    const { profile, error } = await fetchApplicantProfileService(slug);

    if (profile) {
      setProfile(profile);
      fetchUserServices(profile.id);
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  }, [slug]);

  const fetchMyJobs = useCallback(async () => {
    if (isLoggedIn) {
      const { jobs: fetchedJobs } = await fetchMyJobsService();

      if (fetchedJobs) {
        setJobs(fetchedJobs);
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    fetchUserProfile();
    fetchMyJobs();
  }, [fetchUserProfile, fetchMyJobs]);

  const clippedPhoto = (src: String) => {
    return `${CLOUDINARY_BASE_URL}/c_thumb,w_170,w_170,g_face,f_auto,z_0.6/s3/${src.substr(
      src.lastIndexOf('/') + 1,
    )}`;
  };

  return (
    // @dev please add class on main header when profile page loads
    <Fragment>
      <div className={styles.profileWrapper}>
        {loading && <Loader />}
        {profile ? (
          <div>
            <Helmet>
              <title>
                {profile.first_name} {profile.last_name} -{' '}
                {variantDetails?.site_title}{' '}
              </title>
              {profile.photo && (
                <meta
                  property="og:image"
                  content={clippedPhoto(profile.photo)}
                />
              )}
              <meta
                property="og:title"
                content={`${profile.first_name} - ${profile.last_name} - ${variantDetails?.site_title}`}
              />
            </Helmet>
            {paramValue === '?confirm=1' && (
              <Alert variant="warning" className="mx-5">
                Check your email for an account confirmation link.
              </Alert>
            )}
            <div className={styles.headerWrapper}>
              <Header profile={profile} />
            </div>
            <div className={styles.contentWrapper}>
              <Content jobs={jobs} services={services} profile={profile} />
            </div>
          </div>
        ) : null}
        {error && <Alert variant="danger">{error}</Alert>}
      </div>
    </Fragment>
  );
};

export default Profile;
