import React from 'react';

// Components
import Checkbox from 'components/Form/Checkbox/Checkbox';

import SKILLS from 'constants/Skills';

import { QUALIFICATIONS } from 'constants/Educations';

// SCSS
import styles from './Sidebar.module.scss';

interface Query {
  skills?: Array<string>;
  classifications?: Array<string>;
  resume?: boolean;
  hired?: boolean;
  credentialed?: boolean;
  recently_online?: boolean;
  online?: boolean;
}

interface FilterProps {
  title: string;
  checkboxValues: Query | any;
  onResumeChange: () => void;
  onOnlineChange: () => void;
  onRecentlyOnlineChange: () => void;
  onCredentialedChange: () => void;
  onHiredChange: () => void;
  onSkillsFilterChange: (skills: Array<string>) => void;
  onClassificationsFilterChange: (items: Array<string>) => void;
}

const SKILLS_OPTIONS = SKILLS.map((item) => ({
  id: item.label.split(' ').join('_'),
  label: item.label,
  name: item.label,
}));

const CLASSIFICATION_OPTIONS = QUALIFICATIONS.map((item) => ({
  id: item.label.split(' ').join('_'),
  label: item.label,
  name: item.label,
}));

const Filters = (props: FilterProps) => {
  const isCheckboxChecked = (parentType: string, name?: any) => {
    if (name && typeof name === 'object') {
      return JSON.stringify(props.checkboxValues[parentType]).includes(
        JSON.stringify(name),
      );
    }
    if (name) {
      return props.checkboxValues[parentType]?.includes(name);
    } else {
      return props.checkboxValues[parentType];
    }
  };

  const handleSkillsChange = (event: any) => {
    const { name } = event.target;
    const { skills } = props.checkboxValues;

    let arr = skills && skills.length ? skills : [];
    if (arr.includes(name)) {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    } else {
      arr.push(name);
    }
    props.onSkillsFilterChange(arr);
  };

  const handleClassificationsChange = (event: any) => {
    const { name } = event.target;
    const { classifications } = props.checkboxValues;

    let arr = classifications && classifications.length ? classifications : [];
    if (arr.includes(name)) {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    } else {
      arr.push(name);
    }
    props.onClassificationsFilterChange(arr);
  };

  const renderSkillsCheckboxes = () => {
    return (
      <div>
        {SKILLS_OPTIONS.map((loc) => (
          <Checkbox
            key={loc.id}
            id={loc.id}
            label={loc.label}
            name={loc.name}
            isChecked={isCheckboxChecked('skills', loc.name)}
            onChange={handleSkillsChange}
          />
        ))}
      </div>
    );
  };

  const renderClassificationsCheckboxes = () => {
    return (
      <div>
        {CLASSIFICATION_OPTIONS.map((loc) => (
          <Checkbox
            key={loc.id}
            id={loc.id}
            label={loc.label}
            name={loc.name}
            isChecked={isCheckboxChecked('classifications', loc.name)}
            onChange={handleClassificationsChange}
          />
        ))}
      </div>
    );
  };

  const renderFilterCheckboxes = () => {
    return (
      <div>
        <Checkbox
          id="resume"
          label="Has Resume"
          name="resume"
          isChecked={isCheckboxChecked('resume')}
          onChange={props.onResumeChange}
        />
        <Checkbox
          id="online"
          label="Available"
          name="online"
          isChecked={isCheckboxChecked('online')}
          onChange={props.onOnlineChange}
        />
        <Checkbox
          id="recently_online"
          label="Active within 24h"
          name="recently_online"
          isChecked={isCheckboxChecked('recently_online')}
          onChange={props.onRecentlyOnlineChange}
        />
        <Checkbox
          id="credentialed"
          label="Credentialed"
          name="credentialed"
          isChecked={isCheckboxChecked('credentialed')}
          onChange={props.onCredentialedChange}
        />
        <Checkbox
          id="hired"
          label="Been Hired"
          name="hired"
          isChecked={isCheckboxChecked('hired')}
          onChange={props.onHiredChange}
        />
      </div>
    );
  };

  const Accordion = ({ title, children }) => {
    const [openFilter, setOpenFilter] = React.useState(false);
    return (
      <div className="accordion-wrapper">
        <div
          className={`accordion-title ${openFilter ? '' : 'open'}`}
          onClick={() => setOpenFilter(!openFilter)}
        >
          {title}
        </div>
        <div className={`accordion-item ${!openFilter ? '' : 'collapsed'}`}>
          <div className="accordion-content">{children}</div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.sidebarData}>
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarDescription}>{props.title}</div>
      </div>

      <div className={`${styles.sidebarBlock}`}>
        <Accordion title="FILTER">{renderFilterCheckboxes()}</Accordion>
      </div>

      <div className={`${styles.sidebarBlock}`}>
        <Accordion title="Skills">{renderSkillsCheckboxes()}</Accordion>
      </div>

      <div className={`${styles.sidebarBlock}`}>
        <Accordion title="Professional Classification">
          {renderClassificationsCheckboxes()}
        </Accordion>
      </div>
    </div>
  );
};

export default React.memo(Filters);
