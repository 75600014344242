import { API_ENDPOINTS } from 'constants/Constants';
import { API_METHODS, DEFAULT_API_HEADER } from 'config/ApiConfig';
import { addParamsToURL, stringifyMe } from 'utils/UtilityFunctions';

export const getAllConversationsService = async (queryParams?: any) => {
  let fetchURL = API_ENDPOINTS.MESSAGES;

  if (queryParams) {
    fetchURL = `${fetchURL}?${addParamsToURL(queryParams)}`;
  }

  try {
    const response = await fetch(fetchURL, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const conversations = await response.json();
      return { conversations };
    } else {
      return { error: await response.clone().text() };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const getMessagesService = async (
  partnerId: string,
  application?: string,
) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.MESSAGES}/${partnerId}?application=${application || ''}`,
      {
        method: API_METHODS.GET,
        headers: {
          ...DEFAULT_API_HEADER,
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );

    if (response.ok) {
      const messages = await response.json();
      return { messages };
    } else {
      return { error: await response.clone().text() };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const sendMessageService = async (message: any) => {
  try {
    const response = await fetch(API_ENDPOINTS.MESSAGES, {
      method: API_METHODS.POST,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: stringifyMe(message),
    });

    if (response.ok) {
      const message = await response.json();
      return { message };
    } else {
      return { error: response.clone().text() };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const markConversationReadService = async (partnerId: string) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.MESSAGES}/${partnerId}/read`,
      {
        method: API_METHODS.PUT,
        headers: {
          ...DEFAULT_API_HEADER,
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );

    if (response.ok) {
      const markedRead = await response.json();
      return { markedRead };
    } else {
      return { error: await response.clone().text() };
    }
  } catch (err) {
    return { error: err.message };
  }
};
