import React, { Fragment, useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Container, Navbar, Nav } from 'react-bootstrap';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'components/Image/Image';
import cx from 'classnames';

// Components
import Button from '../Button/Button';

// Constants
import { ROUTES, CLOUDINARY_BASE_URL } from 'constants/Constants';

// Context
import GlobalContext from 'contexts/Global.context';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

// SCSS
import './Header.scss';

interface IHeader {
  alt?: boolean;
}

const Header = (props: IHeader) => {
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);

  const { isLoggedIn, userDetails, logout, variantDetails } =
    useContext(GlobalContext);

  const LOGO =
    variantDetails?.header_logo_url ||
    `${variantDetails?.assets_url}/header_logo.svg`;

  const LOGO_ALT = LOGO;

  const PROFILE_THUMBNAIL = `${variantDetails?.assets_url}/header_logo_thumb.svg`;

  const altClass = cx({
    alt: props.alt,
  });

  const clippedPhoto = (src: String) => {
    return `${CLOUDINARY_BASE_URL}/c_thumb,w_34,h_34,g_face,f_auto/s3/${src.substr(
      src.lastIndexOf('/') + 1,
    )}`;
  };

  return (
    <Container className={`headerWrap ${altClass}`} fluid>
      <Container className="header">
        <Navbar className="mdrNav p-0 align-items-center" expand="lg">
          <Navbar.Brand className="mdrBranding" as={Link} to={ROUTES.HOME}>
            {props.alt ? (
              <Image
                className="mainLogo"
                src={LOGO_ALT}
                title={variantDetails?.site_short_title}
              />
            ) : (
              <Image
                className="mainLogo"
                src={LOGO}
                title={variantDetails?.site_short_title}
              />
            )}
          </Navbar.Brand>
          <div className="d-xl-none d-lg-none d-md-block d-sm-block">
            {isLoggedIn ? (
              <Fragment>
                <div className="profileInfo">
                  <div className="profileImg">
                    {userDetails.photo ? (
                      <img
                        className="profilePhoto"
                        src={clippedPhoto(userDetails.photo)}
                        alt="profile"
                      />
                    ) : (
                      <Image
                        src={PROFILE_THUMBNAIL}
                        className="profilePhoto"
                        title="profile"
                      />
                    )}
                  </div>
                  <div className="proifleName">
                    <DropdownButton
                      alignRight
                      title={userDetails.user_name || ''}
                      id="dropdown-menu-align-right"
                      className="dropdownButton"
                    >
                      <Dropdown.Item
                        eventKey="option-2"
                        as={Link}
                        to={ROUTES.DASHBOARD}
                      >
                        Dashboard
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="option-2"
                        as={Link}
                        to={`${ROUTES.PROFILE}/${userDetails.slug}`}
                      >
                        View Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="option-2"
                        as={Link}
                        to={`${ROUTES.DASHBOARD}${ROUTES.EDIT_PROFILE}`}
                      >
                        Edit Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="option-3"
                        onClick={() => logout()}
                        as={Link}
                        to={ROUTES.HOME}
                      >
                        Logout
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
              </Fragment>
            ) : (
              <div className="headerButtons d-xl-none d-lg-none d-md-none d-sm-none d-none">
                {props.alt ? (
                  <Fragment>
                    <Button
                      link={ROUTES.LOGIN}
                      type="transparent white"
                      label="Login"
                    />{' '}
                    <Button
                      link={ROUTES.REGISTER}
                      type="outline"
                      label="Sign Up"
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <Link to={ROUTES.LOGIN}>
                      <Button
                        type={pathname.includes('login') ? '' : 'transparent'}
                        label="Login"
                      />
                    </Link>
                    <Link to={ROUTES.REGISTER}>
                      <Button
                        type={
                          pathname.includes('register') ? '' : 'transparent'
                        }
                        label="Sign Up"
                      />
                    </Link>
                  </Fragment>
                )}
              </div>
            )}
          </div>
          <Navbar.Toggle
            aria-controls="main-nav"
            onClick={() => setOpen(!open)}
            className={open === true ? 'active' : ''}
          />

          <Navbar.Collapse
            id="main-nav"
            className={open === true ? 'active' : ''}
          >
            <Nav className="ml-xl-4 mr-xl-auto">
              <Nav.Link
                as={Link}
                to={ROUTES.CAREERS}
                onClick={() => setOpen(!open)}
              >
                {COPY_OVERRIDES?.header?.careers || 'Careers'}
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={ROUTES.OPPORTUNITIES}
                onClick={() => setOpen(!open)}
              >
                {COPY_OVERRIDES?.header?.opportunities || 'Opportunities'}
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={ROUTES.FIND_SOMEONE}
                onClick={() => setOpen(!open)}
              >
                {COPY_OVERRIDES?.header?.post || 'Create Post'}
              </Nav.Link>
              {/* <Nav.Link
                as={Link}
                to={ROUTES.TALENT}
                onClick={() => setOpen(!open)}
              >
                Profiles
              </Nav.Link> */}
              {/* <Nav.Link
                as={Link}
                to={ROUTES.ABOUT}
                onClick={() => setOpen(!open)}
              >
                About
              </Nav.Link> */}
              {/* <Nav.Link
                as={Link}
                to={ROUTES.SUPPORT}
                onClick={() => setOpen(!open)}
              >
                Contact
              </Nav.Link> */}
              <Nav.Link
                as={Link}
                to={ROUTES.CREDENTIALS}
                onClick={() => setOpen(!open)}
              >
                Credentials
              </Nav.Link>
              <a
                href="http://beyondphysician.academy"
                onClick={() => setOpen(!open)}
                target="_blank"
                rel="noopener noreferrer"
              >
                Academy
              </a>
            </Nav>
            {isLoggedIn ? (
              <Fragment>
                <div className="profileInfo d-xl-flex d-lg-flex d-none">
                  <div className="profileImg">
                    {userDetails.photo ? (
                      <img
                        className="profilePhoto"
                        src={clippedPhoto(userDetails.photo)}
                        alt="profile"
                      />
                    ) : (
                      <Image
                        className="profilePhoto"
                        src={PROFILE_THUMBNAIL}
                        title={variantDetails?.site_short_title}
                      />
                    )}
                  </div>
                  <div className="proifleName">
                    <DropdownButton
                      alignRight
                      title={userDetails.user_name || ''}
                      id="dropdown-menu-align-right-mobile"
                      className="dropdownButton"
                    >
                      <Dropdown.Item
                        eventKey="option-2"
                        as={Link}
                        to={ROUTES.DASHBOARD}
                      >
                        Dashboard
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="option-2"
                        as={Link}
                        to={`${ROUTES.PROFILE}/${userDetails.slug}`}
                      >
                        View Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="option-2"
                        as={Link}
                        to={`${ROUTES.DASHBOARD}${ROUTES.EDIT_PROFILE}`}
                      >
                        Edit Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="option-3"
                        onClick={() => logout()}
                        as={Link}
                        to={ROUTES.HOME}
                      >
                        Logout
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
              </Fragment>
            ) : (
              <div className="headerButtons d-xl-block d-lg-block">
                {props.alt ? (
                  <Fragment>
                    <Button
                      link={ROUTES.LOGIN}
                      type="transparent white"
                      label="Login"
                    />{' '}
                    <Button
                      link={ROUTES.REGISTER}
                      type="outline"
                      label="Sign Up"
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <Link to={ROUTES.LOGIN}>
                      <Button
                        type={pathname.includes('login') ? '' : 'transparent'}
                        label="Login"
                        onClick={() => setOpen(!open)}
                      />
                    </Link>
                    <Link to={ROUTES.REGISTER}>
                      <Button
                        type={
                          pathname.includes('register') ? '' : 'transparent'
                        }
                        label="Sign Up"
                        onClick={() => setOpen(!open)}
                      />
                    </Link>
                  </Fragment>
                )}
              </div>
            )}
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </Container>
  );
};

export default React.memo(Header);
