interface Dictionary<T> {
  [key: string]: T;
}

export const APPLY_RULES: Dictionary<any> = {
  title: {
    required: true,
    minLength: 2,
    maxLength: 150,
    label: 'Title',
  },
  note: {
    required: false,
    min: 2,
    max: 10000,
    label: 'Description',
  },
  flat_fee: {
    required: true,
    label: 'Fee',
  },
  service_credential: {
    required: false,
    label: 'Service Credential',
  },
  question: {
    required: false,
    min: 2,
    max: 300,
    label: 'Question',
  },
};

export const APPLY_DEFAULTS = {
  title: '',
  note: '',
  flat_fee: '',
  hourly_fee: '',
  service_credential: null,
  schedule: [
    {
      date: '',
      delivery: '',
      is_completed: false,
    },
    {
      date: '',
      delivery: '',
      is_completed: false,
    },
  ],
  question: '',
};
