import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

// Context
import GlobalContext from 'contexts/Global.context';

//Components
import Welcome from './components/Welcome/Welcome';
import CareerMap from './components/CareerMap/CareerMap';

import COPY_OVERRIDES from 'config/CopyOverrides';

// SCSS
import styles from './About.module.scss';

const About = () => {
  const { variantDetails } = useContext(GlobalContext);
  return (
    <div className={styles.aboutPage}>
      <Helmet>
        {COPY_OVERRIDES?.titles?.about && (
          <title>{COPY_OVERRIDES?.titles?.about}</title>
        )}
        {COPY_OVERRIDES?.meta?.about && (
          <meta name="description" content={COPY_OVERRIDES.meta.about} />
        )}
      </Helmet>
      <Container className="p-0">
        <Row>
          <Col md={12}>
            <Welcome />
          </Col>
        </Row>
        {variantDetails?.site_short_title === 'MIC' && (
          <Row>
            <Col md={12}>
              <CareerMap />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default About;
