import React from 'react';
import { Redirect, Route } from 'react-router-dom';

interface Props {
  isLoggedIn: boolean;
  path: string;
  component: any;
}

export const PrivateRoute = ({ ...props }: Props) => {
  return props.isLoggedIn ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={
        '/login?destination=' +
        window.location.pathname +
        window.location.search
      }
    />
  );
};
