import React from 'react';
import { Row, Col } from 'react-bootstrap';

// SCSS
import styles from './Header.module.scss';

interface IHeader {
  title: string;
  subtitle: string;
}

const Header = (props: IHeader) => {
  return (
    <Row className={styles.pageHeader}>
      <Col xl={6} lg={6} md={12} sm={12}>
        <h1 className={styles.pageTitle}>{props.title}</h1>
      </Col>
      {/* <Col xl={6} lg={6} md={12} sm={12}>
        <div className={styles.pageSubtitle}>{props.subtitle}</div>
      </Col> */}
    </Row>
  );
};

export default React.memo(Header);
