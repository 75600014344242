import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { Container, Row, Col, Alert, InputGroup } from 'react-bootstrap';

import SVG from 'react-inlinesvg';

import Loader from 'components/Loader/Loader';
import Input from 'components/Form/Input/Input';
import Button from 'components/Button/Button';

import Keyword from 'assets/svgs/Careers/filter-keyword.svg';

// Services
import { fetchSpecificJobsService } from 'services/JobService';

// SCSS
import styles from './ActiveJobs.module.scss';
import JobCard from '../JobCard/JobCard';

interface Props {
  type?: string;
}

const ActiveJobs: React.FC<Props> = ({ type }: Props) => {
  const [searchText, setSearchText] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');
  const [jobs, setJobs] = useState<Array<any> | null>(null);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { search } = useLocation();
  const { congrats } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  // fetch all the jobs list
  const getPendingJobs = useCallback(async () => {
    setJobs(null);
    setCount(0);
    setLoading(true);
    setError('');
    const { jobs, count, error, unread } = await fetchSpecificJobsService({
      type,
      completed: false,
      keyword,
    });
    if (jobs) {
      setCount(count);
      if (jobs.length && unread.length) {
        setJobs(
          jobs.map((item) => ({
            ...item,
            unread_messages:
              unread.find((msg) => msg.application === item.application_id)
                ?.count || 0,
          })),
        );
      } else {
        setJobs(jobs);
      }
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  }, [type, keyword]);

  useEffect(() => {
    getPendingJobs();
  }, [getPendingJobs]);

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.which === 13 || event.charCode === 13) {
      setKeyword(searchText);
    }
  };

  return (
    <div className={styles.completedJob}>
      <Container className="p-0">
        {congrats && (
          <Row>
            <Col>
              <div className={styles.congrats}>
                Congrats. Your payment has been approved and your job has now
                begun!
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <div className={styles.sectionHead}>
              <div className={styles.secHeading}>Current Jobs</div>
              <div className={styles.totalJobs}>{count} Jobs</div>
            </div>
          </Col>
        </Row>
        <div className={styles.filterForm}>
          <Container>
            <Row>
              <InputGroup className={styles.filterGroup}>
                <InputGroup.Prepend
                  className={`${styles.keywordPrepend} ${styles.filterPrepend}`}
                >
                  <InputGroup.Text>
                    <SVG
                      baseURL="/"
                      cacheRequests={true}
                      className="keyword-filter"
                      description="Keyword Filter Icon"
                      loader={<span>Loading...</span>}
                      src={Keyword}
                    />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Input
                  class={`${styles.keywordFilter} ${styles.inputFilter}`}
                  placeholder="Projects, Clients"
                  value={searchText}
                  onChange={handleSearchTextChange}
                  onKeyPress={handleKeyPress}
                />
                <Button
                  type={`branding ${styles.submitFilter}`}
                  label="Search"
                  onClick={() => setKeyword(searchText)}
                />
              </InputGroup>
            </Row>
          </Container>
        </div>
        {loading ? <Loader /> : null}
        {!loading && error ? <Alert variant="danger">{error}</Alert> : null}
        {jobs && jobs.length === 0 ? (
          <Alert variant="primary">No active jobs found</Alert>
        ) : null}
        {!loading &&
          jobs?.map((job) => (
            <JobCard key={job.id} status="active" job={job} />
          ))}
      </Container>
    </div>
  );
};

export default ActiveJobs;
