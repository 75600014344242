import React from 'react';
import { User } from 'interfaces/User.types';

interface wrapInterface {
  isLoggedIn: boolean;
  isLoading: boolean;
  logout: () => void;
  userDetails: User | any;
  setAuth: (token: string) => void;
  setLoader: (loading: boolean) => void;
  variantDetails: any;
}

const wrap: wrapInterface = {
  isLoggedIn: false,
  isLoading: false,
  logout: () => {},
  userDetails: {},
  setAuth: () => {},
  setLoader: () => {},
  variantDetails: null,
};

export default React.createContext(wrap);
