import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import Button from 'components/Button/Button';
import Select from 'components/Form/Select/Select';
import TextArea from 'components/Form/TextArea/TextArea';
import SVG from 'react-inlinesvg';
import History from 'utils/History';

// Interfaces
import { Talent, MyJob } from 'interfaces/User.types';
import { Job } from 'interfaces/Job.types';
// import { Invitation, DEFAULT_INVITATION } from './InvitationConfig';

// Services
import { inviteUserService } from 'services/UserService';
import { createJobService } from 'services/JobService';

import STATES from 'constants/States';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

// SVGs
import sendIcon from 'assets/svgs/sendIcon.svg';
import closeIcon from 'assets/svgs/close-icon.svg';

// SCSS
import styles from './InviteModal.module.scss';
import Input from 'components/Form/Input/Input';
import { ROUTES } from 'constants/Constants';

const InviteModal = (props: {
  user: Talent;
  jobs: MyJob[];
  showInvite: boolean;
  setShowInvite: any;
  serviceDetails?: Job;
}) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [showJobFields, setShowJobFields] = useState<boolean>(false);
  const [job, setJob] = useState<string>('');
  const [jobTitle, setJobTitle] = useState<string>('');
  const [jobDescription, setJobDescription] = useState<string>('');
  const [jobCity, setJobCity] = useState<string>('');
  const [jobState, setJobState] = useState<string>('');
  const [jobZip, setJobZip] = useState<string>('');

  const { user, jobs, setShowInvite, showInvite, serviceDetails } = props;

  const resetJobFields = () => {
    setShowJobFields(false);
    setJobTitle('');
    setJobDescription('');
    setJobCity('');
    setJobState('');
    setJobZip('');
  };

  const closeInvitePopup = () => {
    setSuccess(false);
    setError('');
    setJob('');
    setMessage('');
    setShowInvite(false);
    resetJobFields();
  };

  const createJobInvite = async (jobId: string) => {
    const { invite, error } = await inviteUserService({
      job: jobId,
      user: user.id,
      message: message || '',
    });
    if (error) {
      setError(error || 'Something went wrong');
    } else if (invite) {
      resetJobFields();
      setSuccess(true);
      setJob('');
      setMessage('');
    }
    setSubmitted(false);
    setLoading(false);
  };

  const inviteUser = async () => {
    setSubmitted(true);
    setSuccess(false);
    setError('');
    if (job === 'inherited') {
      History.push({
        pathname: `${ROUTES.FIND_SOMEONE}`,
        state: {
          service: serviceDetails,
          invite_message: message,
          send_invite: true,
          invitee_id: user.id,
          hash: 'page_bottom',
        },
      });
    } else {
      if (!job || (showJobFields && !jobTitle)) {
        return;
      }
      setLoading(true);
      if (job === 'new') {
        const careerFormData = {
          is_private: 'private',
          title: jobTitle,
          opportunity_description: jobDescription,
          type: 'career',
          category: 'Other',
          fee: null,
          location: 'TBD',
          company_name: 'NA',
          company_logo: '',
          deadline: '',
          zip: jobZip,
          state: jobState,
          city: jobCity,
          hiring_description: '',
          apply_through: 'site',
          external_url: '',
          reference_links: null,
          is_featured: false,
          industry: '',
          fellowship: '',
          experience: '',
          ideal_applicant: '',
          other_ideal_applicant: '',
          speciality: '',
          credential_name: '',
          credential_link: '',
          licensed_state: '',
          min_pay: null,
          max_pay: null,
        };
        const response = await createJobService(careerFormData);
        if (!response.ok) {
          const error = await response.clone().text();
          setError(error);
          setSubmitted(false);
          setLoading(false);
        } else {
          const { id } = await response.json();
          createJobInvite(id);
        }
      } else {
        createJobInvite(job);
      }
    }
  };

  useEffect(() => {
    if (job === 'new') {
      setShowJobFields(true);
    } else {
      setShowJobFields(false);
    }
  }, [job]);

  return (
    <Fragment>
      <Modal
        show={showInvite}
        onHide={() => closeInvitePopup()}
        className={styles.inviteUserModal}
      >
        <div className={styles.closeBtn} onClick={() => closeInvitePopup()}>
          Close{' '}
          <span>
            <SVG
              baseURL="/"
              className={styles.closeIcon}
              description="close"
              loader={<span>Loading...</span>}
              src={closeIcon}
            />
          </span>
        </div>
        <Modal.Header>
          <Modal.Title>Invite User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.modalBody}>
            {user && (
              <div className={styles.userInfo}>
                <div className={styles.imgSec}>
                  <img
                    src={user.photo || 'https://placehold.co/200x200'}
                    alt={user.first_name || user.user_name}
                  />
                </div>
                <div className={styles.userDetail}>
                  <div className={styles.userName}>
                    {user.first_name || user.user_name} {user.last_name || ''}
                  </div>
                  <div className={styles.userAdd}>
                    {user.city}, {user.state}
                  </div>
                </div>
              </div>
            )}

            <div className={styles.authInput}>
              <div className={styles.authLabel}>
                Select The {COPY_OVERRIDES?.post?.career_type || 'Job'} Or
                Create A New One{' '}
                <span style={{ display: 'block' }}>
                  (you can only send an invite once per{' '}
                  {COPY_OVERRIDES?.post?.career_type || 'job'})
                  <span className={styles.requiredField}> *</span>
                </span>
              </div>
              <Select
                id="category"
                selectItems={[
                  ...jobs.map((item) => ({
                    value: item.id,
                    label: item.title,
                  })),
                  {
                    value: 'inherited',
                    label: 'Or Create Gig To Hire This Person',
                  },
                  { value: 'new', label: 'Or Create New' },
                ]}
                onChange={(e) => setJob(e.target.value)}
                name="job"
                value={job}
              />
              {submitted && !job && (
                <div className={styles.error}>
                  Please select a {COPY_OVERRIDES?.post?.career_type || 'job'}
                </div>
              )}
            </div>
            {showJobFields && (
              <>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    {COPY_OVERRIDES?.post?.career_type || 'job'} Title
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Input
                    name="jobTitle"
                    onChange={(e) => setJobTitle(e.target.value)}
                    value={jobTitle}
                  />
                  {submitted && !jobTitle && (
                    <div className={styles.error}>Please enter the title</div>
                  )}
                </div>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    {COPY_OVERRIDES?.post?.career_type || 'job'} Description
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <TextArea
                    name="jobDescription"
                    onChange={(e) => setJobDescription(e.target.value)}
                    value={jobDescription}
                  />
                  {submitted && !jobDescription && (
                    <div className={styles.error}>
                      Please enter the description
                    </div>
                  )}
                </div>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    City
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Input
                    type="text"
                    onChange={(e) => setJobCity(e.target.value)}
                    value={jobCity}
                    name="jobCity"
                  />
                  {submitted && !jobCity && (
                    <div className={styles.error}>Please enter the city</div>
                  )}
                </div>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    State
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Select
                    id="state"
                    selectItems={STATES}
                    onChange={(e) => setJobState(e.target.value)}
                    name="jobState"
                    value={jobState}
                  />
                  {submitted && !jobState && (
                    <div className={styles.error}>Please select the state</div>
                  )}
                </div>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Zip
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Input
                    type="text"
                    onChange={(e) => setJobZip(e.target.value)}
                    value={jobZip}
                    name="jobZip"
                  />
                  {submitted && !jobState && (
                    <div className={styles.error}>Please enter the Zipcode</div>
                  )}
                </div>
              </>
            )}
            <div className={styles.authInput}>
              <div className={styles.authLabel}>Send Custom Message</div>
              <TextArea
                name="message"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
              <div className={styles.notice}>
                The price displayed does not include fees.{' '}
                <a
                  href="http://beyondphysician.org/fees"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More
                </a>
              </div>
            </div>
            <div className={styles.modalNoti}>
              <span>
                <SVG
                  baseURL="/"
                  className={styles.notiIcon}
                  description="Send Invite"
                  loader={<span>Loading...</span>}
                  src={sendIcon}
                />
              </span>
              <span>
                This user will receive an email with the details of the job
                along with the above message.
              </span>
            </div>
            {error && (
              <div>
                <Alert variant="danger">
                  {error.includes('sandbox')
                    ? 'User is not available in recipients list.'
                    : error}
                </Alert>
              </div>
            )}
            {success && (
              <div>
                <Alert variant="primary">Invitation sent successfully!</Alert>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={inviteUser}
            disabled={loading || !user}
            label={
              loading
                ? 'Inviting...'
                : job === 'inherited'
                  ? 'Create Post'
                  : 'Submit Invitation'
            }
            type="transparent w-100 branding lgBtn md-font"
          />
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default React.memo(InviteModal);
