import React, { useCallback, useContext, useEffect, useState } from 'react';

import { withRouter } from 'react-router-dom';

import moment from 'moment';

import Header from './components/Header/Header';

// Context
import GlobalContext from './contexts/Global.context';

// Components
import ErrorBoundary from './ErrorBoundary';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';

// Routes
import AppRoutes from './AppRoutes';

// Services
import {
  authenticateLoginService,
  updateLastSeenService,
} from 'services/CommonService';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

// Styles
import './styles/mdr-base.scss';

declare global {
  interface Window {
    analytics: any;
  }
}

export const App = withRouter(({ location }) => {
  const [updating, setUpdating] = useState<boolean>(false);
  const {
    isLoggedIn,
    logout,
    userDetails: { id },
    variantDetails,
  } = useContext(GlobalContext);

  const faviconElement = document.getElementById('favicon') as HTMLLinkElement;
  faviconElement.href = `${variantDetails?.assets_url}/favicon.ico`;

  const path = location.pathname;
  const alternateHeader = path !== '/career/id';

  const updateLastSeen = useCallback(async () => {
    if (
      isLoggedIn &&
      id &&
      !updating &&
      (!localStorage.getItem(`${id}_last_seen`) ||
        moment()
          .utc()
          .diff(
            moment.utc(localStorage.getItem(`${id}_last_seen`)),
            'minutes',
          ) > 5)
    ) {
      setUpdating(true);
      const response = await updateLastSeenService(id);
      if (response.ok) {
        localStorage.setItem(`${id}_last_seen`, moment().utc().format());
      }
      setUpdating(false);
    }
  }, [isLoggedIn, id, updating]);

  updateLastSeen();

  useEffect(() => {
    const authenticateLogin = async () => {
      const response = await authenticateLoginService();
      if (!response.ok) {
        const { status } = response;
        if (status === 401) {
          logout();
        }
      }
    };
    if (isLoggedIn) {
      authenticateLogin();
    }
  }, [isLoggedIn, logout]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [path]);

  return (
    <ErrorBoundary>
      <Container className="p-0" fluid>
        <Helmet>
          <title>{variantDetails?.site_title}</title>
          <meta
            name="description"
            content={
              COPY_OVERRIDES?.meta?.description ||
              'Welcome to your music professional network.'
            }
          />
        </Helmet>
        {alternateHeader ? <Header /> : <Header alt />}
        <AppRoutes />
      </Container>
    </ErrorBoundary>
  );
});
