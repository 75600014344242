import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';

import { Link } from 'react-router-dom';

import { Row, Col, Alert } from 'react-bootstrap';

import orderBy from 'lodash/orderBy';

import moment from 'moment';

// Components
import Applicants from '../../../JobBoard/Applicants/Applicants';
import Loader from 'components/Loader/Loader';

// Context
import GlobalContext from 'contexts/Global.context';

import History from 'utils/History';

// Constants
import { ROUTES, JOB_TYPE } from 'constants/Constants';

// Interfaces
import { Application } from 'interfaces/Job.types';

// Services
import {
  fetchApplicantsService,
  updateApplicantsStatusService,
} from 'services/JobService';

// Styles
import styles from './CareersPendingApplicants.module.scss';

interface Props {
  type: string;
}

const CareersPendingApplicants = ({ type }: Props) => {
  // fetch Applicants state
  const [applicants, setApplicants] = useState<Array<any> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const {
    userDetails: { first_name, user_name }, // sender name
  } = useContext(GlobalContext);

  // fetch all the applicants list
  const getPendingApplicants = useCallback(async () => {
    setApplicants(null);
    setLoading(true);
    setError('');
    const { applicants, error } = await fetchApplicantsService({ type });
    if (applicants) {
      if (applicants.length) {
        const copy = applicants.map((job) => {
          if (job.applications && job.applications.length) {
            return {
              ...job,
              applications: orderBy(
                job.applications,
                ['created_at', 'top_candidate'],
                ['desc', 'desc'],
              ),
            };
          }
          return job;
        });
        setApplicants(copy);
      }
      setApplicants(applicants);
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  }, [type]);

  const getPay = (jobDetails) => {
    if (jobDetails?.min_pay || jobDetails?.max_pay) {
      return jobDetails.min_pay || jobDetails.max_pay;
    } else if (jobDetails?.min_pay === 0 && jobDetails?.max_pay === 0) {
      return 0;
    }
    return 0;
  };

  const callAnalytics = (jobId: string, applicantId: string) => {
    const jobApplication = applicants.filter((app) => app.job_id === jobId)[0];

    const applicant = jobApplication.applications.filter(
      (applicant) => applicant.application_id === applicantId,
    )[0];

    window.analytics.track('Candidate hired, Completed Job', {
      jobTitle: jobApplication.title,
      companyName: jobApplication.company_name,
      hiredCandidate: applicant.email,
    });
  };

  const toggleApplicantNotInterested = async (
    applicationId: string,
    isInterested: boolean,
  ) => {
    const { isUpdated } = await updateApplicantsStatusService(applicationId, {
      is_interested: isInterested,
    });

    if (isUpdated) {
      getPendingApplicants();
    }
  };

  // const toggleApplicantAsTopCandidate = async (
  //   applicationId: string,
  //   isTopCandidate: boolean
  // ) => {
  //   const { isUpdated } = await updateApplicantsStatusService(applicationId, {
  //     top_candidate: isTopCandidate,
  //   });

  //   if (isUpdated) {
  //     getPendingApplicants();
  //   }
  // };

  const toggleApplicantHired = async (
    jobId: string,
    applicationId: string,
    isHired: boolean,
    directHireObject?: any,
  ) => {
    const obj: any = {
      is_hired: isHired,
    };
    if (directHireObject) {
      obj.offer_id = directHireObject.offerId;
      obj.receiver = directHireObject.applicantId;
      obj.message = `${first_name || user_name} accepted the offer!`;
    }
    const { isUpdated } = await updateApplicantsStatusService(
      applicationId,
      obj,
    );

    if (isUpdated) {
      if (directHireObject) {
        History.push(`${ROUTES.DASHBOARD}${ROUTES.ACTIVE_JOBS}?congrats=true`);
      } else {
        callAnalytics(jobId, applicationId);
        getPendingApplicants();
      }
    }
  };

  useEffect(() => {
    getPendingApplicants();
  }, [getPendingApplicants]);

  const getTimeLeft = (deadline) => {
    if (deadline && /\d{4}-\d{2}-\d{2}/.test(deadline)) {
      return `${moment(deadline).diff(moment(), 'days')} days left`;
    }
    return deadline;
  };

  return (
    <Fragment>
      <div className={styles.dashboardSection}>
        <Row>
          <Col>
            <Row>
              <Col>
                <div className={styles.sectionHeading}>Pending Applicants</div>
              </Col>
            </Row>
            <div className={styles.applicantsAndApplications}>
              {loading ? <Loader /> : null}
              {!applicants && error ? (
                <Alert variant="danger">{error}</Alert>
              ) : null}
              {applicants && applicants.length === 0 ? (
                <Alert variant="primary">No applicants found</Alert>
              ) : null}
              {applicants &&
                applicants.length > 0 &&
                applicants.map((applicant) => (
                  <div key={applicant.job_id}>
                    <Row>
                      <Col>
                        <div className={styles.appPost}>
                          <Link
                            to={
                              type === JOB_TYPE.career
                                ? `${ROUTES.CAREER}/${applicant.job_id}`
                                : `${ROUTES.OPPORTUNITY}/${applicant.job_id}`
                            }
                          >
                            <div className={styles.appPostName}>
                              {applicant.title}
                            </div>
                          </Link>

                          <div className={styles.appPostDuration}>
                            {`${getTimeLeft(applicant.deadline)} `} |{' '}
                            {applicant.applicants} applicants
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {applicant.applications &&
                          applicant.applications.length > 0 &&
                          applicant.applications
                            .filter((item) => item.is_interested === true)
                            .map((application: Application) => (
                              <Applicants
                                {...application}
                                key={application.application_id}
                                jobId={applicant.job_id}
                                pay={getPay(applicant)}
                                offerId={application.offer_id}
                                note={application.note}
                                applicationId={application.application_id}
                                userId={application.user_id}
                                image={
                                  application.photo ||
                                  'https://mic-react.s3.amazonaws.com/place_70x70.png'
                                }
                                title={
                                  application.title ||
                                  `${application.first_name} ${application.last_name}`
                                }
                                amount={application.offer}
                                owner={applicant.job_owner}
                                type={type}
                                unread={application.unread_messages || 0}
                                markNotInterested={toggleApplicantNotInterested}
                                markAsHired={toggleApplicantHired}
                              />
                            ))}
                        {!applicants && error ? (
                          <Alert variant="danger">{error}</Alert>
                        ) : null}
                      </Col>
                    </Row>
                    {applicant.applications &&
                      applicant.applications.length > 0 &&
                      applicant.applications.filter(
                        (item) => item.is_interested === false,
                      ).length > 0 && (
                        <>
                          <Row>
                            <Col>
                              <div
                                className={`${styles.sectionHeading} ${styles.mt50}`}
                              >
                                Not Interested In
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {applicant.applications
                                .filter((item) => item.is_interested === false)
                                .map((application: Application) => (
                                  <Applicants
                                    {...application}
                                    key={application.application_id}
                                    jobId={applicant.job_id}
                                    offerId={application.offer_id}
                                    pay={getPay(applicant)}
                                    note={application.note}
                                    applicationId={application.application_id}
                                    userId={application.user_id}
                                    image={
                                      application.photo ||
                                      'https://mic-react.s3.amazonaws.com/place_70x70.png'
                                    }
                                    title={
                                      application.title ||
                                      `${application.first_name} ${application.last_name}`
                                    }
                                    amount={application.offer}
                                    owner={applicant.job_owner}
                                    type={type}
                                    markNotInterested={
                                      toggleApplicantNotInterested
                                    }
                                    markAsHired={toggleApplicantHired}
                                  />
                                ))}
                            </Col>
                          </Row>
                        </>
                      )}
                  </div>
                ))}
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default CareersPendingApplicants;
