import React, { useContext, useEffect, useCallback, useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Components
import { Container, Row, Col } from 'react-bootstrap';

// Page Components
import DashboardMenu from './components/DashboardMenu/DashboardMenu';
import CareersApplied from './Careers/CareersApplied/CareersApplied';
import CareersPosted from './Careers/CareersPosted/CareersPosted';
import CompletedJobs from './Careers/CompletedJobs/CompletedJobs';
import PendingInvites from './Careers/PendingInvites/PendingInvites';
import SentInvites from './Careers/SentInvites/SentInvites';
import ActiveJobs from './Careers/ActiveJobs/ActiveJobs';
/* import ArchivedJobs from './Careers/ArchivedJobs/ArchivedJobs'; */
import CareersPendingApplicants from './Careers/CareersPendingApplicants/CareersPendingApplicants';
import Messages from './MyInformation/Messages/Messages/Messages';
import MessageDetail from './MyInformation/Messages/MessageDetail/MessageDetail';
import EditProfile from './MyInformation/EditProfile/EditProfile';
import Resume from './MyInformation/Resume/Resume';
import EditAccountInfo from './MyInformation/EditAccountInfo/EditAccountInfo';

// Context
import GlobalContext from 'contexts/Global.context';

// Services
import { fetchApplicantProfileService } from 'services/UserService';

// Constants
import { ROUTES, JOB_TYPE } from 'constants/Constants';

// Styles
import styles from './Dashboard.module.scss';

const Dashboard = () => {
  const { url, path } = useRouteMatch();
  const [profile, setProfile] = useState(null);

  const {
    userDetails: { email, user_name, slug },
    variantDetails,
  } = useContext(GlobalContext);

  const fetchUserProfile = useCallback(async () => {
    if (slug) {
      const { profile } = await fetchApplicantProfileService(slug);
      if (profile) {
        setProfile(profile);
      }
    }
  }, [slug]);

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  return (
    <Container className={styles.dashboardWrapper} fluid>
      <Helmet>
        <title>Dashboard - {variantDetails?.site_title}</title>
      </Helmet>
      <Row>
        <Col xs={12}>
          <Container className={styles.dashboardInner}>
            <Row>
              <Col xs={12}>
                <h1>Dashboard</h1>
                <div className={styles.userName}>
                  Welcome, <span>{user_name}</span>
                </div>
                <div className={styles.emailId}>{email}</div>
              </Col>
            </Row>
            <Row className={styles.dashboardContent}>
              <Col xl={3} lg={3} md={12} sm={12}>
                <DashboardMenu url={url} />
              </Col>
              <Col xl={9} lg={9} md={12} sm={12}>
                <Switch>
                  <Route path={`${path}${ROUTES.CAREERS_APPLIED}`}>
                    <CareersApplied type={JOB_TYPE.career} />
                  </Route>
                  <Route path={`${path}${ROUTES.CAREERS_POSTED}`}>
                    <CareersPosted type={JOB_TYPE.career} />
                  </Route>
                  {/* <Route path={`${path}${ROUTES.ARCHIVED_CAREERS}`}>
                    <ArchivedJobs type={JOB_TYPE.career} />
                  </Route> */}
                  <Route path={`${path}${ROUTES.CAREERS_PENDING_APPLICANTS}`}>
                    <CareersPendingApplicants type={JOB_TYPE.career} />
                  </Route>
                  <Route path={`${path}${ROUTES.OPPORTUNITIES_APPLIED}`}>
                    <CareersApplied type={JOB_TYPE.opportunity} />
                  </Route>
                  <Route path={`${path}${ROUTES.OPPORTUNITIES_POSTED}`}>
                    <CareersPosted type={JOB_TYPE.opportunity} />
                  </Route>
                  {/* <Route path={`${path}${ROUTES.ARCHIVED_OPPORTUNITIES}`}>
                    <ArchivedJobs type={JOB_TYPE.opportunity} />
                  </Route> */}
                  <Route
                    path={`${path}${ROUTES.OPPORTUNITIES_PENDING_APPLICANTS}`}
                  >
                    <CareersPendingApplicants type={JOB_TYPE.opportunity} />
                  </Route>
                  <Route
                    exact
                    path={`${path}${ROUTES.MY_MESSAGES}`}
                    component={Messages}
                  ></Route>
                  <Route
                    exact
                    path={`${path}${ROUTES.MY_MESSAGES}/:partnerId`}
                    component={MessageDetail}
                  ></Route>
                  <Route
                    exact
                    path={`${path}${ROUTES.PENDING_INVITES}`}
                    component={PendingInvites}
                  ></Route>
                  <Route
                    exact
                    path={`${path}${ROUTES.SENT_INVITES}`}
                    component={SentInvites}
                  ></Route>
                  <Route
                    path={`${path}${ROUTES.EDIT_PROFILE}`}
                    component={EditProfile}
                  ></Route>
                  <Route path={`${path}${ROUTES.MY_RESUME}`}>
                    <Resume profile={profile} refetch={fetchUserProfile} />
                  </Route>
                  <Route
                    path={`${path}${ROUTES.EDIT_ACCOUNT_INFO}`}
                    component={EditAccountInfo}
                  ></Route>
                  <Route path={`${path}${ROUTES.COMPLETED_JOBS}`}>
                    <CompletedJobs type={JOB_TYPE.career} />
                  </Route>
                  <Route path={`${path}${ROUTES.ACTIVE_JOBS}`}>
                    <ActiveJobs type={JOB_TYPE.career} />
                  </Route>
                  <Route>
                    <CareersApplied type={JOB_TYPE.career} />
                  </Route>
                </Switch>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      {/* <h2>Welcome to Dashboard!!</h2> */}
    </Container>
  );
};

export default Dashboard;
