interface Dictionary<T> {
  [key: string]: T;
}

const FIELD_LABELS: Dictionary<string | number> = {
  title: 'Title',
  category: 'Category',
  location: 'Location',
  companyName: 'Company name',
  companyLogo: 'Company logo',
  deadline: 'Due Date',
  zip: 'Zip',
  state: 'State',
  city: 'City',
  opportunityDescription: 'Description',
  hiringDescription: 'Hiring Description',
  applyThrough: 'Apply through',
  externalUrl: 'External URL',
  fee: 'Fee',
  referenceLinks: 'Reference link',
  industry: 'Industry',
  idealApplicant: 'Ideal Applicant',
  otherIdealApplicant: 'Other Ideal Applicant',
  experience: 'Experience',
  speciality: 'Speciality',
  fellowship: 'Fellowship',
  fellowshipSub: 'Fellowship Subspecialty',
  licensedState: 'Licensed state',
  minPay: 'Minimum Pay',
  maxPay: 'Maximum Pay',
  isPrivate: 'Is Private',
  credentialName: 'Credential Name',
  credentialLink: 'Credential Link',
};

export default FIELD_LABELS;
