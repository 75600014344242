import React, { useContext, useState } from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share';

// Components
import Button from '../../../../../components/Button/Button';
import History from 'utils/History';

// Context
import GlobalContext from 'contexts/Global.context';

import { FREE_WORK_CREDENTIALS } from 'constants/Constants';

// SCSS
import styles from './Content.module.scss';

// Services
import { reportJobService } from 'services/JobService';

//SVGs
import Badge from 'assets/svgs/certificateBadge.svg';
interface Props {
  jobDescription?: string;
  aboutTheLabel?: string;
  postedBy?: string;
  credentialName?: string;
  credentialLink?: string;
  jobId: string;
  title: string;
}

const Content: React.FC<Props> = ({
  jobDescription,
  aboutTheLabel,
  credentialName,
  credentialLink,
  jobId,
  title,
}: Props) => {
  const [reported, setReported] = useState<boolean>(false);

  const { isLoggedIn } = useContext(GlobalContext);

  const reportJob = async () => {
    if (isLoggedIn) {
      window.analytics.track('Report Job', {
        jobId,
      });
      setReported(true);
      await reportJobService(jobId, window.location.pathname);
    } else {
      History.push('/login?destination=' + window.location.pathname);
    }
  };

  return (
    <div className={styles.resultContent}>
      <div className={styles.resultAbout}>
        <Button
          disabled={reported}
          label={!reported ? 'Report Posting' : 'Posting Has Been Reported'}
          type="outline-gray noRadius"
          onClick={reportJob}
        />
        <div className={styles.resultAboutLabel}>Project Description</div>
        <div className={styles.desc}>
          <pre>{jobDescription}</pre>
        </div>
      </div>

      <div className={styles.resultAbout}>
        <div className={styles.resultAboutLabel}>Client Description</div>
        <div className={styles.desc}>
          <pre>{aboutTheLabel}</pre>
        </div>
      </div>

      {credentialName && (
        <div className={styles.resultAbout}>
          <div className={styles.resultAboutLabel}>Service Credential</div>
          <div className={styles.credentialWrap}>
            <div className={styles.credentialBadge}>
              <img src={Badge} className={styles.credentialBadgeSvg} alt="" />
            </div>
            <div className={styles.credentialData}>
              <div className={styles.credentialName}>{credentialName}</div>
              {credentialLink && (
                <div className={styles.credentialLink}>
                  <a
                    href={credentialLink}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    View Service Credential
                  </a>
                </div>
              )}
            </div>
          </div>
          <p className={styles.freeCred}>
            Credentials are free to earn and easy to request.{' '}
            <a
              href={FREE_WORK_CREDENTIALS}
              target="_blank"
              rel="noreferrer noopener"
            >
              Click here
            </a>{' '}
            to get started.
          </p>
        </div>
      )}

      <div className={styles.resultAbout}>
        <div className={styles.resultAboutLabel}>Share</div>
        <div className={styles.desc}>
          <FacebookShareButton
            className={styles.shareButton}
            url={window.location.href}
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton
            url={window.location.href}
            title={title}
            className={styles.shareButton}
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <LinkedinShareButton
            url={window.location.href}
            title={title}
            className={styles.shareButton}
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Content);
