import React from 'react';
import ReactAutocomplete from 'react-autosuggest';
import styles from './AutoComplete.module.scss';

export interface SelectItem {
  name: string;
}

interface Props {
  name: string;
  value?: string;
  items: Array<SelectItem>;
  onChange: (event: any) => void;
}

const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

const AutoComplete: React.FC<Props> = (props: Props) => {
  const [suggestions, setSuggestions] = React.useState<SelectItem[]>(
    props.items,
  );
  const [input, setInput] = React.useState<string>(props.value || '');

  React.useEffect(() => {
    if (props.value && input !== props.value) {
      setInput(props.value);
    }
  }, [props.value]);

  const getSuggestions = (value) => {
    const escapedValue = escapeRegexCharacters(value.trim());
    const regex = new RegExp('^' + escapedValue, 'i');

    return props.items.filter((language) => regex.test(language.name));
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChange = (event, { newValue }) => {
    setInput(newValue);
    props.onChange({
      target: { name: props.name, value: newValue },
    });
  };

  const onSuggestionSelected = (event, { suggestionValue }) => {
    props.onChange({
      target: { name: props.name, value: suggestionValue },
    });
  };

  return (
    <ReactAutocomplete
      getSuggestionValue={(item) => item.name}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      shouldRenderSuggestions={() => true}
      suggestions={suggestions || []}
      inputProps={{
        className: styles.autoCompleteInput,
        value: input,
        onChange: onChange,
      }}
      renderSuggestion={(item) => <div>{item.name}</div>}
    />
  );
};

export default React.memo(AutoComplete);
