import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';

import { Link, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import SVG from 'react-inlinesvg';
import socketIOClient from 'socket.io-client';
import { Row, Col, Alert, Modal } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import qs from 'qs';
import {
  //faFile,
  faFileWord,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowDown } from '@fortawesome/free-solid-svg-icons';

// SVGs
import PDF from 'assets/svgs/pdf.svg';
import Pay from 'assets/svgs/payIcon.svg';
import closeIcon from 'assets/svgs/close-icon.svg';
import Auth from 'assets/svgs/auth.svg';

// Components
import Button from 'components/Button/Button';
import Input from 'components/Form/Input/Input';
import FileUpload from 'components/FileUpload/FileUpload';
import Checkbox from 'components/Form/Checkbox/Checkbox';
import Loader from 'components/Loader/Loader';
import PaymentForm from 'components/PaymentForm/PaymentForm';

import History from 'utils/History';

// Context
import GlobalContext from 'contexts/Global.context';

// Constants
import { ROUTES, BASE_URL, JOB_TYPE, APP_CONSTANTS } from 'constants/Constants';

// Interfaces
import { Message } from 'interfaces/Message.types';
import { ApplicationDetails } from 'interfaces/Job.types';

// Services
import {
  getMessagesService,
  sendMessageService,
  markConversationReadService,
} from 'services/MessageService';
import { reportUserService } from 'services/UserService';
import { getUploadURLService } from 'services/CommonService';
import {
  fetchApplicationService,
  updateApplicantsStatusService,
  updateOfferService,
} from 'services/JobService';

// Styles
import styles from './MessageDetail.module.scss';

const { platformFee } = APP_CONSTANTS;

const MessageDetail = () => {
  const { partnerId } = useParams();

  const {
    state: { read = false, userName = '', photo = '' } = {}, // partner name and photo
    search,
  } = useLocation();

  const {
    applicationId: applicationIdQuery,
    type,
    showPay: showPayMessage,
  } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const applicationId = applicationIdQuery?.toString() || '';

  const {
    userDetails: { id: loggedInUserId, first_name, user_name }, // sender name
  } = useContext(GlobalContext);

  // messages state
  const [loading, setLoading] = useState<boolean>(false);
  const [showPay, setShowPay] = useState<boolean>(false);
  const [newAttachment, setNewAttachment] = useState<File | null>(null);
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const [beforePay, setBeforePay] = useState<boolean>(
    !!showPayMessage || false,
  );
  const [isCounter, setIsCounter] = useState<boolean>(false);
  const [counterAmount, setCounterAmount] = useState<any>('');
  const [messages, setMessages] = useState<Array<Message> | null>(null);
  const [error, setError] = useState<string>('');

  const [messageText, setMessageText] = useState<string>('');
  const [sending, setSending] = useState<boolean>(false);

  const [reported, setReported] = useState<boolean>(false);
  const [application, setApplication] = useState<ApplicationDetails>(null);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const reportUser = async () => {
    if (loggedInUserId) {
      window.analytics.track('Report User', {
        partnerId,
      });
      setReported(true);
      await reportUserService(partnerId);
    } else {
      History.push('/login?destination=' + window.location.pathname);
    }
  };

  const onEditorStateChange: Function = (editorStateParam) => {
    setEditorState(editorStateParam);
    setMessageText(
      draftToHtml(convertToRaw(editorStateParam.getCurrentContent())),
    );
  };

  useEffect(() => {
    if (messages?.length && !application && !applicationId) {
      const copy = JSON.parse(JSON.stringify(messages));
      const appMessage = copy.reverse().find((item) => !!item.application);
      if (appMessage) {
        History.push(
          `${ROUTES.DASHBOARD}${ROUTES.MY_MESSAGES}/${partnerId}?applicationId=${appMessage.application}`,
        );
      }
    }
  }, [messages, application, applicationId, partnerId]);

  // fetch user chat
  const fetchMessages = useCallback(async () => {
    setLoading(true);
    setError('');
    const { messages, error } = await getMessagesService(
      partnerId,
      applicationId,
    );
    if (messages) {
      setMessages(messages);
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  }, [partnerId, applicationId]);

  const fetchApplicationDetails = useCallback(async () => {
    setLoading(true);
    setError('');
    const { application, error } = await fetchApplicationService(applicationId);
    if (application) {
      setApplication(application);
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  }, [applicationId]);

  // send messages
  const sendMessage = async () => {
    setSending(true);
    if (messageText || (counterAmount !== '' && isCounter)) {
      const obj = {
        receiver: partnerId,
      };

      if (messageText) {
        obj['body'] = messageText;
      }

      if (applicationId) {
        obj['application'] = applicationId;
      }

      if (counterAmount !== '' && isCounter) {
        obj['offer'] = parseFloat(counterAmount);
        obj['offerMessage'] = `${
          first_name || user_name
        } declined your offer and made a counter offer of $${counterAmount}`;
      }
      const { message } = await sendMessageService(obj);

      if (message) {
        setMessageText('');
        setEditorState(EditorState.createEmpty());
        setCounterAmount('');
        setIsCounter(false);
      }
    }
    setSending(false);
  };

  // mark conversation read
  const markConversationRead = useCallback(async () => {
    await markConversationReadService(partnerId);
  }, [partnerId]);

  useEffect(() => {
    fetchMessages();
    if (applicationId) {
      fetchApplicationDetails();
    }
    if (!read) {
      markConversationRead();
    }
  }, [
    fetchMessages,
    markConversationRead,
    read,
    applicationId,
    fetchApplicationDetails,
  ]);

  useEffect(() => {
    const socket = socketIOClient(BASE_URL);
    socket.emit('join', loggedInUserId);
    socket.on('message', (data) => {
      if (data.sender === loggedInUserId || data.sender === partnerId) {
        const copy = messages ? [...messages, data] : [data];
        setMessages(copy);
        markConversationRead();
      }
    });

    socket.on('offer', (data) => {
      if (
        (data.user === partnerId || data.user === loggedInUserId) &&
        data.application &&
        data.application === applicationId &&
        application
      ) {
        setApplication({
          ...application,
          offers: [...(application.offers || []), data],
        });
      }
    });

    socket.on('offer_accepted', (data) => {
      if (
        data.user === partnerId &&
        data.application &&
        data.application === applicationId &&
        application?.offers?.length
      ) {
        const copy = JSON.parse(JSON.stringify(application.offers));
        const index = copy.findIndex((item) => item.id === data.id);
        if (index > -1) {
          copy[index].is_accepted = true;
          setApplication({
            ...application,
            offers: copy,
          });
        }
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [
    messages,
    partnerId,
    loggedInUserId,
    application,
    applicationId,
    markConversationRead,
  ]);

  const getApplicantOffer = () => {
    if (
      application?.offers?.length &&
      (application.offers[application.offers.length - 1].user ===
        application.user_id ||
        application.offers[application.offers.length - 1].is_accepted)
    ) {
      return application.offers[application.offers.length - 1];
    }
    return null;
  };

  const getAcceptedOffer = () => {
    return getApplicantOffer()?.amount;
  };

  const uploadFile = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileInfo = {
        name: file.name,
        type: file.type,
      };
      getUploadURLService(fileInfo)
        .then(({ signedRequest, url, error }) => {
          if (signedRequest && url) {
            fetch(signedRequest, {
              method: 'PUT',
              body: file,
            })
              .then((uploadResponse) => {
                if (uploadResponse?.ok) {
                  resolve(url);
                } else {
                  reject('Something went wrong.');
                }
              })
              .catch((error) => {
                reject(error.message);
              });
          } else {
            reject(error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const getFileExtension = (fileName: string) => {
    const parts = fileName.split('.');
    return parts[parts.length - 1];
  };

  const uploadAttachment = async () => {
    if (newAttachment) {
      try {
        setLoading(true);
        const uploadResponse = await uploadFile(newAttachment);
        if (uploadResponse) {
          const { isUpdated, error: updateError } =
            await updateApplicantsStatusService(applicationId, {
              attachments: [
                ...(application?.attachments || []),
                uploadResponse,
              ],
            });
          if (isUpdated) {
            setApplication({
              ...application,
              attachments: [
                ...(application?.attachments || []),
                uploadResponse as string,
              ],
            });
            setNewAttachment(null);
          }
          if (updateError) {
            setError(updateError);
          }
        }
        setLoading(false);
      } catch (error) {
        setError(`An error occurred while uploading resume: ${error.message}`);
        setLoading(false);
      }
    }
  };

  const markAsHired = async () => {
    setError('');
    if (!selectedCard && getAcceptedOffer()) {
      return;
    }
    setLoading(true);
    const obj: any = {
      is_hired: true,
    };
    if (getAcceptedOffer()) {
      obj.card = selectedCard;
    }
    if (
      application?.offers?.length &&
      application.offers[application.offers.length - 1].user ===
        application.user_id &&
      !application.offers[application.offers.length - 1].is_accepted
    ) {
      obj.offer_id = application.offers[application.offers.length - 1].id;
      obj.receiver = application.user_id;
      obj.message = `${first_name || user_name} accepted the offer!`;
    }
    const { isUpdated, error } = await updateApplicantsStatusService(
      applicationId,
      obj,
    );

    if (isUpdated) {
      if (getAcceptedOffer()) {
        History.push(ROUTES.PAYMENT_PROCESSED);
      } else {
        History.push(`${ROUTES.DASHBOARD}${ROUTES.ACTIVE_JOBS}?congrats=true`);
      }
    }

    if (error) {
      setError(error);
    }
    setLoading(false);
  };

  const getCurrentOffer = () => {
    return application.offers[application.offers.length - 1].amount;
  };

  const isOfferAccepted = () => {
    return !!application?.offers?.find((offer) => offer.is_accepted === true);
  };

  const getOwnerOffer = () => {
    if (
      application?.offers?.length &&
      application.offers[application.offers.length - 1].user ===
        application.owner_id &&
      !application.offers[application.offers.length - 1].is_accepted
    ) {
      return application.offers[application.offers.length - 1];
    }
    return null;
  };

  const acceptOffer = async () => {
    setSending(true);
    const ownerOffer = getOwnerOffer();
    const { isUpdated, error } = await updateOfferService(ownerOffer.id, {
      is_accepted: true,
      application: applicationId,
      receiver: application.owner_id,
      message: `${
        application.first_name || application.user_name
      } accepted the offer!`,
    });

    if (isUpdated) {
      const appCopy = JSON.parse(JSON.stringify(application));
      appCopy.offers[application.offers.length - 1].is_accepted = true;
      setApplication(application);
    }

    if (error) {
      setError(error);
    }
    setSending(false);
  };

  const getTotalBudget = (offer: number) => {
    return offer / ((100 - platformFee) / 100);
  };

  const handleMarkHired = () => {
    if (getApplicantOffer()?.amount) {
      setBeforePay(true);
    } else {
      markAsHired();
    }
  };

  const changeIsCounter = (val) => {
    setIsCounter(val);
    setMessageText('');
    setEditorState(EditorState.createEmpty());
  };

  return (
    <Fragment>
      <div className={styles.dashboardSection}>
        <Row>
          <Col>
            {/* <Row>
              <Col>
                <div className={styles.sectionHeading}>
                  Viewing Message From{' '}
                  <span className={styles.messageUser}>
                    {userName || application?.user_name || ''}
                  </span>
                </div>
              </Col>
            </Row> */}
            {!beforePay && (
              <Row>
                <Col>
                  <div className={styles.messageListing}>
                    <div className={styles.messageDetailsTop}>
                      <div className={styles.imgWrapper}>
                        <img
                          src={
                            photo ||
                            application?.photo ||
                            'https://placehold.co/160x160'
                          }
                          alt={userName}
                        />
                      </div>
                      {applicationId ? (
                        <Link
                          to={`${ROUTES.DASHBOARD}${
                            application?.type === JOB_TYPE.career
                              ? ROUTES.CAREERS_PENDING_APPLICANTS
                              : ROUTES.OPPORTUNITIES_PENDING_APPLICANTS
                          }`}
                        >
                          <FontAwesomeIcon
                            icon={faArrowLeft}
                            className={styles.searchIcon}
                          />
                          Back To Applicants
                        </Link>
                      ) : (
                        <Link to={`${ROUTES.DASHBOARD}${ROUTES.MY_MESSAGES}`}>
                          <FontAwesomeIcon
                            icon={faArrowLeft}
                            className={styles.searchIcon}
                          />
                          Back To All Messages
                        </Link>
                      )}
                      <Button
                        disabled={reported}
                        label={
                          !reported
                            ? 'Report This User'
                            : 'User Has Been Reported'
                        }
                        type="outline-gray noRadius"
                        onClick={reportUser}
                      />
                    </div>
                    {loading ? <Loader /> : null}
                    {application && (
                      <div className={styles.applicantDetail}>
                        <Row>
                          <Col sm={8}>
                            {application.title && <h3>{application.title}</h3>}
                            <div className={styles.appTop}>
                              <div className={styles.userName}>
                                {application.user_name}
                              </div>
                              <div className={styles.createdApp}>
                                {moment(application.created_at).fromNow()}
                              </div>
                            </div>
                            {application.note && (
                              <div className={styles.descSec}>
                                <div className={styles.descSecTop}>
                                  Description
                                </div>
                                <div className={styles.descSecBottom}>
                                  {application.note}
                                </div>
                              </div>
                            )}
                            {!!application.schedule?.length && (
                              <div className={styles.schBreakdown}>
                                <div className={styles.heading}>
                                  Schedule/Breakdown
                                </div>
                                <div className={styles.schListOuter}>
                                  {application.schedule.map((item, index) => (
                                    <div
                                      key={index}
                                      className={styles.schListing}
                                    >
                                      <div className={styles.schTiming}>
                                        {moment(item.date).format(
                                          'MMMM Do, YYYY',
                                        )}
                                      </div>
                                      <div className={styles.schDesc}>
                                        {item.delivery}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                            <div className={styles.descSec}>
                              <div className={styles.descSecTop}>Questions</div>
                              <div className={styles.descSecBottom}>
                                {application.question || 'None'}
                              </div>
                            </div>
                            <div className={styles.descSec}>
                              <div className={styles.descSecTop}>Documents</div>
                              {application.attachments?.map((item, index) => (
                                <div
                                  key={item}
                                  className={styles.downloadResume}
                                >
                                  <a
                                    href={item}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download
                                  >
                                    <div className={styles.pdfIcon}>
                                      <SVG
                                        baseURL="/"
                                        description="PDF"
                                        src={PDF}
                                      />
                                    </div>
                                    <div className={styles.fileType}>
                                      <div className={styles.fileName}>
                                        Attachment {index + 1}
                                      </div>
                                      <div className={styles.fileExt}>
                                        {item.substring(
                                          item.lastIndexOf('/') + 1,
                                        )}
                                      </div>
                                    </div>
                                    <div className={styles.download}>
                                      <FontAwesomeIcon icon={faArrowDown} />
                                    </div>
                                  </a>
                                </div>
                              ))}
                              {application.user_resume && (
                                <div className={styles.downloadResume}>
                                  <a
                                    href={application.user_resume}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download
                                  >
                                    <div className={styles.pdfIcon}>
                                      <SVG
                                        baseURL="/"
                                        description="PDF"
                                        src={PDF}
                                      />
                                    </div>
                                    <div className={styles.fileType}>
                                      <div className={styles.fileName}>
                                        My Resume
                                      </div>
                                      <div className={styles.fileExt}>
                                        {application.user_resume.substring(
                                          application.user_resume.lastIndexOf(
                                            '/',
                                          ) + 1,
                                        )}
                                      </div>
                                    </div>
                                    <div className={styles.download}>
                                      <FontAwesomeIcon icon={faArrowDown} />
                                    </div>
                                  </a>
                                </div>
                              )}
                              {!newAttachment && (
                                <>
                                  <div className={styles.fileUploadWrap}>
                                    <div>
                                      <div className={styles.fileNote}>
                                        Upload any final documents, including
                                        contracts, that are required to begin
                                        the Gig.
                                      </div>
                                      <FileUpload
                                        label="Upload"
                                        buttonStyles="gray w-100"
                                        onFileChange={setNewAttachment}
                                      />
                                    </div>
                                    <div className={styles.fileInfo}>
                                      <div>5mb max</div>
                                      <div>500px wide</div>
                                      <div>500px tall</div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                            {newAttachment && (
                              <>
                                <div
                                  className={styles.attachmentsListing}
                                  key={newAttachment.name}
                                >
                                  <div className={styles.attachmentIcon}>
                                    <FontAwesomeIcon
                                      icon={
                                        getFileExtension(
                                          newAttachment?.name,
                                        ) === 'pdf'
                                          ? faFilePdf
                                          : faFileWord
                                      }
                                    />
                                  </div>
                                  <div className={styles.resultData}>
                                    <div className={styles.resultName}>
                                      {newAttachment.name}
                                    </div>
                                  </div>
                                  <div
                                    className={styles.attachmentRemove}
                                    onClick={() => setNewAttachment(null)}
                                  >
                                    Remove
                                  </div>
                                  <div className={styles.uploadBtn}>
                                    <Button
                                      label={
                                        sending || loading
                                          ? 'uploading...'
                                          : 'Upload'
                                      }
                                      type="transparent outline-gray"
                                      disabled={sending || loading}
                                      onClick={() => uploadAttachment()}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </Col>
                          <Col sm={4}>
                            {!!application?.offers?.length &&
                              application?.owner_id === loggedInUserId && (
                                <div className={styles.sidebarPanel}>
                                  <div className={styles.heading}>
                                    Total Budget
                                  </div>
                                  <div className={styles.subHeading}>
                                    $
                                    {getTotalBudget(getCurrentOffer()).toFixed(
                                      2,
                                    )}
                                  </div>
                                </div>
                              )}
                            {!!application.offers?.length && (
                              <div className={styles.sidebarPanel}>
                                <div className={styles.heading}>
                                  Current Offer
                                </div>
                                <div className={styles.subHeading}>
                                  ${getCurrentOffer()}
                                </div>
                              </div>
                            )}
                            {!!application.offers?.length && (
                              <div className={styles.sidebarPanel}>
                                <div className={styles.heading}>
                                  Original Offer
                                </div>
                                <div className={styles.subHeading}>
                                  ${application.offers[0].amount}
                                </div>
                              </div>
                            )}
                            {!!application.hourly_fee && (
                              <div className={styles.sidebarPanel}>
                                <div className={styles.heading}>
                                  Additional Hourly
                                </div>
                                <div className={styles.subHeading}>
                                  ${application.hourly_fee || 0}/hr
                                </div>
                              </div>
                            )}
                            <div className={styles.sidebarPanel}>
                              <div className={styles.heading}>View Profile</div>
                              <div className={styles.subHeading}>
                                <Link
                                  target="_blank"
                                  to={`${ROUTES.PROFILE}/${application.slug}`}
                                >
                                  {application.first_name ||
                                    application.user_name}{' '}
                                  {application.last_name}
                                </Link>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                    {messages && messages.length > 0 && (
                      <div className={styles.chatBox}>
                        <div className={styles.chatList}>
                          <div
                            className={`${styles.list} ${
                              messages[0].sender === loggedInUserId
                                ? styles.listClient
                                : ''
                            }`}
                          >
                            <div className={styles.img}>
                              <a
                                href={`${ROUTES.PROFILE}/${messages[0].sender_slug}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={
                                    messages[0].sender_photo ||
                                    'https://mic-react.s3.amazonaws.com/place_70x70.png'
                                  }
                                  alt={messages[0].sender_name}
                                />
                              </a>
                            </div>
                            <div className={styles.messageInfo}>
                              <div
                                className={styles.MessageHeading}
                                dangerouslySetInnerHTML={{
                                  __html: messages[0].body,
                                }}
                              />
                              <div className={styles.user}>
                                <a
                                  href={`${ROUTES.PROFILE}/${messages[0].sender_slug}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {messages[0].sender_name}
                                </a>
                              </div>
                              <div className={styles.time}>
                                {moment(messages[0].created_at).fromNow()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {messages &&
                      messages.slice(1).map((message) => {
                        if (loggedInUserId === message.sender) {
                          return (
                            <div key={message.id} className={styles.chatBox}>
                              <div className={styles.chatList}>
                                <div
                                  className={`${styles.list} ${styles.listClient}`}
                                >
                                  <div className={styles.img}>
                                    <a
                                      href={`${ROUTES.PROFILE}/${message.sender_slug}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        src={
                                          message.sender_photo ||
                                          'https://mic-react.s3.amazonaws.com/place_70x70.png'
                                        }
                                        alt={message.sender_name}
                                      />
                                    </a>
                                  </div>
                                  <div className={styles.messageInfo}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: message.body,
                                      }}
                                      className={styles.MessageHeading}
                                    />
                                    <div className={styles.user}>
                                      <a
                                        href={`${ROUTES.PROFILE}/${message.sender_slug}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {message.sender_name}
                                      </a>
                                    </div>
                                    <div className={styles.time}>
                                      {moment(message.created_at).fromNow()}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div key={message.id} className={styles.chatBox}>
                              <div className={styles.chatList}>
                                <div className={styles.list}>
                                  <div className={styles.img}>
                                    <a
                                      href={`${ROUTES.PROFILE}/${message.sender_slug}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        src={
                                          message.sender_photo ||
                                          'https://mic-react.s3.amazonaws.com/place_70x70.png'
                                        }
                                        alt={message.sender_name}
                                      />
                                    </a>
                                  </div>
                                  <div className={styles.messageInfo}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: message.body,
                                      }}
                                      className={styles.MessageHeading}
                                    />
                                    <div className={styles.user}>
                                      <a
                                        href={`${ROUTES.PROFILE}/${message.sender_slug}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {message.sender_name}
                                      </a>
                                    </div>
                                    <div className={styles.time}>
                                      {moment(message.created_at).fromNow()}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                    <div className={styles.chatBox}>
                      <div className={styles.chatList}></div>
                      <div className={styles.textAreaOuter}>
                        <Editor
                          editorState={editorState}
                          onEditorStateChange={onEditorStateChange}
                          wrapperClassName={styles.editorWrapper}
                          toolbarClassName={styles.toolbar}
                          readOnly={isCounter}
                          editorClassName={styles.textEditor}
                          placeholder={
                            applicationId &&
                            !application?.is_hired &&
                            !isOfferAccepted()
                              ? 'Use this message space for discussion only. Counter Offers will only be submitted through the checkbox and amount field below, and the other party will receive an automatic message with your counter offer. Do not write counter offers in the message field.'
                              : 'Write a message'
                          }
                        />
                        <div className={styles.smallInfo}>
                          Be careful when sharing any private information sush
                          as email, phone, etc.
                        </div>
                      </div>
                      {applicationId &&
                        !application?.is_hired &&
                        !isOfferAccepted() && (
                          <div className={styles.counterOfferInput}>
                            <div className={styles.checkRow}>
                              <Checkbox
                                id="counter"
                                name="counter"
                                label="Counter Offer"
                                value="counter"
                                isChecked={isCounter}
                                onChange={() => changeIsCounter(!isCounter)}
                              />
                              <Input
                                type="number"
                                placeholder="$"
                                disabled={!isCounter}
                                onChange={(e) =>
                                  setCounterAmount(e.target.value)
                                }
                                value={counterAmount}
                                name="counterAmount"
                              />
                            </div>
                          </div>
                        )}
                      <div className={styles.btnOuter}>
                        <Button
                          label={sending ? 'loading...' : 'Send Message'}
                          type="branding large"
                          disabled={
                            sending ||
                            (!messageText &&
                              (counterAmount === '' || !isCounter))
                          }
                          onClick={sendMessage}
                        />
                        {applicationId &&
                          !application?.is_hired &&
                          application?.owner_id === loggedInUserId &&
                          getApplicantOffer() && (
                            <Button
                              label={
                                sending || loading
                                  ? 'loading...'
                                  : 'Mark As Hired'
                              }
                              type="transparent outline-gray large"
                              disabled={sending || loading}
                              onClick={() => handleMarkHired()}
                            />
                          )}
                        {applicationId &&
                          !application?.is_hired &&
                          application?.user_id === loggedInUserId &&
                          getOwnerOffer() && (
                            <Button
                              label={sending ? 'loading...' : 'Accept Offer'}
                              type="transparent outline-gray large"
                              disabled={sending}
                              onClick={() => acceptOffer()}
                            />
                          )}
                      </div>
                    </div>
                    {error && <Alert variant="danger">{error}</Alert>}
                  </div>
                </Col>
              </Row>
            )}
            {beforePay && (
              <Row>
                <Col>
                  <div className={styles.messageListing}>
                    <div className={styles.messageDetailsTop}>
                      {applicationId ? (
                        <Link
                          to={`${ROUTES.DASHBOARD}${
                            type === JOB_TYPE.career
                              ? ROUTES.CAREERS_PENDING_APPLICANTS
                              : ROUTES.OPPORTUNITIES_PENDING_APPLICANTS
                          }`}
                        >
                          <FontAwesomeIcon
                            icon={faArrowLeft}
                            className={styles.searchIcon}
                          />
                          Back To Applicants
                        </Link>
                      ) : (
                        <Link to={`${ROUTES.DASHBOARD}${ROUTES.MY_MESSAGES}`}>
                          <FontAwesomeIcon
                            icon={faArrowLeft}
                            className={styles.searchIcon}
                          />
                          Back To All Messages
                        </Link>
                      )}
                    </div>
                    <div className={styles.payRedirect}>
                      <div className={styles.imgWrapper}>
                        <SVG baseURL="/" description="Pay" src={Pay} />
                      </div>
                      <div className={styles.heading}>One More Step!</div>
                      <div className={styles.payButton}>
                        <Button
                          onClick={() => setShowPay(true)}
                          label="Complete Payment/Start Gig"
                          type="lgBtn"
                        />
                      </div>
                      <div className={styles.desc}>
                        You have accepted the offer, but you will need to
                        complete the payment before your gig can begin.
                        <br />
                        Beyond Physician requires all gig owners to pay 100%
                        upfront to mitigate risk between both parties. We will
                        hold your funds until you mark the gig as complete,
                        which releases the funds to your seller/service
                        provider. This structure protects you if the provider
                        does not complete your mutually agreed tasks while
                        providing the seller with security for payment. Beyond
                        Physician can assist you in the case of a dispute with a
                        seller.
                      </div>
                      <div className={styles.payInfo}>
                        All orders are subject to our{' '}
                        <a
                          href="http:/beyondphysician.com/privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          privacy policy
                        </a>{' '}
                        (http:/beyondphysician.com/privacy) and our{' '}
                        <a
                          href="http:/beyondphysician.com/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          terms of service
                        </a>{' '}
                        (http:/beyondphysician.com/terms)
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Modal
          show={showPay}
          onHide={() => setShowPay(false)}
          className={styles.paymentPopup}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className={styles.closeBtn} onClick={() => setShowPay(false)}>
            Close{' '}
            <span>
              <SVG
                baseURL="/"
                className={styles.closeIcon}
                description="close"
                loader={<span>Loading...</span>}
                src={closeIcon}
              />
            </span>
          </div>
          <Modal.Header>
            <Modal.Title>Payment Summary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={styles.paySum}>
              <div className={styles.payRow}>
                <div className={styles.payLabel}>Total Job Amount</div>
                <div className={styles.payValue}>
                  ${getAcceptedOffer()?.toFixed(2)}
                </div>
              </div>
              <div className={styles.payRow}>
                <div className={styles.payLabel}>Platform Fees</div>
                <div className={styles.payValue}>
                  $
                  {(
                    getTotalBudget(getAcceptedOffer()) - getAcceptedOffer()
                  )?.toFixed(2)}
                </div>
              </div>
              <div className={`${styles.payRow} ${styles.paySubtotal}`}>
                <div className={styles.payLabel}>Total</div>
                <div className={styles.payValue}>
                  ${getTotalBudget(getAcceptedOffer())?.toFixed(2)}
                </div>
              </div>
              <div className={styles.payForm}>
                <div className={styles.payFormHeading}>
                  <div>Payment Method</div>
                  <div>
                    <SVG
                      baseURL="/"
                      className={styles.authIcon}
                      description="Auth"
                      loader={<span>Loading...</span>}
                      src={Auth}
                    />
                  </div>
                </div>
                <PaymentForm setSelectedCard={setSelectedCard} />
                {error && <Alert variant="danger">{error}</Alert>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => markAsHired()}
              disabled={loading}
              label={loading ? 'Processing...' : 'Confirm Payment'}
              type="transparent w-100 branding lgBtn md-font m-0"
            />
          </Modal.Footer>
        </Modal>
      </div>
    </Fragment>
  );
};

export default MessageDetail;
