import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

import { Row, Col, Alert } from 'react-bootstrap';

// Components
import Result from '../../../JobBoard/Result/Result';

// Interfaces
import { Job } from 'interfaces/Job.types';

// Context
import GlobalContext from 'contexts/Global.context';

import COPY_OVERRIDES from 'config/CopyOverrides';

// Services
import { fetchAppliedJobsService } from 'services/JobService';

import { ROUTES } from 'constants/Constants';

// Styles
import styles from './CareersApplied.module.scss';
import { JOB_TYPE } from 'constants/Constants';
import Loader from 'components/Loader/Loader';

interface Props {
  type: string;
}

const CareersApplied = ({ type }: Props) => {
  const {
    userDetails: { id },
  } = useContext(GlobalContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [careersApplied, setCareersApplied] = useState<Array<Job> | null>(null);
  const [error, setError] = useState<string>('');

  const fetchCareersApplied = useCallback(async () => {
    setLoading(true);
    setCareersApplied(null);
    setError('');
    if (id) {
      const { jobs, error } = await fetchAppliedJobsService(id, { type });
      if (jobs) {
        setCareersApplied(jobs);
      } else if (error) {
        setError(error);
      }
    }

    setLoading(false);
  }, [id, type]);

  useEffect(() => {
    fetchCareersApplied();
  }, [fetchCareersApplied]);

  return (
    <Fragment>
      <div className={styles.dashboardSection}>
        <Row>
          <Col>
            <Row>
              <Col>
                <div className={styles.sectionHeading}>
                  {`${
                    type === JOB_TYPE.career
                      ? COPY_OVERRIDES?.dashboard?.careers || 'Careers'
                      : COPY_OVERRIDES?.dashboard?.opportunities ||
                        'Opportunities'
                  } I’ve Applied`}
                </div>
              </Col>
            </Row>
            <Row className={styles.careersApplied}>
              <Col>
                {loading ? <Loader /> : null}
                {careersApplied &&
                  careersApplied.length > 0 &&
                  careersApplied.map((career) => (
                    <Result
                      key={career.id}
                      id={career.id}
                      image={career.company_logo}
                      idealApplicant={career.ideal_applicant}
                      title={career.title}
                      category={career.category}
                      location={career.location}
                      date={career.created_at}
                      jobType={type}
                      city={career.city}
                      state={career.state}
                      link={`${ROUTES.DASHBOARD}${ROUTES.MY_MESSAGES}/${career.user}?applicationId=${career.application_id}&type=${type}`}
                    />
                  ))}
                {!loading && careersApplied && careersApplied.length === 0 ? (
                  <div className={styles.noJobsFound}>{`No ${
                    type === JOB_TYPE.career
                      ? COPY_OVERRIDES?.dashboard?.careers || 'careers'
                      : COPY_OVERRIDES?.dashboard?.opportunities ||
                        'opportunities'
                  } found`}</div>
                ) : null}
                {!loading && error && <Alert variant="danger">{error}</Alert>}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default CareersApplied;
