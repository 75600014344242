import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import qs from 'qs';

import Sidebar from './components/Sidebar/Sidebar';
import Header from './components/Header/Header';
import Filter from './components/Content/Filter/Filter';
import Listing from './components/Content/Listing/Listing';

// Interfaces
import { Talent, MyJob } from 'interfaces/User.types';

import GlobalContext from 'contexts/Global.context';

// Services
import { searchTalentService } from 'services/UserService';
import { fetchMyJobsService } from 'services/JobService';

// Utils
import { addParamsToURL } from 'utils/UtilityFunctions';
import History from 'utils/History';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

// SCSS
import styles from './TalentSearch.module.scss';
import { DEFAULT_PAGINATION_PARAMS, ITEMS_PER_PAGE } from 'constants/Constants';

interface Query {
  skills?: Array<string>;
  classifications?: Array<string>;
  resume?: boolean;
  online?: boolean;
  recently_online?: boolean;
  hired?: boolean;
  credentialed?: boolean;
}

const Search: React.FC = () => {
  const { search, pathname } = useLocation();
  const { isLoggedIn } = useContext(GlobalContext);
  const {
    keyword: _keyword,
    resume: _resume,
    skills: _skills,
    recently_online: _recently_online,
    online: _online,
    classifications: _classifications,
    hired: _hired,
    credentialed: _credentialed,
  } = qs.parse(search, { ignoreQueryPrefix: true });

  const [users, setUsers] = useState<Array<Talent>>([]);
  const [jobs, setJobs] = useState<Array<MyJob>>([]);
  const [totalUsers, setTotalUsers] = useState<number | null>(null);
  const [skip, setSkip] = useState<number>(0);

  const [keyword, setKeyword] = useState<string>(
    _keyword ? _keyword.toString() : '',
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [query, setQuery] = useState<Query>({
    resume: _resume?.toString() === 'true' || false,
    online: _online?.toString() === 'true' || false,
    hired: _hired?.toString() === 'true' || false,
    credentialed: _credentialed?.toString() === 'true' || false,
    recently_online: _recently_online?.toString() === 'true' || false,
    skills: _skills?.toString().split('_') || [],
    classifications: _classifications?.toString().split(',') || [],
  });

  const setQueryString = useCallback(() => {
    setTimeout(() => {
      const queryObj: any = {};
      if (keyword) {
        queryObj.keyword = keyword;
      }
      for (let key in query) {
        if (
          query[key] &&
          (query[key].length || typeof query[key] === 'boolean')
        ) {
          queryObj[key] = query[key];
        }
      }
      if (Object.keys(queryObj).length) {
        History.push({
          pathname,
          search: addParamsToURL(queryObj),
        });
      }
    }, 0);
  }, [keyword, query, pathname]);

  const onSearchTrigger = (searchText: string) => {
    setSkip(0);
    setKeyword(searchText);
  };

  const onSkillFilterChange = (skills: Array<string>) => {
    setSkip(0);
    setQuery({
      ...query,
      skills,
    });
  };

  const onClassificationsFilterChange = (classifications: Array<string>) => {
    setSkip(0);
    setQuery({
      ...query,
      classifications,
    });
  };

  const onResumeChange = () => {
    setSkip(0);
    setQuery({
      ...query,
      resume: !query.resume,
    });
  };

  const onOnlineChange = () => {
    setSkip(0);
    setQuery({
      ...query,
      online: !query.online,
    });
  };

  const onHiredChange = () => {
    setSkip(0);
    setQuery({
      ...query,
      hired: !query.hired,
    });
  };

  const onCredentialedChange = () => {
    setSkip(0);
    setQuery({
      ...query,
      credentialed: !query.credentialed,
    });
  };

  const onRecentlyOnlineChange = () => {
    setSkip(0);
    setQuery({
      ...query,
      recently_online: !query.recently_online,
    });
  };

  const fetchMoreUsers = (page: number) => {
    setSkip((page - 1) * ITEMS_PER_PAGE);
  };

  // Function to fetch users on mount as well as chnage on the filters
  const fetchUsers = useCallback(async () => {
    setLoading(true);
    setError('');
    setQueryString();
    if (!skip) {
      setUsers([]);
    }
    // changing properties to snake_case
    const queryObj = {
      skills: query.skills,
      resume: query.resume,
      online: query.online,
      professional_classification: query.classifications,
      recently_online: query.recently_online,
      keyword,
      hired: query.hired,
      credentialed: query.credentialed,
    };
    const {
      users: fetchedUsers,
      usersCount,
      error,
    } = await searchTalentService(queryObj, {
      ...DEFAULT_PAGINATION_PARAMS,
      skip,
    });

    if (fetchedUsers) {
      if (skip) {
        setUsers((users) => [...users, ...fetchedUsers]);
        setTotalUsers(usersCount);
      } else {
        setUsers(fetchedUsers);
        setTotalUsers(usersCount);
      }
    } else if (error) {
      setError(error);
    }

    setLoading(false);
  }, [query, skip, keyword, setQueryString]);

  const fetchMyJobs = useCallback(async () => {
    if (isLoggedIn) {
      const { jobs: fetchedJobs } = await fetchMyJobsService();

      if (fetchedJobs) {
        setJobs(fetchedJobs);
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    fetchUsers();
    fetchMyJobs();
  }, [fetchUsers, fetchMyJobs]);

  return (
    <Container className={styles.searchPage} fluid>
      <Container className="p-0">
        <Header
          title={COPY_OVERRIDES?.talent?.header || 'Talent'}
          subtitle={`${totalUsers ? totalUsers : 0} people are available now.`}
        />
        <Row>
          <Col xl lg md={12} sm={12} className={styles.pageSidebar}>
            <Sidebar
              title={
                COPY_OVERRIDES?.talent?.subheader ||
                'Find people and invite them to join your project.'
              }
              checkboxValues={query}
              onSkillsFilterChange={onSkillFilterChange}
              onResumeChange={onResumeChange}
              onOnlineChange={onOnlineChange}
              onCredentialedChange={onCredentialedChange}
              onHiredChange={onHiredChange}
              onRecentlyOnlineChange={onRecentlyOnlineChange}
              onClassificationsFilterChange={onClassificationsFilterChange}
            />
          </Col>
          <Col xl lg md={12} sm={12} className={styles.pageContent}>
            <Filter keyword={keyword} setKeyword={onSearchTrigger} />
            <Listing
              loading={loading}
              users={users}
              jobs={jobs || []}
              totalUsers={totalUsers}
              fetchMoreUsers={fetchMoreUsers}
              error={error}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default React.memo(Search);
