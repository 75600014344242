import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';

// Context
import GlobalContext from 'contexts/Global.context';

// SCSS
import styles from './Hero.module.scss';

// SVGs
import Gesture from '../../../../assets/svgs/Hero/gesture.svg';
import Blob from '../../../../assets/svgs/Hero/blob-1.svg';
import Studio from '../../../../assets/svgs/Hero/studio.svg';
import Blob2 from '../../../../assets/svgs/Hero/blob-2.svg';
import Medical from '../../../../assets/svgs/Hero/medical.svg';
import Income from '../../../../assets/svgs/Hero/income.svg';

import { ROUTES } from 'constants/Constants';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

const Hero: React.FC = () => {
  const { variantDetails } = useContext(GlobalContext);
  const HeroBanner = `${variantDetails?.assets_url}/home_banner_new.png`;
  return (
    <Container className={`p-xl-0 p-0 ${styles.heroWrap}`} fluid>
      <Row className={`m-0 ${styles.heroBanner}`}>
        <div className={styles.heroBannerImage}>
          <img src={HeroBanner} alt="Find the perfect opportunity." />
        </div>
        <Col>
          <div className={styles.heroBubble}>
            <div className={styles.heroBubbleData}>
              <div className={styles.heroTitle}>
                {COPY_OVERRIDES?.homepage?.header ||
                  'Welcome to your music professional network.'}
              </div>
              <div className={styles.heroDesc}>
                {COPY_OVERRIDES?.homepage?.subheader ||
                  'Network, collaborate, find jobs and so much more.'}
              </div>
              {variantDetails?.site_short_title === 'BeyondPhysician' && (
                <Link className={styles.heroLink} to={ROUTES.CAREERS}>
                  View all Gigs
                </Link>
              )}
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col
          className={`${styles.heroBlock} ${styles.heroBlockOpportunities}`}
          xl={6}
          lg={6}
          md={12}
          sm={12}
        >
          <div className={styles.heroBlockWrap}>
            <Row>
              <Col xl={8} lg={8} md={12} sm={12}>
                <div className={styles.heroBlockLabel}>
                  {COPY_OVERRIDES?.homepage?.opportunities_header ||
                    'Opportunities'}
                </div>
                <div className={styles.heroBlockTitle}>
                  {COPY_OVERRIDES?.homepage?.opportunities_subheader ||
                    'Want to build your network?'}
                </div>
                <div className={styles.heroBlockDesc}>
                  {COPY_OVERRIDES?.homepage?.opportunities_body ||
                    'Find musicians for collaboration, service professionals and more.'}
                </div>
              </Col>
              <Col className={styles.heroIconCol} xl={4} lg={4} md={12} sm={12}>
                <div className={styles.heroBlockIconWrap}>
                  <SVG
                    baseURL="/"
                    cacheRequests={true}
                    className={styles.heroBlockIcon}
                    description="Opportunities"
                    loader={<span>Loading...</span>}
                    src={
                      variantDetails?.site_short_title === 'BeyondPhysician'
                        ? Medical
                        : Gesture
                    }
                  />
                  {variantDetails?.site_short_title !== 'BeyondPhysician' && (
                    <SVG
                      baseURL="/"
                      cacheRequests={true}
                      className={styles.heroBlockIconBg}
                      description="Opportunities BG"
                      loader={<span>Loading...</span>}
                      src={Blob}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col
          className={`${styles.heroBlock} ${styles.heroBlockCareers}`}
          xl={6}
          lg={6}
          md={12}
          sm={12}
        >
          <div className={styles.heroBlockWrap}>
            <Row>
              <Col xl={8} lg={8} md={12} sm={12}>
                <div className={styles.heroBlockLabel}>
                  {COPY_OVERRIDES?.homepage?.careers_header || 'Careers'}
                </div>
                <div className={styles.heroBlockTitle}>
                  {COPY_OVERRIDES?.homepage?.careers_subheader ||
                    'Looking for a new career?'}
                </div>
                <div className={styles.heroBlockDesc}>
                  {COPY_OVERRIDES?.homepage?.careers_body ||
                    'Everything from full time to contract work, internships and more.'}
                </div>
              </Col>
              <Col className={styles.heroIconCol} xl={4} lg={4} md={12} sm={12}>
                <div className={styles.heroBlockIconWrap}>
                  <SVG
                    baseURL="/"
                    cacheRequests={true}
                    className={styles.heroBlockIcon}
                    description="Careers"
                    loader={<span>Loading...</span>}
                    src={
                      variantDetails?.site_short_title === 'BeyondPhysician'
                        ? Income
                        : Studio
                    }
                  />
                  {variantDetails?.site_short_title !== 'BeyondPhysician' && (
                    <SVG
                      baseURL="/"
                      cacheRequests={true}
                      className={styles.heroBlockIconBg}
                      description="Careers BG"
                      loader={<span>Loading...</span>}
                      src={Blob2}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(Hero);
