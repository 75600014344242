import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { Row, Col, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';
import qs from 'qs';

// Components
import Input from 'components/Form/Input/Input';
import Select from 'components/Form/Select/Select';
import Checkbox from 'components/Form/Checkbox/Checkbox';
import TextArea from 'components/Form/TextArea/TextArea';
import Button from 'components/Button/Button';
import FileUpload from 'components/FileUpload/FileUpload';
import Autocomplete from 'components/Form/AutoComplete/AutoComplete';

import PaymentForm from 'components/PaymentForm/PaymentForm';

import GlobalContext from 'contexts/Global.context';

// Interfaces
import { CareerForm, CareerFormErrors } from 'interfaces/CareerForm.types';
import { Job } from 'interfaces/Job.types';
import { Category } from 'interfaces/Category.types';

// Services
import { createJobService, updateJobService } from 'services/JobService';
import { inviteUserService } from 'services/UserService';

import {
  getUploadURLService,
  verifyCouponService,
} from 'services/CommonService';

// Utilities
import { camelToSnake } from 'utils/UtilityFunctions';

// Constants
import STATES from 'constants/States';
//import INDUSTRIES from 'config/Industries';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

// import DEADLINES from '../../constants/Deadlines';
import CAREER_CONFIG from 'config/CreateCareerConfig';
import CAREER_RULES from '../../constants/CareerRules';
import {
  DEFAULT_CAREER_ERRORS,
  DEFAULT_CAREER_VALUES,
} from '../../constants/DefaultValues';
import FIELD_LABELS from '../../constants/FieldLabels';
import {
  APP_CONSTANTS,
  FILE_TYPE,
  FREE_WORK_CREDENTIALS,
  ROUTES,
} from 'constants/Constants';
import {
  QUALIFICATIONS,
  CREDENTIAL_EXPERIENCE,
  SPECIALITIES,
  FELLOWSHIPS,
  FELLOWSHIP_SUBSPECIALITIES,
} from 'constants/Educations';

// SCSS
import styles from './Career.module.scss';

interface CouponResponse {
  id: string;
  amount: number;
}

interface PostProps {
  id: string;
  job: Job;
  categories: Array<Category>;
  relist: boolean;
}

const {
  errorConstants: { SOMETHING_WENT_WRONG: serverError },
} = APP_CONSTANTS;

const fileSpecs = ['5mb max', '500px wide', '500px tall'];

const Post: React.FC<PostProps> = ({
  id: postId,
  job,
  categories,
  relist,
}: PostProps) => {
  const {
    userDetails: {
      slug,
      first_name,
      last_name,
      city: userCity,
      state: userState,
      zip: userZip,
    },
    variantDetails,
  } = useContext(GlobalContext);
  const featuredAmount = variantDetails?.career_upgrade_cost || 19.99;
  const { search, state } = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [jobId, setJobId] = useState<string>('');
  const [apiError, setApiError] = useState<string>('');
  const [logo, setLogo] = useState<File | null>(null);
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const [code, setCode] = useState<string>('');
  const [coupon, setCoupon] = useState<CouponResponse | null>(null);
  const [amount, setAmount] = useState<number>(0);
  const [couponError, setCouponError] = useState<string>('');
  const [careerFormErrors, setCareerFormErrors] = useState<CareerFormErrors>(
    DEFAULT_CAREER_ERRORS,
  );
  const query = qs.parse(search, { ignoreQueryPrefix: true });
  const [career, setCareer] = useState<CareerForm>(DEFAULT_CAREER_VALUES);

  const initializeDefaultValues = useCallback(() => {
    if (state && state['service'] && state['service']['id']) {
      const stateService = state['service'];
      setCareer({
        type: DEFAULT_CAREER_VALUES.type,
        title: stateService.title || DEFAULT_CAREER_VALUES.title,
        category: stateService.category || DEFAULT_CAREER_VALUES.category,
        location: stateService.location || DEFAULT_CAREER_VALUES.location,
        companyName: `${first_name} ${last_name}`,
        companyLogo: DEFAULT_CAREER_VALUES.companyLogo,
        deadline: 'asap',
        zip: userZip || DEFAULT_CAREER_VALUES.zip,
        state: userState || DEFAULT_CAREER_VALUES.state,
        city: userCity || DEFAULT_CAREER_VALUES.city,
        opportunityDescription:
          'I would like to hire you based on your Talent listing.',
        hiringDescription: `${window.location.origin}/profile/${slug}`,
        applyThrough:
          stateService.apply_through || DEFAULT_CAREER_VALUES.applyThrough,
        externalUrl:
          stateService.external_url || DEFAULT_CAREER_VALUES.externalUrl,
        isFeatured: DEFAULT_CAREER_VALUES.isFeatured,
        isPrivate: 'private',
        industry: stateService.industry || DEFAULT_CAREER_VALUES.industry,
        idealApplicant:
          stateService.ideal_applicant || DEFAULT_CAREER_VALUES.idealApplicant,
        otherIdealApplicant:
          stateService.other_ideal_applicant ||
          DEFAULT_CAREER_VALUES.otherIdealApplicant,
        experience: stateService.experience || DEFAULT_CAREER_VALUES.experience,
        speciality: stateService.speciality || DEFAULT_CAREER_VALUES.speciality,
        fellowship: stateService.fellowship || DEFAULT_CAREER_VALUES.fellowship,
        fellowshipSub:
          stateService.fellowship_sub || DEFAULT_CAREER_VALUES.fellowshipSub,
        licensedState: stateService.licensed_state
          ? stateService.licensed_state.split(',')[0]
          : DEFAULT_CAREER_VALUES.licensedState,
        minPay: stateService.min_pay,
        maxPay: stateService.max_pay,
        credentialName:
          stateService.credential_name || DEFAULT_CAREER_VALUES.credentialName,
      });
    } else {
      setCareer({
        type: query.type?.toString() || DEFAULT_CAREER_VALUES.type,
        title: query.title?.toString() || DEFAULT_CAREER_VALUES.title,
        category: query.category?.toString() || DEFAULT_CAREER_VALUES.category,
        location: query.location?.toString() || DEFAULT_CAREER_VALUES.location,
        companyName:
          query.company_name?.toString() || DEFAULT_CAREER_VALUES.companyName,
        companyLogo:
          query.company_logo?.toString() || DEFAULT_CAREER_VALUES.companyLogo,
        deadline: query.deadline?.toString() || DEFAULT_CAREER_VALUES.deadline,
        zip: query.zip?.toString() || userZip || DEFAULT_CAREER_VALUES.zip,
        state:
          query.state?.toString() || userState || DEFAULT_CAREER_VALUES.state,
        city: query.city?.toString() || userCity || DEFAULT_CAREER_VALUES.city,
        opportunityDescription:
          query.opportunity_description?.toString() ||
          DEFAULT_CAREER_VALUES.opportunityDescription,
        hiringDescription:
          query.hiring_description?.toString() ||
          DEFAULT_CAREER_VALUES.hiringDescription,
        applyThrough:
          query.apply_through?.toString() || DEFAULT_CAREER_VALUES.applyThrough,
        externalUrl:
          query.external_url?.toString() || DEFAULT_CAREER_VALUES.externalUrl,
        isFeatured:
          query.is_featured === 'true'
            ? true
            : DEFAULT_CAREER_VALUES.isFeatured,
        isPrivate:
          query.is_private?.toString() || DEFAULT_CAREER_VALUES.isPrivate,
        industry: query.industry?.toString() || DEFAULT_CAREER_VALUES.industry,
        idealApplicant:
          query.ideal_applicant?.toString() ||
          DEFAULT_CAREER_VALUES.idealApplicant,
        otherIdealApplicant:
          query.other_ideal_applicant?.toString() ||
          DEFAULT_CAREER_VALUES.otherIdealApplicant,
        experience:
          query.experience?.toString() || DEFAULT_CAREER_VALUES.experience,
        speciality:
          query.speciality?.toString() || DEFAULT_CAREER_VALUES.speciality,
        fellowship:
          query.fellowship?.toString() || DEFAULT_CAREER_VALUES.fellowship,
        fellowshipSub:
          query.fellowship_sub?.toString() ||
          DEFAULT_CAREER_VALUES.fellowshipSub,
        licensedState:
          query.licensed_state?.toString() ||
          DEFAULT_CAREER_VALUES.licensedState,
        minPay:
          query.min_pay === 'null'
            ? null
            : query.min_pay
              ? Number(query.min_pay)
              : DEFAULT_CAREER_VALUES.minPay,
        maxPay:
          query.max_pay === 'null'
            ? null
            : query.max_pay
              ? Number(query.max_pay)
              : DEFAULT_CAREER_VALUES.maxPay,
        credentialName:
          query.credential_name?.toString() ||
          DEFAULT_CAREER_VALUES.credentialName,
      });
    }
  }, [
    query.title,
    query.apply_through,
    query.category,
    query.city,
    query.company_logo,
    query.company_name,
    query.credential_name,
    query.deadline,
    query.experience,
    query.external_url,
    query.fellowship,
    query.fellowship_sub,
    query.hiring_description,
    query.ideal_applicant,
    query.industry,
    query.is_featured,
    query.is_private,
    query.licensed_state,
    query.location,
    query.max_pay,
    query.min_pay,
    query.opportunity_description,
    query.other_ideal_applicant,
    query.speciality,
    query.state,
    query.type,
    query.zip,
    state,
    userCity,
    userZip,
    userState,
    first_name,
    last_name,
    slug,
  ]);

  useEffect(() => {
    initializeDefaultValues();
  }, [initializeDefaultValues]);

  useEffect(() => {
    setTimeout(() => {
      if (state && state['hash']) {
        const target = document.getElementById(state['hash']);
        if (target) {
          const bounds = target.getBoundingClientRect();
          const offset = bounds.top;
          window.scrollTo(0, offset);
        }
      }
    }, 100);
  }, [state]);

  useEffect(() => {
    setTimeout(() => {
      if (relist) {
        window.scrollTo(0, document.body.scrollHeight);
      }
    }, 100);
  }, [relist]);

  useEffect(() => {
    if (job) {
      const obj: any = {};
      Object.keys(DEFAULT_CAREER_VALUES).map((key: string) => {
        obj[key] = job[camelToSnake(key)];
        return key;
      });
      setCareer({
        ...obj,
        credentialName:
          obj.credentialName || DEFAULT_CAREER_VALUES.credentialName,
      });
      if (obj.isFeatured && relist) {
        setAmount(featuredAmount);
      }
    }
  }, [job, relist]);

  const validateField = (target: any) => {
    const { name, value, type, checked } = target;
    if (type === 'checkbox') {
      if (!checked && name !== 'isPrivate') {
        setCareerFormErrors({
          ...careerFormErrors,
          [name]: `${FIELD_LABELS[name]} is required`,
        });
      } else {
        setCareerFormErrors({
          ...careerFormErrors,
          [name]: '',
        });
      }
    } else {
      // if (
      //   name === 'credentialName' &&
      //   !value &&
      //   career.experience ===
      //     'Experience/Training: Required (Verified Credential)'
      // ) {
      //   setCareerFormErrors({
      //     ...careerFormErrors,
      //     [name]: `${FIELD_LABELS[name]} is required for the selected experience`,
      //   });
      // }
      if (name.toLowerCase().includes('pay') && value < 0) {
        setCareerFormErrors({
          ...careerFormErrors,
          [name]: `${FIELD_LABELS[name]} can either be non-paying or positive number`,
        });
      } else if (
        name === 'externalUrl' &&
        !value &&
        career['applyThrough'] === 'external'
      ) {
        setCareerFormErrors({
          ...careerFormErrors,
          [name]: `${FIELD_LABELS[name]} is required`,
        });
      } else if (CAREER_RULES[name]?.required && !value) {
        setCareerFormErrors({
          ...careerFormErrors,
          [name]: `${FIELD_LABELS[name]} is required`,
        });
      } else if (value) {
        if (
          CAREER_RULES[name]?.minLength &&
          value.toString().length < CAREER_RULES[name]?.minLength
        ) {
          setCareerFormErrors({
            ...careerFormErrors,
            [name]: `${FIELD_LABELS[name]} should contain at least ${CAREER_RULES[name]?.minLength} characters`,
          });
        } else if (
          CAREER_RULES[name]?.maxLength &&
          value.toString().length > CAREER_RULES[name]?.maxLength
        ) {
          setCareerFormErrors({
            ...careerFormErrors,
            [name]: `${FIELD_LABELS[name]} can not be longer than ${CAREER_RULES[name]?.maxLength} characters`,
          });
        } else if (CAREER_RULES[name]?.min && value < CAREER_RULES[name]?.min) {
          setCareerFormErrors({
            ...careerFormErrors,
            [name]: `${FIELD_LABELS[name]} should be greater than ${CAREER_RULES[name].min}`,
          });
        } else if (CAREER_RULES[name]?.max && value < CAREER_RULES[name]?.max) {
          setCareerFormErrors({
            ...careerFormErrors,
            [name]: `${FIELD_LABELS[name]} should be lesser than ${CAREER_RULES[name]?.max}`,
          });
        } else if (
          CAREER_RULES[name]?.pattern &&
          !CAREER_RULES[name]?.pattern.test(value)
        ) {
          setCareerFormErrors({
            ...careerFormErrors,
            [name]: `${FIELD_LABELS[name]} is invalid`,
          });
        } else {
          setCareerFormErrors({
            ...careerFormErrors,
            [name]: '',
          });
        }
      } else {
        setCareerFormErrors({
          ...careerFormErrors,
          [name]: '',
        });
      }
    }
    return;
  };

  const handleChange = (event: any) => {
    if (jobId) {
      setJobId('');
    }
    if (apiError) {
      setApiError('');
    }
    if (couponError) {
      setCouponError('');
    }
    const { name, value, type, checked } = event.target;
    if (type !== 'checkbox') {
      setCareer({
        ...career,
        [name]:
          name.toLowerCase().includes('pay') && value
            ? parseFloat(value)
            : value,
      });
    } else {
      if (name === 'applyThrough' && value === 'site') {
        setCareer({
          ...career,
          [name]: checked ? value : '',
          externalUrl: '',
        });
      } else if (name === 'pay') {
        if (value === 'Non-Paying') {
          if (checked) {
            setCareer({
              ...career,
              minPay: 0,
              maxPay: 0,
            });
          } else {
            setCareer({
              ...career,
              minPay: '',
              maxPay: '',
            });
          }
        } else {
          if (checked) {
            setCareer({
              ...career,
              minPay: null,
              maxPay: null,
            });
          } else {
            setCareer({
              ...career,
              minPay: '',
              maxPay: '',
            });
          }
        }
      } else if (name === 'isPrivate') {
        setCareer({
          ...career,
          isPrivate: checked ? 'private' : 'public',
        });
      } else {
        setCareer({
          ...career,
          [name]: checked ? value : '',
        });
      }
    }
    validateField(event.target);
  };

  const handleCodeChange = (event: any) => {
    setCouponError('');
    setCode(event.target.value);
  };

  // const handleCategorySelect = (categoryList: Array<Category | any>) => {
  //   setCareer({
  //     ...career,
  //     category: categoryList,
  //   });

  //   if (!categoryList.length) {
  //     setCareerFormErrors({
  //       ...careerFormErrors,
  //       category: 'Category is required',
  //     });
  //   } else {
  //     setCareerFormErrors({
  //       ...careerFormErrors,
  //       category: '',
  //     });
  //   }
  // };

  const handleFeatured = (val: boolean) => {
    if (jobId) {
      setJobId('');
    }
    if (apiError) {
      setApiError('');
    }
    if (couponError) {
      setCouponError('');
    }
    setCareer({
      ...career,
      isFeatured: val,
    });
    setCareerFormErrors({
      ...careerFormErrors,
      isFeatured: '',
    });
    setAmount(val ? featuredAmount : 0);
    setCoupon(null);
    setCode('');
  };

  const uploadLogo = async () => {
    let uploadImageResponse: any = null;
    const fileInfo = {
      name: logo?.name,
      type: logo?.type,
    };
    const { signedRequest, url, error } = await getUploadURLService(fileInfo);

    if (signedRequest && url) {
      uploadImageResponse = await fetch(signedRequest, {
        method: 'PUT',
        body: logo,
      });

      if (uploadImageResponse) {
        return url;
      }
    } else if (error) {
      setApiError(`An error occurred while uploading logo: ${error}`);
      setIsLoading(false);
      return null;
    }
  };

  const verifyCouponCode = async () => {
    if (coupon) {
      setCode('');
      setCoupon(null);
      setAmount(featuredAmount);
      return;
    }
    if (!code) {
      return;
    }
    setCouponError('');
    setIsLoading(true);
    const { id, type, error } = await verifyCouponService(code);

    if (id) {
      setCoupon({
        id,
        amount: type === 'full' ? featuredAmount : featuredAmount / 2,
      });

      setAmount(type === 'full' ? 0 : featuredAmount / 2);

      setIsLoading(false);
    } else if (error) {
      setCouponError(error);
      setIsLoading(false);
    }
  };

  const validateFields = () => {
    if (jobId) {
      setJobId('');
    }
    if (apiError) {
      setApiError('');
    }
    if (couponError) {
      setCouponError('');
    }
    const errors = Object.create({});
    Object.keys(careerFormErrors).map((name) => {
      // if (
      //   name === 'credentialName' &&
      //   !career[name] &&
      //   career.experience ===
      //     'Experience/Training: Required (Verified Credential)'
      // ) {
      //   errors[name] =
      //     `${FIELD_LABELS[name]} is required for the selected experience`;
      // }
      if (name.toLowerCase().includes('pay') && career[name] < 0) {
        errors[name] =
          `${FIELD_LABELS[name]} can either be non-paying or positive number`;
      } else if (name === 'category' && !career[name].length) {
        errors[name] = `${FIELD_LABELS[name]} is required`;
      } else if (
        name === 'externalUrl' &&
        !career[name] &&
        career['applyThrough'] === 'external'
      ) {
        errors[name] = `${FIELD_LABELS[name]} is required`;
      } else if (
        name === 'isFeatured' &&
        CAREER_RULES[name]?.required &&
        career[name] === null
      ) {
        errors[name] = 'Please choose if you would like your post featured';
      } else if (
        CAREER_RULES[name]?.required &&
        !career[name] &&
        name !== 'isFeatured'
      ) {
        errors[name] = `${FIELD_LABELS[name]} is required`;
      } else if (career[name] && name !== 'isFeatured') {
        if (
          CAREER_RULES[name]?.minLength &&
          career[name].toString().length < CAREER_RULES[name]?.minLength
        ) {
          errors[name] =
            `${FIELD_LABELS[name]} should contain at least ${CAREER_RULES[name]?.minLength} characters`;
        } else if (
          CAREER_RULES[name]?.maxLength &&
          career[name].toString().length > CAREER_RULES[name]?.maxLength
        ) {
          errors[name] =
            `${FIELD_LABELS[name]} can not be longer than ${CAREER_RULES[name]?.maxLength} characters`;
        } else if (
          CAREER_RULES[name]?.min &&
          career[name] < CAREER_RULES[name]?.min
        ) {
          errors[name] =
            `${FIELD_LABELS[name]} should be greater than ${CAREER_RULES[name]?.min}`;
        } else if (
          CAREER_RULES[name]?.max &&
          career[name] < CAREER_RULES[name]?.max
        ) {
          errors[name] =
            `${FIELD_LABELS[name]} should be lesser than ${CAREER_RULES[name]?.max}`;
        } else if (
          CAREER_RULES[name]?.pattern &&
          !CAREER_RULES[name]?.pattern.test(career[name])
        ) {
          errors[name] = `${FIELD_LABELS[name]} is invalid`;
        }
      }
      return name;
    });
    if (Object.keys(errors).length) {
      setCareerFormErrors({
        ...careerFormErrors,
        ...errors,
      });
      setApiError('There are some errors above, please fix those to proceed.');
      return false;
    }
    return true;
  };

  const callAnalytics = () => {
    window.analytics.track('Posted Career', {
      careerTitle: career.title,
      category: career.category,
      companyName: career.companyName,
      pay: career.pay,
      compensation: career.pay,
      rate: career.rate,
      duration: career.expectedNeed,
      workLocation: career.location,
      company: career.companyName,
      city: career.city,
      state: career.state,
      jobDescription: career.opportunityDescription,
      companyDescription: career.hiringDescription,
      featureJob: career.isFeatured,
      industry: career.industry,
    });
  };

  const createJob = async (careerFormData: any) => {
    const response = await createJobService(careerFormData);

    if (!response.ok) {
      const error = await response.clone().text();
      setApiError(error || serverError);
    } else {
      const { id } = await response.json();
      callAnalytics();
      if (state && state['send_invite']) {
        const { error: inviteError } = await inviteUserService({
          job: id,
          user: state['invitee_id'],
          message: state['invite_message'],
        });
        if (inviteError) {
          setApiError(
            `Post created but error occured while submitting invitation: ${
              inviteError || serverError
            }`,
          );
        }
      }
      setJobId(id);
      setCareer(DEFAULT_CAREER_VALUES);
    }

    setIsLoading(false);
  };

  const updateJob = async (careerFormData: any) => {
    const { id, error } = await updateJobService(postId, careerFormData);

    if (error) {
      setApiError(error || serverError);
    } else {
      setJobId(id);
    }

    setIsLoading(false);
  };

  // Todo Validate file specs

  /**
   * Create a career
   */
  const handleSubmit = async () => {
    if (!validateFields()) {
      return;
    }

    if (amount && !selectedCard) {
      setApiError('Please select/add a card to proceed with the payment');
      return;
    }

    setApiError('');
    setJobId('');
    setIsLoading(true);

    let uploadResponse = '';
    if (logo) {
      uploadResponse = await uploadLogo();
      if (!uploadResponse) {
        return;
      }
    }

    const careerFormData: any = {};
    Object.keys(career).map((key: string) => {
      careerFormData[camelToSnake(key)] = career[key];
      return key;
    });
    careerFormData['company_logo'] =
      uploadResponse || (job && job.company_logo ? job.company_logo : '');
    if (!careerFormData.min_pay && careerFormData.min_pay !== 0) {
      careerFormData.min_pay = null;
    }
    if (!careerFormData.max_pay && careerFormData.max_pay !== 0) {
      careerFormData.max_pay = null;
    }
    if (careerFormData.is_featured && (!postId || relist)) {
      if (amount) {
        careerFormData.cardId = selectedCard;
      }
      if (coupon) {
        careerFormData.coupon_id = coupon.id;
      }
    }
    careerFormData.fee = null;
    careerFormData.reference_links = null;
    careerFormData.type = 'career';

    if (!postId || relist) {
      createJob(careerFormData);
    } else {
      delete careerFormData['is_featured'];
      updateJob(careerFormData);
    }
  };

  // const renderTooltipCom = (props: any) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     What will you pay someone? Can be &quot;$0&quot;
  //   </Tooltip>
  // );

  // const renderTooltipRate = (props: any) => (
  //   <Tooltip id="button-tooltipRate" {...props}>
  //     {CAREER_CONFIG?.rate?.info}
  //   </Tooltip>
  // );

  const renderTooltipLocation = (props: any) => (
    <Tooltip id="button-tooltipLocation" {...props}>
      {CAREER_CONFIG?.location?.info}
    </Tooltip>
  );

  return (
    <Row className={styles.careerType}>
      {(!CAREER_CONFIG?.title || !CAREER_CONFIG?.title?.hidden) && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.authInput}>
            <div className={styles.inputLabel}>
              {CAREER_CONFIG?.title?.label || 'Post Title'}{' '}
              <span>(max 100 characters)</span>
              <span className={styles.requiredField}> *</span>
              <div className={`${styles.requiredFieldsNote} text-right`}>
                <span>* </span>= Required Fields
              </div>
            </div>
            <Input
              type="text"
              onChange={handleChange}
              value={career.title}
              name="title"
              className={careerFormErrors.title ? styles.errorInput : ''}
            />
            {careerFormErrors.title && (
              <div className={styles.error}>{careerFormErrors.title}</div>
            )}
          </div>
        </Col>
      )}

      {(!CAREER_CONFIG?.category || !CAREER_CONFIG?.category?.hidden) && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.authInput}>
            <div className={styles.inputLabel}>
              {CAREER_CONFIG?.category?.label || 'Project Category '}{' '}
              <span> If you don&apos;t see your category listed, </span>
              <a
                href={
                  COPY_OVERRIDES?.profile?.submit_category_link ||
                  'https://musiccareermap.com/category-submission'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      COPY_OVERRIDES?.profile?.submit_category_text ||
                      'click here',
                  }}
                ></span>
              </a>
              <span> to request it.</span>
              <span className={styles.requiredField}> *</span>
            </div>
            <Autocomplete
              items={categories || []}
              onChange={handleChange}
              value={career.category}
              name="category"
            />
            {careerFormErrors.category && (
              <div className={styles.error}>{careerFormErrors.category}</div>
            )}
          </div>
        </Col>
      )}

      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.inputLabel}>
          Project Due Date
          <span className={styles.requiredField}> *</span>
        </div>
        <Row className="align-items-center">
          <Col md={4}>
            <div className={styles.authInput}>
              <Input
                type="date"
                onChange={handleChange}
                value={career.deadline}
                name="deadline"
                className={careerFormErrors.deadline ? styles.errorInput : ''}
              />
              {careerFormErrors.deadline && (
                <div className={styles.error}>{careerFormErrors.deadline}</div>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className={styles.authInput}>
              <div className={styles.checkRow}>
                <Checkbox
                  id="free"
                  name="deadline"
                  isChecked={career.deadline === 'asap'}
                  onChange={handleChange}
                  label="ASAP"
                  value="asap"
                />
                <Checkbox
                  id="perHour"
                  name="deadline"
                  isChecked={career.deadline === 'no due date'}
                  onChange={handleChange}
                  label="No Due Date"
                  value="no due date"
                />
                <Checkbox
                  id="TBD"
                  name="deadline"
                  isChecked={career.deadline === 'TBD'}
                  onChange={handleChange}
                  label="TBD"
                  value="TBD"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Col>

      {/* {(!CAREER_CONFIG?.pay || !CAREER_CONFIG?.pay?.hidden) && (
        <Col xl={6} lg={6} md={6} sm={12}>
          <div className={styles.inputLabel}>
            {CAREER_CONFIG?.pay?.label || 'Compensation'}
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipCom}
            >
              <div className={styles.infoHover}>i</div>
            </OverlayTrigger>
          </div>
          <Input
            type="number"
            placeholder="$"
            onChange={handleChange}
            value={career.pay}
            name="pay"
          />
          {careerFormErrors.pay && (
            <div className={styles.error}>{careerFormErrors.pay}</div>
          )}
        </Col>
      )} */}

      {/* {!!INDUSTRIES?.length && (
        <Col xl={6} lg={6} md={6} sm={12}>
          <div className={styles.inputLabel}>
            {CAREER_CONFIG?.industry?.label || 'Industry'}
            <span className={styles.requiredField}> *</span>
          </div>
          <Select
            id="industry"
            selectItems={INDUSTRIES}
            onChange={handleChange}
            name="industry"
            value={career.industry}
          />
          {careerFormErrors.industry && (
            <div className={styles.error}>{careerFormErrors.industry}</div>
          )}
        </Col>
      )} */}

      {/* {(!CAREER_CONFIG?.rate || !CAREER_CONFIG?.rate?.hidden) && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {CAREER_CONFIG?.rate?.label || 'Rate'}
            <span className={styles.requiredField}> *</span>
            {CAREER_CONFIG?.rate?.info && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipRate}
              >
                <div className={styles.infoHover}>i</div>
              </OverlayTrigger>
            )}
          </div>
          <div className={styles.checkRow}>
            <Checkbox
              id="free"
              name="rate"
              isChecked={career.rate === 'free'}
              onChange={handleChange}
              label="Non-Paying"
              value="free"
            />
            <Checkbox
              id="perHour"
              name="rate"
              isChecked={career.rate === 'hour'}
              onChange={handleChange}
              label="Per Hour"
              value="hour"
            />
            <Checkbox
              id="flatFee"
              name="rate"
              isChecked={career.rate === 'flat'}
              onChange={handleChange}
              label="Flat Fee"
              value="flat"
            />
            <Checkbox
              id="salary"
              name="rate"
              isChecked={career.rate === 'salary'}
              onChange={handleChange}
              label="Salary"
              value="salary"
            />
            <Checkbox
              id="TBD"
              name="rate"
              isChecked={career.rate === 'TBD'}
              onChange={handleChange}
              label="TBD"
              value="TBD"
            />
          </div>
          {careerFormErrors.rate && (
            <div className={styles.error}>{careerFormErrors.rate}</div>
          )}
        </Col>
      )} */}

      {/* {(!CAREER_CONFIG?.expectedNeed ||
        !CAREER_CONFIG?.expectedNeed?.hidden) && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {CAREER_CONFIG?.expectedNeed?.label || 'Position'}
            <span className={styles.requiredField}> *</span>
          </div>
          <div className={styles.checkRow}>
            <Checkbox
              id="fullTime"
              name="expectedNeed"
              isChecked={career.expectedNeed === 'full time'}
              onChange={handleChange}
              label="Full Time"
              value="full time"
            />
            <Checkbox
              id="partTime"
              name="expectedNeed"
              isChecked={career.expectedNeed === 'part time'}
              onChange={handleChange}
              label="Part Time"
              value="part time"
            />
            <Checkbox
              id="oneTime"
              name="expectedNeed"
              isChecked={career.expectedNeed === 'one time'}
              onChange={handleChange}
              label="One Time"
              value="one time"
            />
            <Checkbox
              id="freelance"
              name="expectedNeed"
              isChecked={career.expectedNeed === 'freelance'}
              onChange={handleChange}
              label="Freelance"
              value="freelance"
            />
            <Checkbox
              id="internship"
              name="expectedNeed"
              isChecked={career.expectedNeed === 'internship'}
              onChange={handleChange}
              label="Internship"
              value="internship"
            />
             <Checkbox
            id="salary1"
            name="expectedNeed"
            isChecked={career.expectedNeed === 'salary'}
            onChange={handleChange}
            label="Salary"
            value="salary"
          />
          }
          </div>
          {careerFormErrors.expectedNeed && (
            <div className={styles.error}>{careerFormErrors.expectedNeed}</div>
          )}
        </Col>
      )} */}

      {(!CAREER_CONFIG?.location || !CAREER_CONFIG?.location?.hidden) && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.authInput}>
            <div className={styles.inputLabel}>
              {CAREER_CONFIG?.location?.label || 'Location'}
              <span className={styles.requiredField}> *</span>
              {CAREER_CONFIG?.location?.info && (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltipLocation}
                >
                  <div className={styles.infoHover}>i</div>
                </OverlayTrigger>
              )}
            </div>
            <div className={styles.checkRow}>
              <Checkbox
                id="onSite"
                name="location"
                isChecked={career.location === 'on site'}
                onChange={handleChange}
                label="On-Site"
                value="on site"
              />
              <Checkbox
                id="remote"
                name="location"
                isChecked={career.location === 'remote'}
                onChange={handleChange}
                label="Remote"
                value="remote"
              />
              <Checkbox
                id="both"
                name="location"
                isChecked={career.location === 'both'}
                onChange={handleChange}
                label="Both Remote &amp; Onsite"
                value="both"
              />
              <Checkbox
                id="locationTBD"
                name="location"
                isChecked={career.location === 'TBD'}
                onChange={handleChange}
                label="TBD"
                value="TBD"
              />
            </div>
            {careerFormErrors.location && (
              <div className={styles.error}>{careerFormErrors.location}</div>
            )}
          </div>
        </Col>
      )}

      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.authInput}>
          <div className={styles.inputLabel}>
            Ideal Applicant <span> (Please choose one, </span>
            <a
              href={
                COPY_OVERRIDES?.post?.applicant_link ||
                'https://musiccareermap.com/category-submission'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>click here</span>
            </a>
            <span> if your preference is not listed.)</span>
            <span className={styles.requiredField}> *</span>
          </div>
          <Select
            id="idealApplicant"
            selectItems={(QUALIFICATIONS || []).map((item) => ({
              value: item.label,
              label: item.label,
            }))}
            onChange={handleChange}
            name="idealApplicant"
            value={career.idealApplicant}
          />
          {careerFormErrors.idealApplicant && (
            <div className={styles.error}>
              {careerFormErrors.idealApplicant}
            </div>
          )}
        </div>
      </Col>
      {/* <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.authInput}>
          <div className={styles.inputLabel}>
            Other Ideal Applicant{' '}
            <span>(Please specify to whom you would hire for this Gig.)</span>
          </div>
          <Input
            type="text"
            onChange={handleChange}
            value={career.otherIdealApplicant}
            name="otherIdealApplicant"
          />
        </div>
      </Col> */}
      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.authInput}>
          <div className={styles.inputLabel}>
            Experience <span>(Please choose one, and </span>
            <a
              href={FREE_WORK_CREDENTIALS}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>click here</span>
            </a>
            <span> for more information on free experiential credentials)</span>
            <span className={styles.requiredField}> *</span>
          </div>
          <Select
            id="experience"
            selectItems={(CREDENTIAL_EXPERIENCE || []).map((item) => ({
              value: item.label,
              label: item.label,
            }))}
            onChange={handleChange}
            name="experience"
            value={career.experience}
          />
          {careerFormErrors.experience && (
            <div className={styles.error}>{careerFormErrors.experience}</div>
          )}
        </div>
      </Col>
      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.authInput}>
          <div className={styles.inputLabel}>
            Residency/Specialty{' '}
            <span>(Please choose one if applicable, and </span>
            <a
              href={
                COPY_OVERRIDES?.profile?.submit_residency_link ||
                'https://musiccareermap.com/category-submission'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    COPY_OVERRIDES?.profile?.submit_residency_text ||
                    'click here',
                }}
              ></span>
            </a>
            <span> if your preference is not listed.</span>
          </div>
          <Select
            id="speciality"
            selectItems={(SPECIALITIES || []).map((item) => ({
              value: item.label,
              label: item.label,
            }))}
            onChange={handleChange}
            name="speciality"
            value={career.speciality}
          />
        </div>
      </Col>
      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.authInput}>
          <div className={styles.inputLabel}>
            Fellowship Category <span>(Please choose one, if applicable. </span>
            <a
              href={
                COPY_OVERRIDES?.profile?.submit_fellowship_link ||
                'https://musiccareermap.com/category-submission'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    COPY_OVERRIDES?.profile?.submit_fellowship_text ||
                    'Click here',
                }}
              ></span>
            </a>
            <span> if your option is not listed.)</span>
          </div>
          <Select
            id="fellowship"
            selectItems={(FELLOWSHIPS || []).map((item) => ({
              value: item.label,
              label: item.label,
            }))}
            onChange={handleChange}
            name="fellowship"
            value={career.fellowship}
          />
        </div>
      </Col>
      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.authInput}>
          <div className={styles.inputLabel}>
            Fellowship Subspecialty{' '}
            <span>
              (After selecting your Category above, please choose one
              subcategory.{' '}
            </span>
            <a
              href={
                COPY_OVERRIDES?.profile?.submit_fellowship_sub_link ||
                'https://musiccareermap.com/category-submission'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    COPY_OVERRIDES?.profile?.submit_fellowship_text ||
                    'Click here',
                }}
              ></span>
            </a>
            <span> if your option is not listed.)</span>
          </div>
          <Select
            id="fellowshipSub"
            selectItems={FELLOWSHIP_SUBSPECIALITIES[career.fellowship] || []}
            onChange={handleChange}
            name="fellowshipSub"
            value={career.fellowshipSub}
          />
        </div>
      </Col>
      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.authInput}>
          <div className={styles.inputLabel}>
            Licensed States <span>(Please choose one if applicable)</span>
          </div>
          <Select
            id="licensedState"
            selectItems={STATES}
            onChange={handleChange}
            name="licensedState"
            value={career.licensedState}
          />
        </div>
      </Col>

      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.authInput}>
          <div className={styles.inputLabel}>
            {CAREER_CONFIG?.title?.label || 'Compensation'}{' '}
            <span>
              (Enter your Gross Budget Range in these fields, TBD if not yet
              determined).
            </span>
          </div>
          <div className={styles.shortDesc}>
            STRUCTURE: 80% of your budget will be viewable on your listing to
            our community, and the remaining 20% will be commissioned to Beyond
            Physician for finding your candidates and managing your project.
            Beyond Physician will collect this money in advance once your
            Service Professional is selected, and the money will only be
            released when you mark the project as completed.{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={COPY_OVERRIDES?.post?.learn_more}
            >
              Learn More.
            </a>
            <br />
            <br />
            Enter the same value for both if only listing a single rate
          </div>
        </div>
      </Col>
      <Col xl={12} lg={12} md={12} sm={12}>
        <Row className={styles.customInputOuter}>
          <Col sm={12} md={4}>
            <div className={styles.authInput}>
              <div className={styles.inputLabel}>
                Gross Budget Rate Low End (Entire Gig)
              </div>
              <Input
                type="number"
                placeholder="$"
                onChange={handleChange}
                value={career.minPay}
                name="minPay"
              />
              {careerFormErrors.minPay && (
                <div className={styles.error}>{careerFormErrors.minPay}</div>
              )}
            </div>
          </Col>
          <Col sm={12} md={4}>
            <div className={styles.authInput}>
              <div className={styles.inputLabel}>
                Gross Budget Rate High End (Entire Gig)
              </div>
              <Input
                type="number"
                placeholder="$"
                onChange={handleChange}
                value={career.maxPay}
                name="maxPay"
              />
              {careerFormErrors.maxPay && (
                <div className={styles.error}>{careerFormErrors.maxPay}</div>
              )}
            </div>
          </Col>

          <Col sm={12} md={4}>
            <div className={styles.authInput}>
              <div className={styles.inputLabel}>
                Gross Budget Rate TBD or Non-Paying
              </div>
              <div className={styles.checkRow}>
                <Checkbox
                  id="payTBD"
                  name="pay"
                  onChange={handleChange}
                  isChecked={career.minPay === null && career.maxPay === null}
                  label="TBD"
                  value="TBD"
                />
                <Checkbox
                  id="payNonPaying"
                  name="pay"
                  onChange={handleChange}
                  isChecked={career.minPay === 0 && career.maxPay === 0}
                  label="Non-Paying"
                  value="Non-Paying"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Col>

      {(!CAREER_CONFIG?.companyName || !CAREER_CONFIG?.companyName?.hidden) && (
        <Col xl={6} lg={6} md={12} sm={12}>
          <div className={styles.authInput}>
            <div className={styles.inputLabel}>
              {CAREER_CONFIG?.companyName?.label ||
                'Hiring Company/Individual Name'}
              <span className={styles.requiredField}> *</span>
            </div>
            <Input
              type="text"
              onChange={handleChange}
              value={career.companyName}
              name="companyName"
            />
            {careerFormErrors.companyName && (
              <div className={styles.error}>{careerFormErrors.companyName}</div>
            )}
          </div>
          {/* <div className={styles.doubleInput}>
          <div className={styles.inputLabel}>
            Project Deadline <span>(if any)</span>
          </div>
          <Select
            id="deadline"
            selectItems={DEADLINES}
            onChange={handleChange}
            name="deadline"
            value={career.deadline}
          />
          {careerFormErrors.deadline && (
            <div className={styles.error}>{careerFormErrors.deadline}</div>
          )}
        </div> */}
        </Col>
      )}

      {(!CAREER_CONFIG?.logo || !CAREER_CONFIG?.logo?.hidden) && (
        <Col xl={6} lg={6} md={12} sm={12}>
          <div className={styles.authInput}>
            <div className={styles.inputLabel}>
              {CAREER_CONFIG?.logo?.label || 'Company Logo/Image'}
            </div>
            <FileUpload
              fileType={FILE_TYPE.IMAGE}
              fileSpecs={fileSpecs}
              onFileChange={setLogo}
              image={job?.company_logo}
              reset={!!jobId && !postId}
            />
            {careerFormErrors.companyLogo && (
              <div className={styles.error}>{careerFormErrors.companyLogo}</div>
            )}
          </div>
        </Col>
      )}

      <Col xl={12} lg={12} md={12} sm={12}>
        <Row>
          {(!CAREER_CONFIG?.city || !CAREER_CONFIG?.city?.hidden) && (
            <Col xl={6} lg={6} md={12} sm={12}>
              <div className={styles.authInput}>
                <div className={styles.inputLabel}>
                  {CAREER_CONFIG?.city?.label || 'City'}
                  <span className={styles.requiredField}> *</span>
                </div>
                <Input
                  type="text"
                  onChange={handleChange}
                  value={career.city}
                  name="city"
                />
                {careerFormErrors.city && (
                  <div className={styles.error}>{careerFormErrors.city}</div>
                )}
              </div>
            </Col>
          )}
          {(!CAREER_CONFIG?.state || !CAREER_CONFIG?.state?.hidden) && (
            <Col xl={3} lg={3} md={12} sm={12}>
              <div className={styles.authInput}>
                <div className={styles.inputLabel}>
                  {CAREER_CONFIG?.state?.label || 'State'}
                  <span className={styles.requiredField}> *</span>
                </div>
                <Select
                  id="state"
                  selectItems={STATES}
                  onChange={handleChange}
                  name="state"
                  value={career.state}
                />
                {careerFormErrors.state && (
                  <div className={styles.error}>{careerFormErrors.state}</div>
                )}
              </div>
            </Col>
          )}
          {(!CAREER_CONFIG?.zip || !CAREER_CONFIG?.zip?.hidden) && (
            <Col xl={3} lg={3} md={12} sm={12}>
              <div className={styles.authInput}>
                <div className={styles.inputLabel}>
                  {CAREER_CONFIG?.zip?.label || 'Zip'}
                  <span className={styles.requiredField}> *</span>
                </div>
                <Input
                  type="text"
                  onChange={handleChange}
                  value={career.zip}
                  name="zip"
                />
                {careerFormErrors.zip && (
                  <div className={styles.error}>{careerFormErrors.zip}</div>
                )}
              </div>
            </Col>
          )}
        </Row>
      </Col>

      {(!CAREER_CONFIG?.opportunityDescription ||
        !CAREER_CONFIG?.opportunityDescription?.hidden) && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.authInput}>
            <div className={styles.inputLabel}>
              {CAREER_CONFIG?.opportunityDescription?.label ||
                'Project Description'}
              <span className={styles.requiredField}> *</span>
            </div>
            <TextArea
              onChange={handleChange}
              value={career.opportunityDescription}
              name="opportunityDescription"
            />
            {careerFormErrors.opportunityDescription && (
              <div className={styles.error}>
                {careerFormErrors.opportunityDescription}
              </div>
            )}
          </div>
        </Col>
      )}

      {(!CAREER_CONFIG?.hiringDescription ||
        !CAREER_CONFIG?.hiringDescription?.hidden) && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.authInput}>
            <div className={styles.inputLabel}>
              {CAREER_CONFIG?.hiringDescription?.label ||
                'Hiring Company/Individual Description'}
              <span className={styles.requiredField}> *</span>
            </div>
            <TextArea
              onChange={handleChange}
              value={career.hiringDescription}
              name="hiringDescription"
            />
            {careerFormErrors.hiringDescription && (
              <div className={styles.error}>
                {careerFormErrors.hiringDescription}
              </div>
            )}
          </div>
        </Col>
      )}

      {(!CAREER_CONFIG?.isPrivate || !CAREER_CONFIG?.isPrivate?.hidden) && (
        <Col style={{ marginBottom: '20px' }} xl={12} lg={12} md={12} sm={12}>
          <div className={styles.checkRow}>
            <div
              style={{ display: 'inline-block' }}
              className={styles.inputLabel}
            >
              Private Gig?
            </div>
            <Checkbox
              id="isPrivateTalent"
              name="isPrivate"
              isChecked={career.isPrivate === 'private'}
              onChange={handleChange}
              label=""
              value="private"
            />
          </div>
          {careerFormErrors.isPrivate && (
            <div className={styles.error}>{careerFormErrors.isPrivate}</div>
          )}
          <div className={styles.shortDesc}>
            Private Gigs function the same as Public Gigs but are not listed or
            searchable. You can still invite Service providers and receive
            applications to Private Gigs.
          </div>
        </Col>
      )}

      {/* {(!CAREER_CONFIG?.applyThrough ||
        !CAREER_CONFIG?.applyThrough?.hidden) && (
        <Col xl={6} lg={6} md={12} sm={12}>
          <div className={styles.inputLabel}>External URL</div>
          <Input
            type="text"
            placeholder="http://"
            onChange={handleChange}
            value={career.externalUrl}
            name="externalUrl"
            disabled={career.applyThrough !== 'external'}
          />
          {careerFormErrors.externalUrl && (
            <div className={styles.error}>{careerFormErrors.externalUrl}</div>
          )}
        </Col>
      )}

      <div className={styles.formDivider} /> */}

      {(!postId || relist) && (
        <Col xl={6} lg={6} md={12} sm={12}>
          <div className={styles.inputLabel}>
            Featured Listing?
            <span className={styles.requiredField}> *</span>
          </div>
          <div className={styles.featureDesc}>
            Featured listings gain visibility through top placement for category
            listings, in addition to receiving a dedicated email campaign to our
            applicant community and an account representative. Featured Listings
            remain for 30-days from posting and can be renewed in your dashboard
            with a single click.
          </div>
          <div className={styles.featureList}>
            <ul>
              <li>Account Representative</li>
              <li>Top Listing</li>
              <li>Email Campaign</li>
            </ul>
          </div>
          {careerFormErrors.isFeatured && (
            <div className={styles.error}>{careerFormErrors.isFeatured}</div>
          )}
        </Col>
      )}

      {(!postId || relist) && (
        <Col className={styles.limitedPriceData} xl={6} lg={6} md={12} sm={12}>
          <div className={styles.limitedPricing}>Limited Time Pricing</div>
          <div className={styles.limitedPrice}>
            <div className={styles.prices}>
              <s>${(featuredAmount + 50).toFixed(2)}</s> &nbsp;{' '}
              <b>${featuredAmount}</b>
            </div>
          </div>
          <div className={styles.limitedButtons}>
            <Button
              onClick={() => handleFeatured(false)}
              type="gray my-2"
              label={`${
                career.isFeatured === false ? '✔ ' : ''
              }Don't Feature My Post`}
            />
            <br />
            <Button
              onClick={() => handleFeatured(true)}
              type="gradient-blue"
              label={`${career.isFeatured ? '✔ ' : ''}Feature My Post`}
            />
          </div>
        </Col>
      )}

      <Col xl={6} lg={6} md={12} sm={12} id="page_bottom">
        <div className={`${styles.inputLabel} ${styles.inputQuestion}`}>
          Questions?
          <a href={ROUTES.SUPPORT} target="_blank" rel="noopener noreferrer">
            {' '}
            Contact us
          </a>
        </div>
      </Col>

      {(!postId || relist) && career.isFeatured && (
        <>
          <div className={styles.formDivider}></div>
          <Col xl={12} lg={12} md={12} sm={12} className={styles.posRel}>
            <Input
              type="text"
              placeholder="Coupon Code"
              onChange={handleCodeChange}
              value={code}
              name="code"
            />
            <Button
              onClick={verifyCouponCode}
              buttonType="button"
              label={!coupon ? 'Apply' : 'Remove'}
              type={`branding ${styles.applyCouponBtn}`}
              disabled={isLoading || !code}
              isLoading={isLoading}
            />
          </Col>
          {couponError && (
            <Col xl={12} lg={12} md={12} sm={12}>
              <div className={styles.jobSubmitError}>
                <Alert variant={'danger'} className={styles.apiError}>
                  {couponError}
                </Alert>
              </div>
            </Col>
          )}
          {amount > 0 ? (
            <>
              <Col xl={12} lg={12} md={12} sm={12} className="mt-3">
                <div className={styles.inputLabel}>
                  {coupon
                    ? `Applied coupon worth $${coupon.amount.toFixed(2)}. `
                    : ''}{' '}
                  Please provide payment details to pay ${amount.toFixed(2)}.
                </div>
              </Col>
              <PaymentForm setSelectedCard={setSelectedCard} />
            </>
          ) : (
            <Col xl={12} lg={12} md={12} sm={12}>
              <div className={styles.inputLabel}>
                {coupon ? `Applied coupon worth $${coupon.amount}. ` : ''}
              </div>
            </Col>
          )}
        </>
      )}
      {state && state['send_invite'] && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.inviteBanner}>
            <Alert variant="primary">
              Click the “Create Post and Submit Invitation” button to send the
              Private Gig to the Talent as is, or customize any fields (copied
              from Talent listing) before sending.
            </Alert>
          </div>
        </Col>
      )}
      {apiError && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.jobSubmitError}>
            <Alert variant={'danger'} className={styles.apiError}>
              {apiError}
            </Alert>
          </div>
        </Col>
      )}
      <Col xl={12} lg={12} md={12} sm={12}>
        <Button
          onClick={handleSubmit}
          buttonType="button"
          type="large mt-5 py-3 w-100"
          label={
            postId && !relist
              ? 'Update Post'
              : state && state['send_invite']
                ? 'Create Post And Submit Invitation'
                : 'Create Post'
          }
          disabled={isLoading}
          isLoading={isLoading}
        />
      </Col>
      {jobId && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.jobSubmitSuccess}>
            <Redirect
              to={
                state && state['send_invite']
                  ? `${ROUTES.CAREER}/${jobId}?invited=true`
                  : `${ROUTES.CAREER}/${jobId}`
              }
            />
            {/* {!postId ? (
              <Redirect to={`${ROUTES.CAREER}/${jobId}`} />
            ) : (
              <Redirect to={`${ROUTES.CAREER}/${jobId}`} />
            )} */}
          </div>
        </Col>
      )}
      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.subText}>
          Posts will last 90 days, you can refresh a post via your dashboard if
          you wish to continue the search.
        </div>
      </Col>
    </Row>
  );
};

export default React.memo(Post);
