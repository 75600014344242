export const DEFAULT_CAREER_ERRORS = {
  title: '',
  category: '',
  location: '',
  companyName: '',
  zip: '',
  state: '',
  city: '',
  opportunityDescription: '',
  hiringDescription: '',
  applyThrough: '',
  externalUrl: '',
  deadline: '',
  isFeatured: '',
  industry: '',
  idealApplicant: '',
  otherIdealApplicant: '',
  experience: '',
  speciality: '',
  fellowship: '',
  fellowshipSub: '',
  licensedState: '',
  minPay: '',
  maxPay: '',
  isPrivate: '',
  credentialName: '',
};

export const DEFAULT_CAREER_VALUES = {
  type: '',
  title: '',
  category: '',
  location: '',
  companyName: '',
  companyLogo: '',
  deadline: '',
  zip: '',
  state: '',
  city: '',
  opportunityDescription: '',
  hiringDescription: '',
  applyThrough: 'site',
  externalUrl: '',
  isFeatured: false,
  isPrivate: 'private',
  industry: '',
  idealApplicant: '',
  otherIdealApplicant: '',
  experience: '',
  speciality: '',
  fellowship: '',
  fellowshipSub: '',
  licensedState: '',
  minPay: '',
  maxPay: '',
  credentialName: '',
};

export const DEFAULT_OPPORTUNITY_ERRORS = {
  title: '',
  category: '',
  location: '',
  companyName: '',
  zip: '',
  state: '',
  city: '',
  opportunityDescription: '',
  hiringDescription: '',
  applyThrough: '',
  externalUrl: '',
  fee: '',
  referenceLinks: ['', '', ''],
  isFeatured: '',
  industry: '',
  idealApplicant: '',
  otherIdealApplicant: '',
  experience: '',
  speciality: '',
  fellowship: '',
  fellowshipSub: '',
  licensedState: '',
  minPay: '',
  maxPay: '',
  credentialName: '',
  credentialLink: '',
  isPrivate: '',
};

export const DEFAULT_OPPORTUNITY_VALUES = {
  type: '',
  title: '',
  category: '',
  location: '',
  companyName: '',
  companyLogo: '',
  zip: '',
  state: '',
  city: '',
  opportunityDescription: '',
  hiringDescription: '',
  fee: '',
  applyThrough: 'site',
  externalUrl: '',
  referenceLinks: ['', '', ''],
  isFeatured: false,
  industry: '',
  idealApplicant: '',
  otherIdealApplicant: '',
  experience: '',
  speciality: '',
  fellowship: '',
  fellowshipSub: '',
  licensedState: [],
  minPay: '',
  maxPay: '',
  credentialName: '',
  credentialLink: '',
  isPrivate: 'public',
};
