import React from 'react';
import { Button as Btn } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// SCSS
import './Button.scss';

interface Props {
  label: any;
  link?: string;
  size?: any | 'md'; // sm, md, lg
  type?: string | 'branding'; // branding, *-outline, transparent
  buttonType?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  isLoading?: boolean; // loading handler
  target?: string;
  onClick?: () => void; // optional onClick event
}

const Button: React.FC<Props> = ({
  label,
  link,
  target,
  size,
  type,
  buttonType,
  disabled,
  isLoading,
  onClick,
}: Props) => {
  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return !link ? (
    <Btn
      size={size}
      className={`micButton micBtn ${type}`}
      type={buttonType}
      disabled={disabled}
      onClick={!isLoading ? handleClick : () => {}}
    >
      {isLoading ? 'Loading...' : label}
    </Btn>
  ) : (
    <Btn
      size={size}
      className={`micButton micBtn ${type}`}
      type={buttonType}
      disabled={disabled}
    >
      <Link
        target={target}
        style={{ background: 'inherit', color: 'inherit' }}
        to={link}
      >
        {isLoading ? 'Loading...' : label}
      </Link>
    </Btn>
  );
};

export default React.memo(Button);
